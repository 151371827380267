<template>
<!------下拉選單------->
<div class="drop-down-wrap">
  <div
    class="select-container"
    style="width: 100%; position: relative"
    ref="target"
  >
    <div
      class="d-flex justify-content-end align-items-center dropdown"
      @click="autoShow = !autoShow"
    >
      <span
        class="flex-fill text-start"
      >
        {{ selectValue }}
      </span>
      <img :src="greenDownTriangle" alt="searchImg" />
    </div>
    <div
      class="auto-complicate-div text-start cursor-pointer option-content"
      v-show="autoShow"
      @mouseenter="autoShow = true"
    > 
      <div
        v-for="(value, index) in dropDownOptionsList"
        :key="index"
        class="m-0 auto-complicate-p d-flex justify-content-start align-items-center"
        style="padding: 5px 14px"
        @click="optionClick($event,value, index)"
      >
        <img class="option-img" :class="[sortArrowType==='ascending'?'arrow-up':'']" src="../../assets/arrow-sort.svg.svg" alt="sort-arrow" v-show="selectedOptionName===value">  
        <span
          class="option-text"
        >
          {{ value }}
        </span>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import {ref,defineEmits} from 'vue'
import greenDownTriangle from "../../assets/green-down-triangle.svg";
const emits = defineEmits(['updateSorting'])
const selectValue = ref("篩選")
const dropDownOptionsList = ['日期','名稱','大小']
const autoShow = ref(false)
const selectedOptionName = ref('日期')
const sortArrowType = ref('ascending')

// 下拉選單觸發事件
function optionClick (event) {
  if(event.target.tagName === 'IMG') {
    if (selectedOptionName.value === event.target.nextElementSibling.innerText) {
      sortArrowType.value = sortArrowType.value.includes('a')?'descending':'ascending'
    } else {
      const selectOptionName = event.target.nextElementSibling.innerText
      selectedOptionName.value = selectOptionName
    }
    
  } else if (event.target.tagName === 'SPAN') {
    if (selectedOptionName.value === event.target.innerText) {
      sortArrowType.value = sortArrowType.value.includes('a')?'descending':'ascending'
    } else {
      selectedOptionName.value = event.target.innerText
    }  
  } else {
    if (selectedOptionName.value === event.target.children[1].innerText) {
      sortArrowType.value = sortArrowType.value.includes('a')?'descending':'ascending'
    } else {
      selectedOptionName.value = event.target.children[1].innerText
    }
    
  }
  // 上傳篩選條件 升降冪 + 篩選種類
  console.log("觸發篩選日期大小emit")
  emits('updateSorting',sortArrowType.value,selectedOptionName.value)
  const upDownText = sortArrowType.value==='descending'?'↑':'↓'
  selectValue.value = selectedOptionName.value + `(${upDownText})`
  autoShow.value = false
}
</script>

<style scoped>
.dropdown {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 0 14px;
  width: 92px;
  height: 40px;
  cursor: pointer;
  width: fit-content;
}
.auto-complicate-div {
  position: absolute;
  box-shadow: none;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  background-color: white;
  z-index: 99;
  max-height: 200px;
  overflow: auto;
}
.select-container {
  color: #575757;
  text-align: center;
  cursor: pointer;
}
.auto-complicate-p {
  color: #575757;
}
.auto-complicate-p:hover {
  background-color: #eaf3f4;
  color: #575757;
}
.option-content {
  color: #575757;
  width: 250px;
  background-color: white;
  border-radius: 15px;
  border-color: transparent;
  box-shadow: 0 2px 8px 0 rgba(99,99,99,.2);
}
.option-text {
  margin-left: 10.36px;
}
.arrow-up {
  transform: rotate(180deg);
}
</style>