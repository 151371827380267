<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="props.customDateModalId"
    tabindex="-1"
    aria-labelledby="customDatePickerTestModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="customDatePickerTestModalLabel"></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <DatePicker
            :from-page="{ month: endMonth, year: endYear }"
            @dayclick="onDayClick"
            v-model="customDate"
            is-range
            is-expanded
            :model-config="modelConfig"
            :min-date="hasDataStart"
            :max-date="hasDataEnd"
          />
          <div>
            <DatePicker
              class="date-text-input"
              v-model="customDate"
              :model-config="modelConfig"
              :min-date="hasDataStart"
              :max-date="hasDataEnd"
              is-range
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div
                  class="d-flex justify-content-center align-items-center mt-2"
                >
                  <input
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                    class="ps-2 date-picker-input"
                  />
                  <span style="margin: 0 5px">-</span>
                  <input
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                    class="ps-2 date-picker-input"
                  />
                </div>
              </template>
            </DatePicker>
          </div>
          <button
            class="btn confirm-btn mt-3"
            @click="confirmDateRange"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-gtm="time-range-option"
          >
            選取
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  watch,
  computed,
  defineProps,
  defineEmits,
} from "vue";
import moment from "moment";

const props = defineProps({
  dateRange: {
    type: Object,
  },
  hasDataStart: {
    type: String,
    default: "2000-01-01",
  },
  hasDataEnd: {
    type: String,
    default: "2000-01-31",
  },
  customDateModalId: {
    type: String,
    default: "customDatePickerTestModal",
  },
});

const modelConfig = {
  type: "string",
  mask: "YYYY-MM-DD",
};

const customDate = ref({});
const endYear = ref(0);
const endMonth = ref(0);

watch(
  () => props.dateRange,
  (val) => {
    // console.log('date changed, endMonth=', moment('2023-12-31').month(), 'val=', val.end)
    endYear.value = moment(val.end).year();
    endMonth.value = moment(val.end).month() + 1;
    customDate.value = val;
  },
  { deep: true }
);
{
  props.startDate;
}
onMounted(() => {
  endYear.value = moment(props.dateRange.end).year();
  endMonth.value = moment(props.dateRange.end).month() + 1;
  customDate.value = props.dateRange;
});

const emit = defineEmits(["customDatePicked"]);

const onDayClick = (day) => {
  endYear.value = day.year;
  endMonth.value = day.month;
};

const confirmDateRange = () => {
  // console.log('confirmDateRange=', customDate.value);
  emit("customDatePicked", customDate.value);
};
</script>

<style scoped>
.confirm-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}
</style>
