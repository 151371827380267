<template>
  <div class="dropdown">
    <a
      class="btn dropdown-toggle datepicker d-flex justify-content-between align-items-center"
      href="#"
      role="button"
      id="dropdownMenuLink"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <!--時間選擇器顯示區間 local頁面使用-->
      <span class="d-flex align-items-center" v-if="isLocalStatus">
        <img class="me-2" src="../assets/black-calendar.svg" alt="calendar" />
        <span>{{ localSelectedRangeTitle }}</span>
      </span>
      <span v-if="isLocalStatus">
        {{ localSelectedStartDate }} ~ {{ localSelectedEndDate }}
      </span>

      <!--一般通用時間選擇器顯示區間 -->
      <span class="d-flex align-items-center" v-if="!isLocalStatus">
        <img class="me-2" src="../assets/black-calendar.svg" alt="calendar" />
        <span>{{ rangeTitle }}</span>
      </span>
      <span v-if="!isLocalStatus">
        {{ selectedStartDate }} ~ {{ selectedEndDate }}
      </span>
    </a>
    <!--時間下拉選項-->
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
      <li class="change-date-li" v-for="choice in dateChoices" :key="choice">
        <button
          class="change-date-button"
          @click="selectDateRange(choice)"
          :data-gtm="choice !== '自訂' ? 'time-range-option' : ''"
        >
          {{ choice }}
        </button>
      </li>
    </ul>
    <p
      class="ms-2 font-14 GrayC4 position-absolute recent-data-date"
      v-if="!isSpecialPathName"
    >
      {{ endDate }} 為最近一筆資料
    </p>
    <CustomDatePicker
      :dateRange="{ start: selectedStartDate, end: selectedEndDate }"
      :hasDataStart="startDate"
      :hasDataEnd="endDate"
      :customDateModalId="customDateModalId"
      @customDatePicked="customDatePicked"
      v-if="!isLocalStatus"
    />
    <CustomDatePicker
      :dateRange="{ start: localSelectedStartDate, end: localSelectedEndDate }"
      :hasDataStart="startDate"
      :hasDataEnd="endDate"
      :customDateModalId="customDateModalId"
      @customDatePicked="customDatePicked"
      v-if="isLocalStatus"
    />
  </div>
</template>

<script setup>
// Vue 套件
import { useRoute } from "vue-router";
import { ref, computed, defineProps, watch } from "vue";
// api1 api2 api3 因應ReMa等API模組不同
import { allapi } from "../../public/static/api/apigClient.js";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { allapi3 } from "../../public/static3/api/apigClient.js";
// 引用的外部模組
import CustomDatePicker from "./CustomDatePicker.vue"; // 自訂區間元件
import createModal from "../utility/createModal"; // 自訂日曆選擇器modal
// pinia
import { storeToRefs } from "pinia";
import { useDateRangeStore } from "../store/useDateRangeStore";
import DateFunction from "../utility/DateSelector.js"; // local端需要的時間計算模組
import DateSelectorInitialize from "../utility/DateSelectInitialize"; // 時間記憶初始化
import { useLoadingStore } from "../store/useLoadingStore"; // loading狀態管理
const dateRangeStore = useDateRangeStore();
const { rangeTitle, startDate, endDate, selectedStartDate, selectedEndDate } =
  storeToRefs(dateRangeStore);
const emit = defineEmits(["exportStartDateAndEndDate"]);
// loading
const loading = useLoadingStore();
// 由自己頁面獨立控制，不透過pinia記憶的
const localSelectedStartDate = ref("");
const localSelectedEndDate = ref("");
const localSelectedRangeTitle = ref("");

// 不同頁面或帳號顯示時間選項不同
const pathName = useRoute().path;

const props = defineProps({
  customDateModalId: {
    /* 自訂時間區間的彈窗id */
    /* 因為有些頁面會有一個以上的自訂彈窗，需要用此來判斷開啟哪個彈窗 */
    /* 用法可參考 IndicatorCompare.vue */
    type: String,
    default: "#exampleModal",
  },
  sdkApiName: {
    type: String,
    default: "timeSalesGet",
  },
  // 時間狀態由元件狀態本身控制的- ex:指標同比 上期欄位使用
  isLocalStatus: {
    type: Boolean,
    default: false,
  },
  // api種類名稱
  allApi: {
    type: String,
    default: "allapi",
  },
  // 標籤報表運用自訂義傳入的min max模式
  useCustomDate: {
    type: Boolean,
    default: false,
  },
  // 自己傳入min-max date
  customMinMaxDate: {
    type: Object,
    default: () => {},
  },
});

// api sdk
let apiSdk = switchApiSdk();
function switchApiSdk() {
  switch (props.allApi) {
    case "allapi":
      return allapi;
    case "allapi2":
      return allapi2;
    case "allapi3":
      return allapi3;
  }
}

defineExpose({
  localSelectedStartDate,
  localSelectedEndDate,
});

// 時間選擇器下拉選項 會依據部分帳戶顯示不同時間選項
const dateChoices = computed(() => {
  if (
    pathName === "/GoogleadsTable" ||
    pathName === "/CrossAdsChart" ||
    pathName === "/FbadsTable" ||
    pathName === "/FbTrendAnal"
  ) {
    return [
      "最近1天",
      "最近7天",
      "最近14天",
      "最近30天",
      "最近一年",
      "全部",
      "自訂",
    ];
  } else if (pathName === "/BuildingCase") {
    return [
      "最近1天",
      "最近7天",
      "最近30天",
      "最近3個月",
      "最近一年",
      "全部",
      "自訂",
    ];
  }
  // 同期指標頁面
  else if (pathName === "/IndicatorCompare") {
    return [
      "最近1天",
      "最近7天",
      "最近30天",
      "最近3個月",
      "最近一年",
      "全部",
      "上個月",
      "自訂",
    ];
  } else {
    return ["最近1天", "最近7天", "最近30天", "最近一年", "全部", "自訂"];
  }
});

const customDateModalId = computed(() => {
  return props.customDateModalId;
});

// 某些頁面不顯示最近一筆資料
const isSpecialPathName = computed(() => {
  switch (pathName) {
    case "/CrossAdsChart":
    case "/BuildingCase":
    case "/FbadsTable":
    case "/IndicatorCompare":
      return true;
    default:
      return false;
  }
});

// 會接收自訂日曆emit上來的date參數
function customDatePicked(date) {
  console.log(
    "自訂日曆選擇的日期date",
    date,
    "props.isLocalStatus",
    props.isLocalStatus
  );
  // 在指標同比(IndicatorCompare)頁面使用
  // local 時間選擇器處理邏輯，而是傳出時間另外處裡
  if (props.isLocalStatus) {
    console.log("選取上期的時間選擇器(local)", date);
    // 存入local端
    localSelectedStartDate.value = date.start;
    localSelectedEndDate.value = date.end;
    emit(
      "exportStartDateAndEndDate",
      localSelectedStartDate.value,
      localSelectedEndDate.value
    );
  } else {
    console.log("選取上期的時間選擇器(pinia)", date);
    // 存入pinia
    dateRangeStore.setCustom(date.start, date.end);
    emit(
      "exportStartDateAndEndDate",
      selectedStartDate.value,
      selectedEndDate.value
    );
  }
}

// 按到自訂按鈕時觸發日曆彈窗
function showCalendarModal() {
  const calendarModal = createModal(props.customDateModalId);
  calendarModal.show();
}

// 點擊選擇下拉選項的事件
function changeLocalDateRange(title, option) {
  const targetTitle = title || "最近30天";
  // 將選擇時間存入自己元件的狀態
  localSelectedRangeTitle.value = targetTitle;
  switch (targetTitle) {
    case "最近1天":
      localSelectedStartDate.value = DateFunction.getPassDaysStart(
        endDate.value,
        24
      );
      localSelectedEndDate.value = endDate.value;
      return;
    case "最近3天":
      localSelectedStartDate.value = DateFunction.getPassDaysStart(
        endDate.value,
        24 * 3
      );
      localSelectedEndDate.value = endDate.value;
      return;
    case "最近7天":
      localSelectedStartDate.value = DateFunction.getPassDaysStart(
        endDate.value,
        24 * 7
      );
      localSelectedEndDate.value = endDate.value;
      return;
    case "最近14天":
      localSelectedStartDate.value = DateFunction.getPassDaysStart(
        endDate.value,
        24 * 14
      );
      localSelectedEndDate.value = endDate.value;
      return;
    case "最近30天":
      localSelectedStartDate.value = DateFunction.getPassDaysStart(
        endDate.value,
        24 * 30
      );
      localSelectedEndDate.value = endDate.value;
      return;
    case "最近3個月":
      localSelectedStartDate.value = DateFunction.getPassDaysStart(
        endDate.value,
        24 * 90
      );
      localSelectedEndDate.value = endDate.value;
      return;
    case "最近一年":
      localSelectedStartDate.value = DateFunction.getRecentYearStart(
        endDate.value
      );
      localSelectedEndDate.value = endDate.value;
      return;
    case "全部":
      localSelectedStartDate.value = startDate.value;
      localSelectedEndDate.value = endDate.value;
      return;
    case "上個月":
      localSelectedStartDate.value = DateFunction.getLastMonthStart(
        endDate.value
      ).startDate;
      localSelectedEndDate.value = DateFunction.getLastMonthStart(
        endDate.value
      ).endDate;
      return;
    case "自訂":
      // 打開日曆選擇彈窗
      if (option !== "closeModal") {
        showCalendarModal();
      }
      break;
    default:
      return;
  }
}
function selectDateRange(title, option) {
  const targetTitle = title || "最近30天";
  // local 選擇器邏輯
  if (props.isLocalStatus) {
    changeLocalDateRange(title, option);
    return;
  }

  // 將選擇時間存入pinia
  dateRangeStore.setRangeTitle(targetTitle);
  switch (targetTitle) {
    case "最近1天":
      dateRangeStore.setDay();
      emit("exportStartDateAndEndDate", selectedStartDate, selectedEndDate);
      return;
    case "最近3天":
      dateRangeStore.set3Day();
      emit("exportStartDateAndEndDate", selectedStartDate, selectedEndDate);
      return;
    case "最近7天":
      dateRangeStore.setWeek();
      emit("exportStartDateAndEndDate", selectedStartDate, selectedEndDate);
      return;
    case "最近14天":
      dateRangeStore.setTwoWeeks();
      emit("exportStartDateAndEndDate", selectedStartDate, selectedEndDate);
      return;
    case "最近30天":
      dateRangeStore.setMonth();
      emit("exportStartDateAndEndDate", selectedStartDate, selectedEndDate);
      return;
    case "最近3個月":
      dateRangeStore.setSeason();
      emit("exportStartDateAndEndDate", selectedStartDate, selectedEndDate);
      return;
    case "最近一年":
      dateRangeStore.setYear();
      emit("exportStartDateAndEndDate", selectedStartDate, selectedEndDate);
      return;
    case "上個月":
      dateRangeStore.setLastMonth();
      emit("exportStartDateAndEndDate", selectedStartDate, selectedEndDate);
      return;
    case "全部":
      dateRangeStore.setAllPeriod();
      emit("exportStartDateAndEndDate", selectedStartDate, selectedEndDate);
      return;
    case "自訂":
      // 打開日曆選擇彈窗
      if (option !== "closeModal") {
        showCalendarModal();
      }
      break;
    default:
      return;
  }
}
watch(
  () => props.customMinMaxDate,
  (newVal) => {
    if (newVal?.min) {
      const selectedRangeTitle = DateSelectorInitialize(
        newVal?.min,
        newVal?.max
      );

      // 最後一筆資料顯示的時間區間以api回傳的時間區間為主-----------------------
      startDate.value = newVal?.min;
      endDate.value = newVal?.max;

      // 處理上一次時間選擇器顯示的起始時間、結束時間(需以每頁最後一筆資料時間為主)
      selectDateRange(selectedRangeTitle, "closeModal"); // 如果為自訂選項關閉自訂日曆彈窗
      // local時間選擇器 (預設為最近一個月)
      localSelectedStartDate.value = DateFunction.getLastMonthStart(
        newVal?.max
      ).startDate;
      localSelectedEndDate.value = DateFunction.getLastMonthStart(
        newVal?.max
      ).endDate;
      localSelectedRangeTitle.value = "上個月";
    }
  },
  {
    immediate: true,
    deep: true,
  }
);
if (!props.useCustomDate) {
  apiDataTimeInterval();
}
// 日期選擇器拉取的時間區間api
async function apiDataTimeInterval() {
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
  };
  try {
    // api SDK pathname
    loading.startLoading();
    const res = await apiSdk[props.sdkApiName](params);
    const selectedRangeTitle = DateSelectorInitialize(
      res.data.Data.start_date,
      res.data.Data.end_date
    );

    // 最後一筆資料顯示的時間區間以api回傳的時間區間為主-----------------------
    startDate.value = res.data.Data.start_date;
    endDate.value = res.data.Data.end_date;

    // 處理上一次時間選擇器顯示的起始時間、結束時間(需以每頁最後一筆資料時間為主)
    selectDateRange(selectedRangeTitle, "closeModal"); // 如果為自訂選項關閉自訂日曆彈窗
    // local時間選擇器 (預設為最近一個月)
    localSelectedStartDate.value = DateFunction.getLastMonthStart(
      res.data.Data.end_date
    ).startDate;
    localSelectedEndDate.value = DateFunction.getLastMonthStart(
      res.data.Data.end_date
    ).endDate;
    localSelectedRangeTitle.value = "上個月";

    console.log(
      "時間選擇器取得時間區間 ok",
      res,
      "最後一筆資料時間",
      endDate.value
    );
    loading.endLoading();
  } catch (err) {
    console.log("時間選擇器取得時間區間連線有誤", err);
    loading.endLoading();
  }
}
</script>

<style scoped>
.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 10000;
}
.dropdown-toggle {
  font-size: 14px;
}
.recent-data-date {
  right: 5px;
}
</style>
