<template>
  <div class="backdrop" @click.self=closeModal>
    <Transition>
      <div class="upload-image-modal-container" v-if="openContainer">
      <div v-if="isApiUpLoadingImages" class="loading-image-spinner"></div>
      <header class="nav-bar">
        <img class="close-cross" src="../../assets/whiteXWithRadius.svg" alt="close" @click.self=closeModal>
      </header>
      <section class="load-img-section">
        <p class="Cyan">插入圖片</p>
        <div class="img-load-intro"
          @drop.prevent="($event)=>uploadImageFile($event)"
          @dragover.prevent
          @dragleave.prevent
        >
          <div class="custom-file">
              <input
                ref="fileRef"
                type="file"
                class="custom-file-input"
                style="height: 120px"
                id="customFile"
                @change="($event)=>uploadImageFile($event)"
                accept=".gif,.png,.jpg,.jpeg,.bmp"
              />
              <label
                class="custom-file-label"
                for="customFile"
              >
                <div class="customFile-img">
                  <img src="../../assets/upload-img-icon.svg" alt="picture" />
                </div>
                <div>
                  <div class="upload-input-title">
                    <span class="Gray34 font-18">
                      拖曳檔案到此視窗或瀏覽檔案
                    </span>
                    <div class="upload-search-text">
                      瀏覽...
                    </div>
                  </div>
                  <div class="lh-1">
                    <span class="Cyan font-12">提示 </span>
                    <span class="Gray54 font-12">
                      <!-- 支援格式為gif.png.jpg.jpeg.bmp，檔案最大上傳5MB。 -->
                      <span>支援格式為gif.png.jpg.jpeg.bmp,檔案最大上傳5MB,gif則為2MB以下。</span>
                      <br><span :style="{ 'font-weight': '600' }">系統會將圖片自動壓縮至2MB以下，若壓縮後超過2MB，請刪除並調整圖檔大小後再重新上傳。</span><br>建議您使用小於2MB以下的圖檔，以便確保EDM發送品質。
                    </span>
                    <span class="Red font-12" v-if="upFileError == true"
                      >上傳格式或檔案大小不符合，請重新上傳</span
                    >
                  </div>
                </div>
              </label>
            </div>
        </div>
      <div class="filter-search-section">
          <InputSearch
            @updateSearchInput="(inputVal)=>updateSearchInput(inputVal)"
          />
          <DropDownWithSortIcon 
            class="dropdown-select-sort"
            @updateSorting="(sort,type)=>sortImage(sort,type)"
          />
          <button class="delete-image-btn" v-show="isShowImageDeleteBtn" @click="deleteMultipleImages">刪除</button>
          <img class='block-icon' 
            :src="blockDisplayIcon" 
            alt="" 
            :class="[imageDisplayMode === 'block'?'active':'inactive']"
            @click="() => imageDisplayMode = 'block'"
            v-show="imageDisplayMode === 'block'"
          >
          <img class='block-icon' 
            :src="blockDisplayInActiveIcon" 
            alt="" 
            @click="() => imageDisplayMode = 'block'"
            v-show="imageDisplayMode !== 'block'"
          >
          <img class='list-icon' 
            :src="listDisplayIcon"
            alt=""
            @click="() => imageDisplayMode = 'list'"
            v-show="imageDisplayMode === 'list'"
          >
          <img class='list-icon' 
            :src="listDisplayInActiveIcon"
            alt=""
            @click="() => imageDisplayMode = 'list'"
            v-show="imageDisplayMode !== 'list'"
          >
          <Paginator
            :first="1"
            :rows="6"
            :pageLinkSize="3"
            :totalRecords="filterImageListData?.length"
            template="PrevPageLink PageLinks NextPageLink "
            @page="($event)=>onPage($event)"
            ref="paginatorDom"
            class="paginator"
          />
      </div>
      <div class="paginator-count">
          目前 {{ nowPage }} ，共
          {{ Math.ceil(filterImageListData?.length/6) }} 頁
      </div>
    </section>
    <div class="card-container" :class="[imageDisplayMode==='list'?'row':'']">
      <template v-if="imageDisplayMode==='block'">
        <div v-for="card in perPageImageCardData" :key="card.key">
            <BlockCard
            :cardInfoData="card"
            @updateImageInfo="updateImageInfo"
            @checKBoxChecked="(checked,key)=>imageChecked(checked,key)"
            @deleteImage="(imageKey)=>apiDeleteOneImage(imageKey)"
            @insertImage="(imageKey)=>insertImageToTemplate(imageKey)"
            :checked="card?.checked"
            />
        </div>
      </template>
      <template v-if="imageDisplayMode==='list'">
        <div v-for="card in perPageImageCardData" :key="card.key">
            <ListCard 
            :cardInfoData="card"
            @updateImageInfo="updateImageInfo"
            @checKBoxChecked="(checked,key)=>imageChecked(checked,key)"
            @deleteImage="(imageKey)=>apiDeleteOneImage(imageKey)"
            @insertImage="(imageKey)=>insertImageToTemplate(imageKey)"
            :checked="card?.checked"
            />   
        </div>  
      </template>
      <teleport to='#email-editor-container' >
        <UpLoadImageToast
         :loadingImageStatus="loadingImageStatus"
        />
      </teleport>
    </div>
    </div>
    
    </transition>
</div>
</template>
<script setup>
import {ref,defineEmits,onMounted,computed,watch,nextTick,Teleport} from 'vue'
import { allapi2 } from "../../../public/static2/api/apigClient";

import InputSearch from './InputSearch.vue';
import DropDownWithSortIcon from './DropDownWithSortIcon.vue';
import Paginator from "primevue/paginator";
import BlockCard from './BlockCard.vue';
import ListCard from './ListCard.vue';
import UpLoadImageToast from './UpLoadImageToast.vue';

import blockDisplayIcon from "../../assets/block-display.svg" 
import listDisplayIcon from "../../assets/list-display-active.svg"
import blockDisplayInActiveIcon from "../../assets/block-display-inactive.svg" 
import listDisplayInActiveIcon from "../../assets/list-display.svg"
const imageDisplayMode = ref('block')
const allImagesListData = ref([]) // 全部編輯圖片資料
const filterImageListData = ref([]) //依據全部圖片資料搜尋後資料
const nowSearchInputData = ref("") // 給日期、大小和size 判斷依據此族群資料做排序
const nowPage = ref(1)
const paginatorDom = ref (null)
const openContainer = ref(false)
const fileRef = ref(null)


// 圖片上傳狀態
const loadingImageStatus = ref({
})
const isApiUpLoadingImages = ref(false)
const upFileError = ref(false)

// 每一分頁的圖片資訊(6個為一組)
const perPageImageCardData = ref([])
const emits = defineEmits(['closeModal','insertImageToTemplate'])

const isShowImageDeleteBtn = computed(()=>{
  const isContainChecked = (item) => item?.checked === true
  return filterImageListData.value.some(isContainChecked)
})

watch([nowPage,filterImageListData],(newVal)=>{
  perPageImageCardData.value = filterImageListData.value.slice((newVal[0]-1)*6,newVal[0]*6)
},{immediate:true,deep: true})

onMounted(()=>{
  apiGetAllImagesData()
  openContainer.value = true
})
function closeModal() {
  openContainer.value = false
  setTimeout(()=>{
    emits('closeModal')
  },500)
}

// 分頁器顯示目前頁數--------------
function onPage(event) {
  nowPage.value = event.page + 1
}

// 搜尋框關鍵字 搜尋圖片功能---------
function updateSearchInput(inputVal) {
  if (inputVal==='')  {
    filterImageListData.value = allImagesListData.value
    nowSearchInputData.value = ''
    return
  }
  nowSearchInputData.value = inputVal
  filterImageListData.value = allImagesListData.value.filter(imageData=> imageData?.name?.includes(inputVal))
}

// 圖片排序功能 日期、檔案大小、name
function sortImage(sort,type) {
  const sortOrder = sort==='ascending' ? true : false; // 升降冪排序
  const filterData = nowSearchInputData.value?.length>0? filterImageListData.value:allImagesListData.value
  const name = {
    '日期': "time",
    '大小': "size",
    '名稱': "name"
  }
  const key = name[type]

  switch (key) {
    case "size":
      filterImageListData.value = filterData.sort((a, b) => {
        return sortOrder? b?.size - a?.size : a?.size - b?.size;
      });
      break;
    case "name":
      filterImageListData.value = filterData.sort((a, b) => {
        return sortOrder? a?.name?.localeCompare(b?.name, "zh-TW") : b?.name?.localeCompare(a?.name, "zh-TW");
      });
      break;
    case "time":
      filterImageListData.value = filterData.sort((a, b) => {
        return sortOrder ? new Date(a['time']) - new Date(b['time']):
        new Date(b['time']) - new Date(a['time']);
      });
      break;
      default:
      break;
  }
}

// 每張卡片執行checked功能 去對filterData進行修正
function imageChecked(checked,key) {
  filterImageListData.value = filterImageListData.value.map(item=>{
    if (item.key === key) {
      item['checked'] = checked
    } 
    return item
  })
}

// 圖片大小驗證
function checkImageSize(fileSize,fileType) {
  // gif 上傳限制2MB
  if (fileType.includes('.gif') && fileSize > 2 * 1024 * 1024) {
    upFileError.value = true
    throw Error("image size error");
  }
  /// 判斷檔案大小是否超過5MB
  else if (fileSize > 5 * 1024 * 1024) {
      // alert("圖片檔案太大");
      upFileError.value = true
      throw Error("image size error");
  }
}

// 圖片檔案類型驗證
function checkExtension(fileName) {
  const validExts = new Array(
    ".gif",
    ".png",
    ".jpg",
    ".jpeg",
    ".bmp",
    ".csv"
  );
  fileName = fileName.substring(fileName.lastIndexOf("."));
    if (validExts.indexOf(fileName) < 0) {
      // alert("檔案類型錯誤，可接受的副檔名有：" + validExts.toString());
      upFileError.value = true
      throw Error("extension error");
    }
  }
// 刪除多張圖片功能-----------------------
function deleteMultipleImages() {
  // checkbox點選的id 迴圈刪除
  const checkedImagesKey = []
  filterImageListData.value.forEach(item=>{
    if (item?.checked) checkedImagesKey.push(item.key)
  })
  checkedImagesKey.forEach(id=>apiDeleteOneImage(id))
}

// 插入圖片至樣板---------------------
function insertImageToTemplate(imageKey) {
  emits('insertImageToTemplate',imageKey)
}
// 創建上傳狀態物件資料----------------
function updateImageLoadingStatus(key,name,state) {
    loadingImageStatus.value[key] = {
      name,
      status:state
    }
}
function deleteImageLoadingStatus(key) {
  delete loadingImageStatus.value[key]

}

// 圖片上傳loading UI狀態延遲控制
function uploadImageFileLoadingSuccess(key,fileName) {
  return new Promise((resolve)=>{
    setTimeout(()=>{ 
        updateImageLoadingStatus(key,fileName,'success') 
        resolve()
      },1000) 
     
  })
}
function deleteImageFileLoadingInfo(key) {
  return new Promise((resolve)=>{
    setTimeout(()=>{ 
      deleteImageLoadingStatus(key)
      resolve()
      },4000) 
  })
}

function dropFile(e) {
  console.log("drop拖曳檔案",e)
}
async function delayTimerByFileSize(fn,fileSize) {
  let time = 1000
  switch (true) {
    case (fileSize >= 3500):
      time = 6000
      break;
    case (fileSize >= 3000 && fileSize < 3500):
      time = 5000
      break;
    case (fileSize >= 2500 && fileSize < 3000):
      time = 4000
      break;
    case (fileSize >= 2000 && fileSize < 2500):
      time = 3000
      break;
    case (fileSize >= 1500 && fileSize < 2000):
      time = 3000
      break;
    case (fileSize >= 1000 && fileSize < 1500):
      time = 2000
      break;
  }
 setTimeout(()=>fn(),time)
}
// 圖片上傳壓縮功能
async function uploadImageFile (event) {
  event.stopPropagation()
  event.preventDefault();
  console.log('圖片',event.target?.files?.[0])
  // loading狀態資料新建
  const uniqueId = Math.floor(Math.random() * 100)
  const file = event.target?.files?.[0] || event.dataTransfer?.files?.[0];
  isApiUpLoadingImages.value = true
  console.log("fileRef",fileRef.value.value)
  // 沒有上傳檔案時空值不呼叫Api
  if(!file) {
    isApiUpLoadingImages.value = false
    return
  }
  try { 
    console.log('上傳file',file)
    upFileError.value = false
    /// 檢查副檔名
    checkExtension(file.name);
    /// 檢查檔案大小
    checkImageSize(file.size,file.name);
    // loading狀態資料新建
    updateImageLoadingStatus(uniqueId+file.name,file.name,'loading')
    // 轉成base64
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const base64Banner = reader.result;
      let binary = atob(base64Banner.split(",")[1]);
      let array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      let blobData = new Blob([new Uint8Array(array)], { type: file.type });
      // 呼叫api圖片新增至s3網域取得網址 輸入檔案類型
      const res = await apiGetImagesUploadUrl(file.type);
      // 上傳圖片到s3提供的網址
      await apiPutImageToUrl(blobData,res.bannerUrl)
      // 將網址、size等資料送至後端資料庫--------------
      await apiPostImagesInfo (res.bannerKey,file.name,file.size,)
      // 每一列圖片loading 狀態UI
      await uploadImageFileLoadingSuccess(uniqueId+file.name,file.name)
      await deleteImageFileLoadingInfo(uniqueId+file.name)
      // 成功後重獲取所有圖片資料-------------
      await delayTimerByFileSize(updateImageInfo,file.size/1000)
      fileRef.value.value = ''
    };
   
    } catch (err) {
        console.log(err,'圖片上傳過程有誤');
        fileRef.value.value = ''
        updateImageLoadingStatus(uniqueId+file.name,file.name,'error')
        isApiUpLoadingImages.value = false
        deleteImageFileLoadingInfo(uniqueId+file.name)
      }
  }
// 更新修改後圖片資訊
async function updateImageInfo(ascending='descending') {
  // 重新排序 日期最新的在前面---------------
  await apiGetAllImagesData(ascending)
  sortImage(ascending,'日期') 
  isApiUpLoadingImages.value = false
  // 返回分頁第一頁------------
  if (paginatorDom.value.page) {
    // 如果不是第一頁就不用呼叫
    nextTick(()=>paginatorDom.value.changePageToFirst())
  }

}

// 取得所有編輯圖片資料(圖片上傳列表用)--------------
async function apiGetAllImagesData() {
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
  };
  await allapi2.imagesInfoGet(params)
    .then((res) => {
      // 預設是日期最新在前
      allImagesListData.value = res.data?.sort((a,b)=> new Date(b?.time) - new Date(a?.time))
      filterImageListData.value = allImagesListData.value // 複製一份過濾用資料
      console.log('取得所有編輯圖片資料ok',res)
    })
    .catch((err) => {
      console.log("取得所有編輯圖片連線有誤",err);
    });
}

// 上傳圖片取得圖片s3儲存位置-------
async function apiGetImagesUploadUrl(type) {
  // const mimeString = base64Banner.split(",")[0].split(":")[1].split(";")[0];
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
    mimetype: type,
  };
  let resObject
  await allapi2
    .imagesUploadurlGet(params)
    .then((res) => {
        console.log(res,'取得圖片S3網址成功');
        resObject = {
          bannerUrl: res.data.uploadURL,
          bannerKey: res.data.key
        }
      })
        .catch((err) => {
          console.log(err,'得圖片S3網址有錯');
        });
  return resObject
}
// 將圖片存入s3提供的網址-------------------------
async function apiPutImageToUrl(imageFile,imageUrl) { 
  await fetch(imageUrl, { method: "PUT", body: imageFile })
    .then((res) => {
      console.log("fetch圖片上傳至s3網址ok", res);
          return res.statusText; // 使用 text() 可以得到純文字 String
    }).catch((err)=> console.log("fetch圖片上傳至s3網址有誤",err))
}


// api將圖片資訊記錄到DB------------------------
async function apiPostImagesInfo (bannerKey,fileName,size) {
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
  };
  const body = {
    key: bannerKey,
    name: fileName,
    size: size/1024,
  };
  await allapi2
    .imagesInfoPost(params, body)
    .then((res) => {
        console.log("圖片上傳資料到DB--ok",res); 
          // $("#mediaLibrary").modal("hide"); 
        })
        .catch((err) => {
          console.log("圖片上傳資料到DB連線有誤",err);
        });
}
// 刪除圖片------------
async function apiDeleteOneImage(imageKey){
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
  };
  const body = {
    key: imageKey,
  };
  allapi2
    .imagesInfoDelete(params, body)
    .then((res) => {
      console.log("刪除圖片ok", res);
      // 重新呼叫所有圖片api----------------------
      updateImageInfo('descending')

      })
      .catch((err) => {
          console.log("刪除圖片連線有誤",err);
      });
}


</script>

<style scoped>
.backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.upload-image-modal-container {
  display: flex;
  width: 66.8%;
  max-width: 1000px;
  height: auto;
  background-color: white;
  margin: auto;
  margin-top: 3%;
  border-radius: 10px;
  flex-direction: column;
  position: relative;
}
.upload-input-title {
  display: flex;
}
.upload-search-text {
  font-size: 14px;
  color: #FFFFFF;
  min-width: 96px;
  border-radius: 5px;
  background-color: #71AFB6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15.23px;
}
.loading-image-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #EAF3F4;
  opacity: 0.6;
  z-index: 5;
  border-radius: 10px;
}
.loading-image-spinner::after {
  content: "";
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 5px solid #e5e5e5;
  border-top: 5px solid rgb(95, 88, 88);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: turn-around 1.0s linear infinite;
}
@keyframes turn-around {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.nav-bar {
  width: 100%;
  height: 30px;
  background: linear-gradient(270deg,#71afb6,#b4dbdf);
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
}
.close-cross {
  width: 12px;
  height: 12px;
  margin-left: auto;
  margin-right: 15px;
}
.load-img-section {
  padding: 25px 20px 19px 20px;
}
.custom-file-input {
  display: none;
}
.custom-file-label {
  display: flex;
}
.custom-file {
  padding: 2% 9%;
  background-color: rgba(234, 243, 244, 1);
  border-radius: 10px;
}
.customFile-img {
  margin-right: 2%;
}
.filter-search-section {
  display: flex;
  margin-top: 25px;
}
.dropdown-select-sort {
  margin-left: 25px;
}

.delete-image-btn {
  border-color: transparent;
  background-color: #EAF3F4;
  color: #71AFB6;
  font-size: 18px;
  border-radius: 10px;
  padding: 7px 22px;
  margin-left: 25px;
}
.block-icon {
  margin-left: auto;
  cursor: pointer;
}

.list-icon {
  margin-left: 25px;
  cursor: pointer;
}

.paginator {
  margin-bottom: 0;
  margin-left: 25px;
}
.paginator-count {
  width: 100%;
  font-size: 12px;
  text-align: end;
  color: #8A8A8A;
}
.card-container {
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  gap: 25px;
  margin-bottom: 19px;
  position: relative;
}
.card-container.row {
  display: flex;
}
/* transition 淡入淡出動畫*/
.v-enter-active,
.v-leave-active {
  transition:  opacity 0.5s ease,top 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  top: -8%;
  opacity: 0;
}

.v-enter-to,
.v-leave-from {
  top: 0;
  opacity: 1;
}

 
</style>