<template>
  <div class="dropdown">
    <a
      class="btn dropdown-toggle datepicker d-flex justify-content-between align-items-center"
      href="#"
      role="button"
      id="dropdownMenuLink"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="font-size: 14px"
    >
      <span class="d-flex align-items-center">
        <img class="me-2" :src="blackCalendar" alt="calendar" />
        <span>{{ dateSelector }}</span>
      </span>
      <span> {{ startDate }}~{{ endDate }} </span>
    </a>

    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
      <li class="change-date-li">
        <button
          class="change-date-button"
          @click="getPass1Days"
          data-gtm="time-range-option"
        >
          最近1天
        </button>
      </li>
      <li class="change-date-li">
        <button
          class="change-date-button"
          @click="getPass7Days"
          data-gtm="time-range-option"
        >
          最近7天
        </button>
      </li>
      <li
        class="change-date-li"
        v-if="
          pathName === '/GoogleadsTable' ||
          pathName === '/CrossAdsChart' ||
          pathName === '/FbadsTable' ||
          pathName === '/FbTrendAnal'
        "
      >
        <button
          class="change-date-button"
          @click="getPass14Days"
          data-gtm="time-range-option"
        >
          最近14天
        </button>
      </li>
      <li class="change-date-li">
        <button
          class="change-date-button"
          @click="getRecentMonth"
          data-gtm="time-range-option"
        >
          最近30天
        </button>
      </li>
      <li class="change-date-li">
        <button
          class="change-date-button"
          @click="getRecentSeason"
          data-gtm="time-range-option"
          v-if="pathName === '/BuildingCase'"
        >
          最近三個月
        </button>
      </li>
      <li class="change-date-li">
        <button
          class="change-date-button"
          @click="getRecentYear"
          data-gtm="time-range-option"
          v-if="pathName != '/BuildingCase'"
        >
          最近一年
        </button>
      </li>
      <li class="change-date-li" v-if="showLastMonth">
        <button
          class="change-date-button"
          @click="getLastMonth"
          data-gtm="time-range-option"
        >
          上個月
        </button>
      </li>
      <li class="change-date-li" v-if="showThisMonth">
        <button
          class="change-date-button"
          @click="getThisMonth"
          data-gtm="time-range-option"
        >
          這個月
        </button>
      </li>
      <li class="change-date-li">
        <button type="button" class="change-date-button" @click="selectCustom">
          自訂
        </button>
      </li>
      <li class="change-date-li">
        <button
          class="change-date-button"
          @click="getRecentAll"
          data-gtm="time-range-option"
        >
          全部
        </button>
      </li>
    </ul>
    <p
      class="ms-2 font-14 GrayC4 position-absolute"
      style="right: 5px"
      v-if="!isSpecialPathName"
    >
      {{ hasDataEnd }} 為最近一筆資料
    </p>
    <CustomDatePicker
      :dateRange="{ start: startDate, end: endDate }"
      :hasDataStart="hasDataStart"
      :hasDataEnd="hasDataEnd"
      :customDateModalId="customDateModalId"
      @customDatePicked="customDatePicked"
    />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import {
  ref,
  reactive,
  onMounted,
  watch,
  computed,
  defineProps,
  defineEmits,
} from "vue";
import CustomDatePicker from "./CustomDatePicker.vue";
import createModal from "../utility/createModal";
import developMode from "../config/developMode";

import blackCalendar from "../assets/black-calendar.svg";

const emit = defineEmits([
  "getRecentAll",
  "getRecentYear",
  "getRecentMonth",
  "getRecentSeason",
  "getPass14Days",
  "getPass7Days",
  "getPass1Days",
  "getLastMonth",
  "getThisMonth",
  "customDatePicker",
  "customDatePicked",
]);
const props = defineProps({
  dateSelector: {
    type: String,
    default: "最近30天",
  },
  startDate: {
    type: String,
    default: "2000-01-01",
  },
  endDate: {
    type: String,
    default: "2000-01-31",
  },
  hasDataStart: {
    type: String,
    default: "2000-01-01",
  },
  hasDataEnd: {
    type: String,
    default: "2000-12-31",
  },
  customDateModalId: {
    /* 自訂時間區間的彈窗id */
    /* 因為有些頁面會有一個以上的自訂彈窗，需要用此來判斷開啟哪個彈窗 */
    /* 用法可參考 IndicatorCompare.vue */
    type: String,
    default: "#exampleModal",
  },
  isPast: {
    type: Boolean,
    default: false,
  },
});

const pathName = useRoute().path;
const customDateModalId = computed(() => {
  return props.customDateModalId;
});
const isSpecialPathName = computed(() => {
  switch (pathName) {
    case "/CrossAdsChart":
      return true;
    case "/BuildingCase":
      return true;
    case "/FbadsTable":
      return true;
    case "/IndicatorCompare":
      return true;
    default:
      return false;
  }
});

const showThisMonth = computed(() => {
  switch (pathName) {
    case "/GoogleadsTable":
      return true;
    case "/CrossAdsChart":
      return true;
    case "/FbadsTable":
      return true;
    case "/FbTrendAnal":
      return true;
    case "/MemberData":
      return true;
    default:
      return false;
  }
});
const showLastMonth = computed(() => {
  switch (pathName) {
    case "/GoogleadsTable":
      return true;
    case "/CrossAdsChart":
      return true;
    case "/FbadsTable":
      return true;
    case "/FbTrendAnal":
      return true;
    case "/MemberData":
      return true;
    case "/IndicatorCompare":
      return true;
    default:
      return false;
  }
});

const getRecentAll = async function () {
  localStorage.setItem("time", "getRecentAll");
  emit("getRecentAll");
};
const getRecentYear = async function () {
  localStorage.setItem("time", "getRecentYear");
  emit("getRecentYear");
};
const getRecentMonth = async function () {
  localStorage.setItem("time", "getRecentMonth");
  emit("getRecentMonth");
};
const getRecentSeason = async function () {
  localStorage.setItem("time", "getRecentSeason");
  emit("getRecentSeason");
};
const getPass14Days = async function () {
  localStorage.setItem("time", "getPass14Days");
  emit("getPass14Days");
};
const getPass7Days = async function () {
  localStorage.setItem("time", "getPass7Days");
  emit("getPass7Days");
};
const getPass1Days = async function () {
  localStorage.setItem("time", "getPass1Days");
  emit("getPass1Days");
};
const getLastMonth = async function () {
  localStorage.setItem("time", "getLastMonth");
  emit("getLastMonth");
};
const getThisMonth = async function () {
  localStorage.setItem("time", "getThisMonth");
  emit("getThisMonth");
};

const customDatePicked = async function (date) {
  localStorage.setItem("time", "customDatePicker");
  // 在指標同比(IndicatorCompare)頁面，需判斷是否為上期
  // 若為上期則不需要儲存時間範圍，而是傳出時間另外處裡
  if (!props.isPast) {
    localStorage.setItem("startDate", date.start);
    localStorage.setItem("endDate", date.end);
    emit("customDatePicked");
  } else {
    emit("customDatePicked", date);
  }
};
function selectCustom() {
  const customModal = createModal(props.customDateModalId);
  customModal.show();
}
</script>

<style scoped>
.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 10000;
}
</style>
