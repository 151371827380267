<template>
  <div
    class="select-container"
    style="width: 100%; position: relative"
    ref="target"
    :id="props?.id"
  >
    <div
      class="d-flex justify-content-end align-items-center dropdown"
      @click="autoShow = !autoShow"
    >
      <span
        class="flex-fill text-start"
        :class="isTextNeedOverflow ? 'word-overflow' : ''"
        v-tooltip.bottom="
          isTextNeedOverflow && selectValue.length > textMaxLength
            ? { value: selectValue, class: 'tip-LabelManage' }
            : { value: '', class: '' }
        "
      >
        {{ selectValue }}
      </span>
      <img :src="greenDownTriangle" alt="searchImg" />
    </div>
    <div
      class="auto-complicate-div text-start cursor-pointer"
      style="width: 100%"
      :style="`max-height:${mxHeight}px;top:${topSide}px;bottom:${bottomSide}px`"
      v-show="
        (autoShow && valueArr[0] != undefined) ||
        (autoShow && Object.keys(valueArr).length != 0)
      "
      @mouseenter="autoShow = true"
    >
      <p
        v-for="(value, index) in valueArr"
        :key="index"
        class="m-0 auto-complicate-p d-flex justify-content-end align-items-center font-18"
        style="padding: 11px 14px; line-height: 18px"
        data-gtm="dropdown-option"
        @click="childClick(value, index)"
      >
        <span
          class="flex-fill"
          data-gtm="dropdown-option"
          v-if="Object.prototype.toString.call(valueArr) === '[object Object]'"
        >
          {{ index }}
        </span>
        <span
          class="flex-fill word-overflow"
          data-gtm="dropdown-option"
          v-else-if="isTextNeedOverflow"
        >
          <span
            class="text-nowrap"
            v-tooltip.bottom="
              value.length > textMaxLength
                ? { value: value, class: 'tip-LabelManage' }
                : { value: '', class: '' }
            "
          >
            {{ value }}
          </span>
        </span>
        <span class="flex-fill" data-gtm="dropdown-option" v-else>
          {{ value }}
        </span>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import { onClickOutside } from "@vueuse/core";
import greenDownTriangle from "../assets/green-down-triangle.svg";

const props = defineProps({
  id: {
    //整個下拉選單的id。Google gtm埋碼會用來辨識下拉選單
    type: String,
    default: "",
  },
  topSide: {
    type: Number,
  },
  bottomSide: {
    type: Number,
  },
  mxHeight: {
    //選單的最大高度
    type: Number,
  },
  valueArr: {
    type: [Array, Object],
  },
  selectValue: {
    type: String,
  },
  changingSelected: {
    type: String,
  },
  isTextNeedOverflow: {
    //文字是否要省略符號、出現v-tooltip?
    type: Boolean,
    default: false,
  },
  textMaxLength: {
    //文字長度到多少時要出現v-tooltip?
    type: Number,
    default: 9,
  },
});
const topSide = computed(() => {
  return props.topSide;
});
const mxHeight = computed(() => {
  return props.mxHeight;
});
const valueArr = computed(() => {
  return props.valueArr;
});
const selectValue = computed(() => {
  return props.selectValue;
});
const changingSelected = computed(() => {
  return props.changingSelected;
});
const isTextNeedOverflow = computed(() => {
  return props.isTextNeedOverflow;
});
const textMaxLength = computed(() => {
  return props.textMaxLength;
});

const emit = defineEmits(["selectValueByDrop"]);
const autoShow = ref(false);
const target = ref(null);

onClickOutside(target, () => (autoShow.value = false));

const childClick = (value, index) => {
  emit("selectValueByDrop", value, changingSelected.value, index);
  autoShow.value = false;
};
</script>

<style scoped>
.dropdown {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 0 14px;
  height: 40px;
  cursor: pointer;
}
.auto-complicate-div {
  position: absolute;
  box-shadow: none;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  background-color: white;
  z-index: 99;
  max-height: 200px;
  overflow: auto;
}
.select-container {
  color: #575757;
  text-align: center;
  cursor: default;
  /* cursor: pointer; */
}
.auto-complicate-p {
  color: #575757;
}
.auto-complicate-p:hover {
  background-color: #eaf3f4;
  color: #575757;
}
</style>
