<template>
  <div class="container home">
    <div class="header d-flex justify-content-between">
      <div class="d-flex" style="position: relative">
        <span class="title-span fs-medium">營運總覽</span>
        <div class="switch-button-container fs-small">
          <div v-for="buttons in buttonGroup" :key="buttons">
            <button
              class="switch-button"
              :class="this.mode === buttons ? 'switch-button-click' : ''"
              @click="changeMode(buttons)"
            >
              {{ buttons }}
            </button>
          </div>
        </div>
      </div>
      <!--新的時間選擇器模組-->
      <DateSelectNew :sdkApiName="'timeSalesGet'" />
    </div>
    <div
      class="col-12 container p-0 mt-4 d-flex"
      :style="dashBoardWrapperStyle"
    >
      <ul
        :class="mode === '全部' ? '' : 'col-12'"
        :style="mode === '全部' ? 'flex: 18' : ''"
      >
        <li :style="basicDataWrapperStyle">
          <div
            v-for="business in businessDisplay"
            :key="business"
            :class="mode === '全部' ? '' : 'online-width'"
          >
            <ul class="business-display-container">
              <li class="dash-board-title" style="margin-bottom: 12px">
                {{ business.title }}
              </li>
              <li class="d-flex justify-content-between">
                <div>
                  <span
                    class="fs-medium"
                    v-if="selectedSumNew[business.title] === undefined"
                    >- -&nbsp;{{ business.unit }}</span
                  >
                  <span class="fs-medium" v-else
                    >{{ selectedSumNew[business.title] }}&nbsp;{{
                      business.unit
                    }}</span
                  >
                </div>
                <div class="d-flex text-nowrap" style="">
                  <img
                    class="arrow"
                    :src="
                      selectedSumNew[business.radioTitle] >= 0
                        ? redUpArrow
                        : greenDownArrow
                    "
                    alt="up or down arrow"
                    v-if="selectedSumNew[business.radioTitle] != undefined"
                  />
                  <span
                    class="font-18"
                    :class="
                      selectedSumNew[business.radioTitle] >= 0
                        ? 'LightRed'
                        : 'LightGreen'
                    "
                    v-if="selectedSumNew[business.radioTitle] != undefined"
                  >
                    <span
                      >{{ selectedSumNew[business.radioTitle] }}&nbsp;%</span
                    >
                  </span>
                  <span
                    class="Cyan"
                    v-if="selectedSumNew[business.radioTitle] === undefined"
                    >(&nbsp;- -&nbsp;% )</span
                  >
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="dash-board" v-if="mode === '全部'">
          <span class="dash-board-title">通路線上/線下佔比</span>
          <div id="donut" style="position: relative">
            <span
              class="text-center"
              style="position: absolute; top: 20%; left: 24%; color: #c4c4c4"
              v-if="this.series2.every((d) => d === 0)"
            >
              <img src="../assets/missData.svg" alt="" style="width: 200px" />
              <br />
              此時間段無資料</span
            >
            <apexchart
              type="donut"
              height="300"
              :options="chartOptions2"
              :series="series2"
            ></apexchart>
          </div>
        </li>
      </ul>
      <ul class="d-flex flex-column" style="flex: 25; gap: 25px">
        <li class="dash-board line-bar-div">
          <span class="dash-board-title">營業額 X 訂單數</span>
          <div id="lineBar" style="position: relative">
            <span
              class="GrayC4"
              style="position: absolute; top: 40%; left: 40%"
              v-if="this.selectedMixedLine.日期[0] === undefined"
              >此時間段無資料</span
            >
            <apexchart
              type="line"
              height="330"
              :options="chartOptionSalesAndOrder"
              :series="chartSeriesSalesAndOrder"
            ></apexchart>
          </div>
        </li>
        <li class="dash-board" v-if="mode === '全部'">
          <span class="dash-board-title">線上線下消費走勢</span>
          <div id="line" style="position: relative">
            <apexchart
              type="line"
              height="260"
              :options="chartOptionPurchaseTrend"
              :series="chartSeriesPurchaseTrend"
            ></apexchart>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { allapi } from "../../public/static/api/apigClient.js";
import VueApexCharts from "vue3-apexcharts";
import DateSelect from "@/components/DateSelect.vue";
import keepTime from "../utility/keepTime";
import redUpArrow from "../assets/redUpArrow.svg";
import greenDownArrow from "../assets/greenDownArrow.svg";
import DateFunction from "../utility/DateSelector.js";
import developMode from "../config/developMode";
import { useLoadingStore } from "../store/useLoadingStore";
import DateSelectNew from "../components/DateSelectNew.vue";
import { useDateRangeStore } from "../store/useDateRangeStore";

export default {
  name: "OperationalOverview",
  components: {
    apexchart: VueApexCharts,
    DateSelect,
    redUpArrow,
    greenDownArrow,
    DateSelectNew,
  },
  data() {
    return {
      loadingStore: useLoadingStore(),
      developMode,

      mode: "全部",
      buttonGroup: ["全部", "線上", "線下"],
      businessDisplay: [
        { title: "營業額", unit: "($)", radioTitle: "營業額上期同比" },
        { title: "客單價", unit: "($)", radioTitle: "客單價上期同比" },
        { title: "訂單數", unit: "(次)", radioTitle: "訂單數上期同比" },
      ],
      redUpArrow: redUpArrow,
      greenDownArrow: greenDownArrow,
      customDate: {
        start: "",
        end: "",
      },
      customDateConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },

      dateSelector: "最近30天",

      // dount
      series2: [0, 0],
      chartOptions2: {},
      // line
      chartSeriesPurchaseTrend: [
        {
          data: [],
        },
      ],
      chartOptionPurchaseTrend: {},

      sumOFBussAll: null,

      apiChannelPie: [],
      apiNumber: [],
      apiMixLine: [],
      apiMixColumm: [],
      apiLine: [],

      online: false,
      offline: false,
      all: true,

      selectedSumNew: [],
      selectedMixedLine: {
        日期: [],
        營業額: [],
        訂單數: [],
      },
      selectedPieNew: [],
      selectedLineNew: [],

      chartSeriesSalesAndOrder: [],
      chartOptionSalesAndOrder: {},

      onlineAndOffline: "全部",
      // pinia 時間資料
      startDate: "",
      endDate: "",
      hasDataStart: "",
      hasDataEnd: "",

      endYear: 2022,
      endMonth: 6,
    };
  },
  created: async function () {},

  // 接回pinia時間資料
  computed: {
    selectedStartDate() {
      return useDateRangeStore().selectedStartDate;
    },
    selectedEndDate() {
      return useDateRangeStore().selectedEndDate;
    },
    // 新增fullSelectedDate
    selectedFullDate() {
      return (
        useDateRangeStore().selectedStartDate +
        useDateRangeStore().selectedEndDate
      );
    },
    selectedRangeTitle() {
      return useDateRangeStore().rangeTitle;
    },
    dashBoardWrapperStyle() {
      if (this.mode === "全部") {
        return "gap:25px;";
      } else {
        return "flex-wrap:wrap;";
      }
    },
    basicDataWrapperStyle() {
      if (this.mode === "全部") {
        return "";
      } else {
        return "display:flex; gap:25px; ";
      }
    },
  },

  // 點選其他業頁面，再回到該頁面重新加載時
  // mounted: async function () {
  //   if (this.selectedRangeTitle) {
  //     this.selectedTimePeriod()
  //     return
  //   } else {
  //     return
  //   }

  // },

  // 頁面第一次加仔等待時間選擇器接回時間區間資料時
  watch: {
    selectedFullDate: {
      async handler() {
        if (this.selectedRangeTitle) {
          await this.selectedTimePeriod();
          this.toRender++;
          return;
        }
      },
      immediate: true,
    },
    mode: {
      handler(newMde) {
        this.$forceUpdate(); //手動觸發渲染，避免"全部"與"線上線下"版面沒有成功切換
      },
    },
  },

  methods: {
    // 初始化圖表資料function
    // 新的時間選擇器連結function
    selectedTimePeriod: async function () {
      this.loadingStore.startLoading();
      this.startDate = this.selectedStartDate;
      this.endDate = this.selectedEndDate;

      await Promise.all([
        this.postPieData(),
        this.postNumberData(),
        this.postMixedChartData(),
        this.postLineData(),
      ]);
      this.changeMode(this.mode);
      // // apexchart繪圖
      this.getAll();
      this.loadingStore.endLoading();
    },

    // getTime: async function () {
    //   const token = sessionStorage.getItem("access_token");
    //   const params = {
    //     Authorization: "Bearer " + token,
    //   };
    //   await allapi
    //     .timeSalesGet(params)
    //     .then((res) => {
    //       console.log("取得時間", res);
    //       const {startDate,endDate}= DateSelectorInitialize(res.data.Data.start_date,res.data.Data.end_date)
    //       this.selectedTimePeriod(startDate,endDate)
    //     })
    //     .catch((err) => {
    //       console.log("連線有誤", err);
    //       this.loadingStore.endLoading();
    //     });
    // },
    changeMode(Mode) {
      this.mode = Mode;
      switch (Mode) {
        case "全部":
          this.getAll();
          break;
        case "線上":
          this.getOnline();
          break;
        case "線下":
          this.getOffline();
          break;
        default:
          break;
      }
    },
    postNumberData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationMetricsIndexGet(params)
        .then((res) => {
          console.log("營業額number", res);
          const target = res.data.Data;
          if (target.整體.營業額 === undefined) {
            target.整體 = {};
          } else {
            target.整體.營業額 = target.整體.營業額.toLocaleString();
            target.整體.客單價 = target.整體.客單價.toLocaleString();
            target.整體.訂單數 = target.整體.訂單數.toLocaleString();
          }

          if (target.線上.營業額 === undefined) {
            target.線上 = {};
          } else {
            target.線上.營業額 = target.線上.營業額.toLocaleString();
            target.線上.客單價 = target.線上.客單價.toLocaleString();
            target.線上.訂單數 = target.線上.訂單數.toLocaleString();
          }
          if (target.線下.營業額 === undefined) {
            target.線下 = {};
          } else {
            target.線下.營業額 = target.線下.營業額.toLocaleString();
            target.線下.客單價 = target.線下.客單價.toLocaleString();
            target.線下.訂單數 = target.線下.訂單數.toLocaleString();
          }

          this.apiNumber = res.data.Data;
          // this.getAll();
          // this.apiNumber = this.apiNumber.toLocaleString()
        })
        .catch((err) => {
          console.log("連線有誤", err);

          this.loadingStore.endLoading();
        });
    },
    postMixedChartData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationMetricsSalesxordersGet(params)
        .then((res) => {
          console.log("mixed", res);
          this.apiMixLine = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤", err);
          this.loadingStore.endLoading();
        });
    },

    postPieData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      await allapi
        .operationMetricsChannelrateGet(params)
        .then((res) => {
          console.log("chanelpie", res.data.Data[0]);

          this.apiChannelPie = res.data.Data[0];
        })
        .catch((err) => {
          console.log("連線有誤", err);
          this.loadingStore.endLoading();
        });
    },

    postLineData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationMetricsOnlinetrendGet(params)
        .then((res) => {
          console.log("lineapi", res);
          this.apiLine = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤", err);
          this.loadingStore.endLoading();
        });
    },

    pieChart() {
      (this.series2 = [this.selectedPieNew.線上, this.selectedPieNew.線下]), //資料數據
        (this.chartOptions2 = {
          chart: {
            height: 330,
            type: "donut",
          },
          colors: ["#63A7C8", "#F9C357"],
          labels: ["線上", "線下"],
          legend: {
            position: "bottom",
          },
          responsive: [
            {
              // breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          noData: {
            text: "此時間段無資料",
            align: "center",
            verticalAlign: "top",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#c4c4c4",
              fontSize: "14px",
              fontFamily: "Noto Sans TC",
              fontWeight: 400,
            },
          },
        });
    },
    lineChart() {
      this.chartSeriesPurchaseTrend = [
        {
          name: "線上",
          data: this.selectedLineNew.線上,
        },
        {
          name: "線下",
          data: this.selectedLineNew.線下,
        },
      ];
      this.chartOptionPurchaseTrend = {
        chart: {
          height: 280,
          type: "line",
          dropShadow: {
            enabled: false,
            color: "#575757",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        colors: ["#63A7C8", "#F9C357"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        title: {
          align: "left",
          style: {
            fontSize: "20px",
            fontWeight: "medium",
            color: "#71AFB6",
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: this.selectedLineNew.日期,
          tickAmount: 7,
          labels: {
            style: {
              colors: ["#8a8a8a"], //刻線的文字顏色
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          title: {
            text: "營業額",
            style: {
              color: "#8A8A8A",
              fontSize: "12px",
            },
          },
          labels: {
            style: {
              colors: ["#8a8a8a"], //刻線的文字顏色
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          offsetY: 6,
          offsetX: -5,
        },
        noData: {
          text: "此時間範圍無資料",
          align: "center",
          verticalAlign: "center",
          offsetX: 0,
          offsetY: -15,
          style: {
            color: "#c4c4c4",
            fontSize: "14px",
            fontFamily: "Noto Sans TC",
            fontWeight: 400,
          },
        },
      };
    },
    mixedChart() {
      (this.chartSeriesSalesAndOrder = [
        {
          name: "營業額",
          type: "column",
          data: this.selectedMixedLine.營業額,
        },
        {
          name: "訂單數",
          type: "line",
          data: this.selectedMixedLine.訂單數,
        },
      ]),
        (this.chartOptionSalesAndOrder = {
          // 圖表動畫選項
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 500,
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          chart: {
            // width:820,
            height: 320,
            type: "line",
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
            },
          },
          colors: ["#63A7C8", "#F9C357"],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            curve: "smooth",
            width: 2,
          },
          title: {
            align: "left",
            style: {
              fontSize: "20px",
              fontWeight: "medium",
              color: "#71AFB6",
            },
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 0,
          },
          xaxis: {
            categories: this.selectedMixedLine.日期,
            tickAmount: 7,
            labels: {
              show: true,
              style: {
                colors: ["#8a8a8a"],
                fontSize: "12px",
              },
            },
          },
          yaxis: [
            {
              title: {
                text: "營業額",
                style: {
                  color: "#8a8a8a",
                  fontSize: "12px",
                },
              },
              labels: {
                style: {
                  colors: ["#8a8a8a"],
                  fontSize: "12px",
                },
                formatter: function (y) {
                  return y.toLocaleString();
                },
              },
            },
            {
              opposite: true,
              title: {
                text: "訂單數",
                style: {
                  fontSize: "12px",
                },
              },
              labels: {
                style: {
                  fontSize: "12px",
                },
                formatter: function (y) {
                  return y.toLocaleString();
                },
              },
            },
          ],

          responsive: [
            {
              //  breakpoint: 1000,
              options: {
                width: 820,
              },
            },
          ],

          legend: {
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            offsetX: -5,
            labels: {
              colors: "#8a8a8a",
            },
          },
        });
    },

    getAll: async function () {
      this.mode = "全部";
      this.onlineAndOffline = "整體";
      this.onlineOrOffline = "all";

      this.selectedSumNew = this.apiNumber.整體;
      this.selectedMixedLine = this.apiMixLine.整體;
      this.selectedPieNew = this.apiChannelPie;
      this.selectedLineNew = this.apiLine;

      this.loadingStore.startLoading();
      //取得資料，開始畫圖表
      this.pieChart();
      this.lineChart();
      this.mixedChart();
      this.loadingStore.endLoading();
    },
    getOnline: async function () {
      this.mode = "線上";
      this.onlineAndOffline = "線上";
      this.onlineOrOffline = "onlineAction";

      this.selectedSumNew = this.apiNumber.線上;
      this.selectedMixedLine = this.apiMixLine.線上;
      this.loadingStore.startLoading();
      await setTimeout(() => {
        this.mixedChart();
      }, 500);
      this.loadingStore.endLoading();
    },
    getOffline: async function () {
      this.mode = "線下";
      this.onlineAndOffline = "線下";
      this.onlineOrOffline = "offlineAction";

      this.selectedSumNew = this.apiNumber.線下;
      this.selectedMixedLine = this.apiMixLine.線下;
      this.loadingStore.startLoading();
      await setTimeout(() => {
        this.mixedChart();
      }, 500);
      this.loadingStore.endLoading();
    },
  },
};
</script>

<style scoped>
.arrow {
  width: 8px;
  margin-right: 10px;
  padding-bottom: 6px;
}
.business-display-container {
  height: 114px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  margin-bottom: 24px;
}
.online-width {
  width: calc(100% / 3);
}
.line-bar-div {
  height: 390px;
}
.pie-div-OperationalOverview {
  flex: 18;
}
.purchase-trend-container {
  flex: 25;
}
.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.confirm-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}
.parent-popout {
  position: relative;
}

.child-popout {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -30px;
  color: #fff;
  text-align: left;
  padding: 10px;
}

.parent-popout:hover .container__arrow,
.parent-popout:hover .child-popout {
  opacity: 1;
  pointer-events: initial;
}

.container__arrow {
  /* Invisible by default */
  opacity: 1;
  pointer-events: none;
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: 50%;
  left: -0.8%;
  position: absolute;
  transform: translate(-68%, 7px) rotate(90deg);
  height: 0;
  width: 0;
  z-index: 10;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
@media (max-width: 1200px) {
  .online-width {
    width: 22vw;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
