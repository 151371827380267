import { defineStore } from "pinia";

export const useLoadingStore = defineStore({
  id: "loadingStore",
  state: () => ({ loading: false }),
  actions: {
    startLoading() {
      this.loading = true;
    },
    endLoading() {
      this.loading = false;
    },
  },
});
