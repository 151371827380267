<template>
  <Modal
    :hasBodyButtonList="false"
    :setModalStyle="{ width: '500px' }"
    @closeModal="closeModal()"
  >
    <template #modalContent>
      <div class="text-center">{{ props.content }}</div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import Modal from "../Modal.vue";

const emits = defineEmits(["closeModal"]);
const props = defineProps({
  content: {
    type: String,
    default: "",
  },
});

function closeModal() {
  emits("closeModal");
}
</script>
