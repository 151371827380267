import { defineStore } from "pinia";
import moment from "moment/moment";

export const useDateRangeStore = defineStore({
  id: "dateRangeStore",
  state: () => ({
    startDate: "", // 第一筆資料時間
    endDate: "", // 最後一筆資料時間
    selectedStartDate: "", // 選擇起始時間
    selectedEndDate: "", // 選擇結束時間
    minimumSelectableDate: "",
    rangeTitle: "", // UI顯示的選擇時間種類
  }),
  actions: {
    setDateRange(start, end) {
      console.log("pinia儲存的時間區間", start, end);
      this.startDate = start;
      this.endDate = end;
    },
    setRangeTitle(title) {
      this.rangeTitle = title;
    },
    setMinimumSelectableDate(createdTime) {
      // -90 days
      if (createdTime === "") return (this.minimumSelectableDate = null);

      const date = moment(createdTime)
        .subtract(89, "days")
        .format("YYYY-MM-DD");
      this.minimumSelectableDate = date;
    },
    setDay() {
      // -1 day
      this.selectedStartDate = this.endDate;
      this.selectedEndDate = this.endDate;
    },
    set3Day() {
      // -3day
      const start = moment(this.endDate)
        .subtract(3, "days")
        .format("YYYY-MM-DD");
      this.selectedStartDate = start;
      this.selectedEndDate = this.endDate;
    },
    setWeek() {
      // -7 days
      const start = moment(this.endDate)
        .subtract(6, "days")
        .format("YYYY-MM-DD");
      this.selectedStartDate = start;
      this.selectedEndDate = this.endDate;
    },
    setTwoWeeks() {
      // -14 days
      const start = moment(this.endDate)
        .subtract(12, "days")
        .format("YYYY-MM-DD");
      this.selectedStartDate = start;
      this.selectedEndDate = this.endDate;
    },
    setMonth() {
      // -30 days
      const start = moment(this.endDate)
        .subtract(29, "days")
        .format("YYYY-MM-DD");
      this.selectedStartDate = start;
      this.selectedEndDate = this.endDate;
    },
    setLastMonth() {
      const startDate = moment(new Date(this.endDate))
        .subtract(1, "months")
        .startOf("months")
        .format("YYYY-MM-DD");
      const endDate = moment(new Date(this.endDate))
        .subtract(1, "months")
        .endOf("months")
        .format("YYYY-MM-DD");
      this.selectedStartDate = startDate;
      this.selectedEndDate = endDate;
      return { startDate, endDate };
    },
    setSeason() {
      // -90 days
      const start = moment(this.endDate)
        .subtract(89, "days")
        .format("YYYY-MM-DD");
      this.selectedStartDate = start;
      this.selectedEndDate = this.endDate;
    },
    setYear() {
      // -90 days
      const start = moment(this.endDate)
        .subtract(1, "years")
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.selectedStartDate = start;
      this.selectedEndDate = this.endDate;
    },
    setCustom(start, end) {
      this.selectedStartDate = start;
      this.selectedEndDate = end;
    },
    setAllPeriod() {
      this.selectedStartDate = this.startDate;
      this.selectedEndDate = this.endDate;
    },
  },
  persist: true, // 使用者選擇區間存入session storage
});
