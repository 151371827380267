<template>
  <div>
    <div class="input-row">
      <label for="" class="label-name">{{ labelName }}</label>
      <div class="input-wrap">
        <input
          type="text"
          :value="defaultValInput"
          class="input"
          :disabled="disable || isApiLoading"
          @change="($event) => handleInputOnChange($event)"
          :class="[isApiLoading ? 'load' : '']"
        />
        <button class="copy-btn" @click="storeAndCopyModifiedUrl($event)">
          {{ btnName }}
        </button>
      </div>
    </div>
    <div class="success-msg" v-show="apiModifiedSuccess">儲存成功</div>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
import { allapi2 } from "../../../public/static2/api/apigClient";
import {updateCustomParamInfo} from "../../../public/api/apiCustomParams"
import { useRoute } from "vue-router";
const props = defineProps({
  labelName: {
    type: String,
    default: "",
  },
  defaultVal: {
    type: String,
    default: "",
  },
  disable: {
    type: Boolean,
    default: false,
  },
  btnName: {
    type: String,
    default: "複製",
  },
});
const defaultValInput = ref(props.defaultVal);
const isApiLoading = ref(false);
const apiModifiedSuccess = ref(false);
const route = useRoute()

// 取消訂閱網址輸入修改-------------
function handleInputOnChange(event) {
  if (event.target.value.trim()) {
    defaultValInput.value = event.target.value;
  }
}
async function storeAndCopyModifiedUrl(event) {
  switch (event.target.innerText) {
    case "複製":
      // 複製按鈕
      navigator.clipboard.writeText(props.defaultVal);
      break;
    case "儲存":
      // 儲存按紐
      await apiPostTemplateUnsubscribeUrl(defaultValInput.value);
      break;
    default:
      console.warn("未定義的按鈕", event.target.innerText);
      break;
  }

  // if (event.target.innerText === '複製') {
  //   navigator.clipboard.writeText(props.defaultVal)
  // }
  // // 儲存按紐
  // else if (event.target.innerText === '儲存') {
  //   await apiPostTemplateUnsubscribeUrl(defaultValInput.value)
  // }
}
// 修改取消訂閱網址api
async function apiPostTemplateUnsubscribeUrl(newUrl) {
  isApiLoading.value = true;
  if(route.path.includes("_New")) {
    try {
      const res = await updateCustomParamInfo(newUrl)
      console.log('更新取消訂閱網址過程ok',res)
      sessionStorage.setItem("varUrl", res.value);
      apiModifiedSuccess.value = true;
      isApiLoading.value = false;
      setTimeout(() => {
        apiModifiedSuccess.value = false;
      }, 2000);
    } catch (err) {
      console.log('更新取消訂閱網址過程有誤',err)
    }
    return
  }
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
  };
  const body = {
    html: newUrl,
  };
  allapi2
    .templatev2UnsubscribeUrlPost(params, body)
    .then((res) => {
      console.log("儲存取消訂閱的網址ok", res);
      sessionStorage.setItem("varUrl", res.data.content);
      apiModifiedSuccess.value = true;
      isApiLoading.value = false;
      setTimeout(() => {
        apiModifiedSuccess.value = false;
      }, 2000);
    })
    .catch((err) => {
      isApiLoading.value = false;
      console.log("儲存取消訂閱的網址連線有誤", err);
    });
}
</script>
<style scoped>
.input-row {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.input-wrap {
  display: flex;
  width: 100%;
}
.input-wrap.success::after {
  content: "修改成功";
  color: #71afb6;
}
.input {
  border: 1px solid #c4c4c4;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 40px;
  width: 90%;
  border-right: none;
}
.copy-btn {
  height: 40px;
  background: #71afb6;
  color: #ffffff;
  font-size: 18px;
  width: 100px;
  border: 1px solid #c4c4c4;
  border-left: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.label-name {
  color: #71afb6;
  font-size: 18px;
  margin-right: 25px;
  min-width: 80px;
}
.success-msg {
  color: #71afb6;
  width: 100%;
  font-size: 14px;
  position: relative;
  top: -25px;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

/* loading skeleton*/
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
.load {
  background: linear-gradient(
      100deg,
      rgba(256, 256, 256, 0) 0%,
      rgba(256, 256, 256, 0.5) 100%,
      rgba(256, 256, 256, 0) 30%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 0.5s loading ease-in-out infinite;
}
</style>
