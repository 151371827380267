/*
 * Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

var apigClientFactory = {};
apigClientFactory.newClient = function (config) {
  var apigClient = {};
  if (config === undefined) {
    config = {
      accessKey: "",
      secretKey: "",
      sessionToken: "",
      region: "ap-northeast-1",
      apiKey: "Rx1MmkMDROayqGCtfXCcQ7FqpeXWSshs7TPHRx0B",
      defaultContentType: "application/json",
      defaultAcceptType: "application/json",
    };
  }
  if (config.accessKey === undefined) {
    config.accessKey = "";
  }
  if (config.secretKey === undefined) {
    config.secretKey = "";
  }
  if (config.apiKey === undefined) {
    config.apiKey = "";
  }
  if (config.sessionToken === undefined) {
    config.sessionToken = "";
  }
  if (config.region === undefined) {
    config.region = "us-east-1";
  }
  //If defaultContentType is not defined then default to application/json
  if (config.defaultContentType === undefined) {
    config.defaultContentType = "application/json";
  }
  //If defaultAcceptType is not defined then default to application/json
  if (config.defaultAcceptType === undefined) {
    config.defaultAcceptType = "application/json";
  }

  // extract endpoint and path from url
  var invokeUrl = process.env.NODE_ENV==='development'
  ?`https://lyfed2ygdc.execute-api.ap-northeast-1.amazonaws.com/dev`:
  `https://lyfed2ygdc.execute-api.ap-northeast-1.amazonaws.com/cdp`
  var endpoint = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl)[1];
  var pathComponent = invokeUrl.substring(endpoint.length);

  var sigV4ClientConfig = {
    accessKey: config.accessKey,
    secretKey: config.secretKey,
    sessionToken: config.sessionToken,
    serviceName: "execute-api",
    region: config.region,
    endpoint: endpoint,
    defaultContentType: config.defaultContentType,
    defaultAcceptType: config.defaultAcceptType,
  };

  var authType = "NONE";
  if (
    sigV4ClientConfig.accessKey !== undefined &&
    sigV4ClientConfig.accessKey !== "" &&
    sigV4ClientConfig.secretKey !== undefined &&
    sigV4ClientConfig.secretKey !== ""
  ) {
    authType = "AWS_IAM";
  }

  var simpleHttpClientConfig = {
    endpoint: endpoint,
    defaultContentType: config.defaultContentType,
    defaultAcceptType: config.defaultAcceptType,
  };

  var apiGatewayClient = apiGateway.core.apiGatewayClientFactory.newClient(
    simpleHttpClientConfig,
    sigV4ClientConfig
  );

  apigClient.aiAnalysisOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var aiAnalysisOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/ai_analysis").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      aiAnalysisOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.aiAnalysisProductInfoGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["product_cate", "Authorization", "product_rk"],
      ["body"]
    );

    var aiAnalysisProductInfoGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/ai_analysis/product_info").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "product_cate",
        "product_rk",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      aiAnalysisProductInfoGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.aiAnalysisProductInfoOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var aiAnalysisProductInfoOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/ai_analysis/product_info").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      aiAnalysisProductInfoOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.aiAnalysisRecommendShowGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["product_cate", "ids", "product_id", "Authorization"],
      ["body"]
    );

    var aiAnalysisRecommendShowGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/ai_analysis/recommend_show").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "product_cate",
        "ids",
        "product_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      aiAnalysisRecommendShowGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.aiAnalysisRecommendShowOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var aiAnalysisRecommendShowOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/ai_analysis/recommend_show").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      aiAnalysisRecommendShowOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.aiAnalysisRecommendShowExportGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["product_id", "product_cate", "Authorization"],
      ["body"]
    );

    var aiAnalysisRecommendShowExportGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/ai_analysis/recommend_show/export").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "product_id",
        "product_cate",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      aiAnalysisRecommendShowExportGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.aiAnalysisRecommendShowExportOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var aiAnalysisRecommendShowExportOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/ai_analysis/recommend_show/export").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      aiAnalysisRecommendShowExportOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.authOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var authOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/auth").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      authOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.authSubuserPost = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var authSubuserPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/auth/subuser").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      authSubuserPostRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.authSubuserOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var authSubuserOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/auth/subuser").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      authSubuserOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.authSubuserListGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var authSubuserListGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/auth/subuser/list").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      authSubuserListGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.authSubuserListOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var authSubuserListOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/auth/subuser/list").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      authSubuserListOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.authSubuserUsernameDelete = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["username", "Authorization"],
      ["body"]
    );

    var authSubuserUsernameDeleteRequest = {
      verb: "delete".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/auth/subuser/{username}").expand(
          apiGateway.core.utils.parseParametersToObject(params, ["username"])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      authSubuserUsernameDeleteRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.authSubuserUsernamePatch = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["username", "Authorization"],
      ["body"]
    );

    var authSubuserUsernamePatchRequest = {
      verb: "patch".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/auth/subuser/{username}").expand(
          apiGateway.core.utils.parseParametersToObject(params, ["username"])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      authSubuserUsernamePatchRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.authSubuserUsernameOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var authSubuserUsernameOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/auth/subuser/{username}").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      authSubuserUsernameOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.authSubuserUsernameSendVerifycodeGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["username", "Authorization"],
      ["body"]
    );

    var authSubuserUsernameSendVerifycodeGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/auth/subuser/{username}/send_verifycode").expand(
          apiGateway.core.utils.parseParametersToObject(params, ["username"])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      authSubuserUsernameSendVerifycodeGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.authSubuserUsernameSendVerifycodeOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var authSubuserUsernameSendVerifycodeOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/auth/subuser/{username}/send_verifycode").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      authSubuserUsernameSendVerifycodeOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflineOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOfflineOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflineOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflineDayGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOfflineDayGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline/day").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflineDayGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflineDayOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOfflineDayOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline/day").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflineDayOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflineMemberGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOfflineMemberGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline/member").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflineMemberGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflineMemberOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOfflineMemberOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline/member").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflineMemberOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflinePieGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOfflinePieGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline/pie").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflinePieGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflinePieOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOfflinePieOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline/pie").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflinePieOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflineTimeGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOfflineTimeGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline/time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflineTimeGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflineTimeOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOfflineTimeOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline/time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflineTimeOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflineWeekGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOfflineWeekGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline/week").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflineWeekGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOfflineWeekOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOfflineWeekOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/offline/week").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOfflineWeekOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOnlineOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineBuyBehaGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOnlineBuyBehaGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/buy_beha").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineBuyBehaGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineBuyBehaOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOnlineBuyBehaOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/buy_beha").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineBuyBehaOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineBuyJourGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOnlineBuyJourGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/buy_jour").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineBuyJourGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineBuyJourOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOnlineBuyJourOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/buy_jour").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineBuyJourOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineDayGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOnlineDayGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/day").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineDayGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineDayOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOnlineDayOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/day").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineDayOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineRaterateGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOnlineRaterateGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/raterate").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineRaterateGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineRaterateOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOnlineRaterateOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/raterate").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineRaterateOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineTimeGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOnlineTimeGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineTimeGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineTimeOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOnlineTimeOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineTimeOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineWeekGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOnlineWeekGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/week").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineWeekGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOnlineWeekOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOnlineWeekOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/online/week").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOnlineWeekOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOverallOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallAllTimeGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOverallAllTimeGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/all_time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallAllTimeGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallAllTimeOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOverallAllTimeOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/all_time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallAllTimeOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallCompareGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOverallCompareGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/compare").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallCompareGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallCompareOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOverallCompareOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/compare").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallCompareOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallPieGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOverallPieGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/pie").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallPieGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallPieOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOverallPieOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/pie").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallPieOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallRateGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOverallRateGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/rate").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallRateGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallRateOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOverallRateOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/rate").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallRateOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallRate2Get = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOverallRate2GetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/rate2").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallRate2GetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallRate2Options = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOverallRate2OptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/rate2").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallRate2OptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallSaleTrendGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var behaviorOverallSaleTrendGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/sale_trend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallSaleTrendGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.behaviorOverallSaleTrendOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var behaviorOverallSaleTrendOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/behavior/overall/sale_trend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      behaviorOverallSaleTrendOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingBookingCaseGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var buildingBookingCaseGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/booking_case").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingBookingCaseGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingBookingCaseOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingBookingCaseOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/booking_case").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingBookingCaseOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingBookingPerformanceGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["label_id", "Authorization"],
      ["body"]
    );

    var buildingBookingPerformanceGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/booking_performance").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "label_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingBookingPerformanceGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingBookingPerformanceOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingBookingPerformanceOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/booking_performance").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingBookingPerformanceOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingBookingTimeGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var buildingBookingTimeGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/booking_time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingBookingTimeGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingBookingTimeOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingBookingTimeOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/booking_time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingBookingTimeOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingBookingTop10Get = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["label_id", "Authorization"],
      ["body"]
    );

    var buildingBookingTop10GetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/booking_top10").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "label_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingBookingTop10GetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingBookingTop10Options = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingBookingTop10OptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/booking_top10").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingBookingTop10OptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingBookingTrendGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "type", "Authorization"],
      ["body"]
    );

    var buildingBookingTrendGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/booking_trend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
        "type",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingBookingTrendGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingBookingTrendOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingBookingTrendOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/booking_trend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingBookingTrendOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingCaseRecommendGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var buildingCaseRecommendGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/case_recommend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingCaseRecommendGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingCaseRecommendOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingCaseRecommendOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/case_recommend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingCaseRecommendOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingCustomerBehavGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var buildingCustomerBehavGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/customer_behav").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingCustomerBehavGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingCustomerBehavOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingCustomerBehavOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/customer_behav").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingCustomerBehavOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingExportMembersGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["case_name", "Authorization"],
      ["body"]
    );

    var buildingExportMembersGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/export_members").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "case_name",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingExportMembersGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingExportMembersOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingExportMembersOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/export_members").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingExportMembersOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingLineEventGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var buildingLineEventGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/line_event").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingLineEventGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingLineEventOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingLineEventOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/line_event").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingLineEventOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingLineJoinGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var buildingLineJoinGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/line_join").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingLineJoinGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingLineJoinOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingLineJoinOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/line_join").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingLineJoinOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingMemberFigureGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var buildingMemberFigureGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/member_figure").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingMemberFigureGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingMemberFigureOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingMemberFigureOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/member_figure").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingMemberFigureOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingMemberPieGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var buildingMemberPieGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/member_pie").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingMemberPieGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingMemberPieOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingMemberPieOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/member_pie").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingMemberPieOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingMemberRegionGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization", "label_id"],
      ["body"]
    );

    var buildingMemberRegionGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/member_region").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "label_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingMemberRegionGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingMemberRegionOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingMemberRegionOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/member_region").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingMemberRegionOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingMemberSourceGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var buildingMemberSourceGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/member_source").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingMemberSourceGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingMemberSourceOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingMemberSourceOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/member_source").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingMemberSourceOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingMemberTotalGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var buildingMemberTotalGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/member_total").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingMemberTotalGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingMemberTotalOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingMemberTotalOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/member_total").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingMemberTotalOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingPersonBookingGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var buildingPersonBookingGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/person_booking").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingPersonBookingGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingPersonBookingOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingPersonBookingOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/person_booking").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingPersonBookingOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingRecommendAiGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var buildingRecommendAiGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/recommend_ai").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingRecommendAiGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingRecommendAiOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingRecommendAiOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/recommend_ai").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingRecommendAiOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingRecommendListGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["case_name", "ids", "Authorization"],
      ["body"]
    );

    var buildingRecommendListGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/recommend_list").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "case_name",
        "ids",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingRecommendListGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.buildingRecommendListOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var buildingRecommendListOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/building/recommend_list").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      buildingRecommendListOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberBasicOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicInfoGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberBasicInfoGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/info").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicInfoGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicInfoOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberBasicInfoOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/info").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicInfoOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicLabelGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberBasicLabelGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/label").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicLabelGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicLabelOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberBasicLabelOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/label").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicLabelOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicLabel1Get = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberBasicLabel1GetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/label1").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicLabel1GetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicLabel1Options = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberBasicLabel1OptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/label1").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicLabel1OptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicLabel2Get = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization", "member_id"],
      ["body"]
    );

    var memberBasicLabel2GetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/label2").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicLabel2GetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicLabel2Options = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberBasicLabel2OptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/label2").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicLabel2OptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicLabel3Get = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization", "member_id"],
      ["body"]
    );

    var memberBasicLabel3GetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/label3").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicLabel3GetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicLabel3Options = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberBasicLabel3OptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/label3").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicLabel3OptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicPreferGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberBasicPreferGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/prefer").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicPreferGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicPreferOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberBasicPreferOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/prefer").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicPreferOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicRecommendGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberBasicRecommendGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/recommend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicRecommendGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicRecommendOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberBasicRecommendOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/recommend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicRecommendOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicRfmGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberBasicRfmGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/rfm").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicRfmGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberBasicRfmOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberBasicRfmOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/basic/rfm").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberBasicRfmOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOnlineOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineLfgepieGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOnlineLfgepieGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online/lfgepie").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineLfgepieGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineLfgepieOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOnlineLfgepieOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online/lfgepie").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineLfgepieOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineListsGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOnlineListsGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online/lists").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineListsGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineListsOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOnlineListsOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online/lists").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineListsOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineResponseGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOnlineResponseGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online/response").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineResponseGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineResponseOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOnlineResponseOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online/response").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineResponseOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineTrackGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOnlineTrackGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online/track").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineTrackGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineTrackOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOnlineTrackOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online/track").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineTrackOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineViewGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOnlineViewGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online/view").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineViewGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOnlineViewOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOnlineViewOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/online/view").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOnlineViewOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOverallOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallHourGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOverallHourGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/hour").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallHourGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallHourOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOverallHourOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/hour").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallHourOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallLinechartGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOverallLinechartGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/linechart").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallLinechartGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallLinechartOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOverallLinechartOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/linechart").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallLinechartOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallPurchaseGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOverallPurchaseGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/purchase").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallPurchaseGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallPurchaseOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOverallPurchaseOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/purchase").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallPurchaseOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallShopieGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOverallShopieGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/shopie").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallShopieGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallShopieOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOverallShopieOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/shopie").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallShopieOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallThreeGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOverallThreeGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/three").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallThreeGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallThreeOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOverallThreeOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/three").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallThreeOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallWeekGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["member_id", "Authorization"],
      ["body"]
    );

    var memberOverallWeekGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/week").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "member_id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallWeekGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberOverallWeekOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberOverallWeekOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/overall/week").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberOverallWeekOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberSearchUpdatePost = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var memberSearchUpdatePostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/search_update").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberSearchUpdatePostRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.memberSearchUpdateOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var memberSearchUpdateOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/member/search_update").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      memberSearchUpdateOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorCityGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization", "ids"],
      ["body"]
    );

    var onlineBehaviorCityGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/city").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
        "ids",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorCityGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorCityOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorCityOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/city").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorCityOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorDeviceGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorDeviceGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/device").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorDeviceGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorDeviceOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorDeviceOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/device").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorDeviceOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorLogininfoGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorLogininfoGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/logininfo").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorLogininfoGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorLogininfoOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorLogininfoOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/logininfo").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorLogininfoOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorMemberGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorMemberGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/member").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorMemberGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorMemberOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorMemberOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/member").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorMemberOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorOtherGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorOtherGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/other").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorOtherGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorOtherOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorOtherOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/other").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorOtherOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorProductGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      [
        "start_date",
        "Authorization",
        "end_date",
        "sort",
        "ids",
        "item",
        "ascending",
      ],
      ["body"]
    );

    var onlineBehaviorProductGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/product").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
        "sort",
        "ids",
        "item",
        "ascending",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorProductGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorProductOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorProductOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/product").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorProductOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorProductSearchGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorProductSearchGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/product_search").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorProductSearchGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorProductSearchOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorProductSearchOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/product_search").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorProductSearchOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorRegisterGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorRegisterGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/register").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorRegisterGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorRegisterOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorRegisterOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/register").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorRegisterOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorRegisterLineGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorRegisterLineGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/register_line").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorRegisterLineGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorRegisterLineOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorRegisterLineOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/register_line").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorRegisterLineOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorSearchGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorSearchGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/search").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorSearchGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorSearchOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorSearchOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/search").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorSearchOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorSessionGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorSessionGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/session").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorSessionGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorSessionOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorSessionOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/session").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorSessionOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorSessionLineGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorSessionLineGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/session_line").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorSessionLineGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorSessionLineOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorSessionLineOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/session_line").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorSessionLineOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorTop10PageGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorTop10PageGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/top10_page").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorTop10PageGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorTop10PageOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorTop10PageOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/top10_page").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorTop10PageOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorUtmSourceGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization", "ids"],
      ["body"]
    );

    var onlineBehaviorUtmSourceGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/utm_source").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
        "ids",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorUtmSourceGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorUtmSourceOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorUtmSourceOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/utm_source").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorUtmSourceOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorWordGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineBehaviorWordGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/word").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorWordGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineBehaviorWordOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineBehaviorWordOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/behavior/word").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineBehaviorWordOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineCfOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfClick10Get = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineCfClick10GetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/click10").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfClick10GetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfClick10Options = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineCfClick10OptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/click10").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfClick10OptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfClickBubbleGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineCfClickBubbleGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/click_bubble").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfClickBubbleGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfClickBubbleOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineCfClickBubbleOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/click_bubble").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfClickBubbleOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfKeywordsGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineCfKeywordsGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/keywords").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfKeywordsGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfKeywordsOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineCfKeywordsOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/keywords").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfKeywordsOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfLabelCatesGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineCfLabelCatesGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/label_cates").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfLabelCatesGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfLabelCatesOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineCfLabelCatesOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/label_cates").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfLabelCatesOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfLabelValueGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var onlineCfLabelValueGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/label_value").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfLabelValueGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfLabelValueOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineCfLabelValueOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/label_value").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfLabelValueOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfTimeGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var onlineCfTimeGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfTimeGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.onlineCfTimeOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var onlineCfTimeOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/online/cf/time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      onlineCfTimeOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationChannelHotsaletimeGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationChannelHotsaletimeGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/channel/hotsaletime").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationChannelHotsaletimeGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationChannelHotsaletimeOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationChannelHotsaletimeOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/channel/hotsaletime").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationChannelHotsaletimeOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationChannelMemberateGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationChannelMemberateGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/channel/memberate").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationChannelMemberateGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationChannelMemberateOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationChannelMemberateOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/channel/memberate").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationChannelMemberateOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationChannelStoreperformanceGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationChannelStoreperformanceGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/channel/storeperformance").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationChannelStoreperformanceGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationChannelStoreperformanceOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationChannelStoreperformanceOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/channel/storeperformance").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationChannelStoreperformanceOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationIndicatorOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationIndicatorOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/indicator").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationIndicatorOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationIndicatorMemberInfoGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      [
        "last_start_date",
        "start_date",
        "last_end_date",
        "end_date",
        "Authorization",
      ],
      ["body"]
    );

    var operationIndicatorMemberInfoGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/indicator/member_info").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "last_start_date",
        "start_date",
        "last_end_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationIndicatorMemberInfoGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationIndicatorMemberInfoOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationIndicatorMemberInfoOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/indicator/member_info").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationIndicatorMemberInfoOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationIndicatorOperationIndexGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      [
        "last_start_date",
        "start_date",
        "last_end_date",
        "end_date",
        "Authorization",
      ],
      ["body"]
    );

    var operationIndicatorOperationIndexGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/indicator/operation_index").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "last_start_date",
        "start_date",
        "last_end_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationIndicatorOperationIndexGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationIndicatorOperationIndexOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationIndicatorOperationIndexOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/indicator/operation_index").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationIndicatorOperationIndexOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberBuyFreqGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "Authorization", "end_date"],
      ["body"]
    );

    var operationMemberBuyFreqGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/buy_freq").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberBuyFreqGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberBuyFreqOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationMemberBuyFreqOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/buy_freq").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberBuyFreqOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberMemberGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var operationMemberMemberGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/member").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberMemberGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberMemberOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationMemberMemberOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/member").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberMemberOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberRegionGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationMemberRegionGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/region").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberRegionGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberRegionOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationMemberRegionOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/region").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberRegionOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberSalesGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationMemberSalesGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/sales").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberSalesGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberSalesOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationMemberSalesOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/sales").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberSalesOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberStructureGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationMemberStructureGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/structure").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberStructureGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberStructureOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationMemberStructureOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/structure").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberStructureOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberTrendGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationMemberTrendGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/trend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberTrendGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMemberTrendOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationMemberTrendOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/member/trend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMemberTrendOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMetricsChannelrateGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationMetricsChannelrateGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/metrics/channelrate").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMetricsChannelrateGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMetricsChannelrateOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationMetricsChannelrateOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/metrics/channelrate").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMetricsChannelrateOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMetricsIndexGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationMetricsIndexGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/metrics/index").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMetricsIndexGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMetricsIndexOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationMetricsIndexOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/metrics/index").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMetricsIndexOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMetricsOnlinetrendGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationMetricsOnlinetrendGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/metrics/onlinetrend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMetricsOnlinetrendGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMetricsOnlinetrendOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationMetricsOnlinetrendOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/metrics/onlinetrend").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMetricsOnlinetrendOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMetricsSalesxordersGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationMetricsSalesxordersGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/metrics/salesxorders").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMetricsSalesxordersGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationMetricsSalesxordersOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationMetricsSalesxordersOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/metrics/salesxorders").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationMetricsSalesxordersOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAssociationCountsGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationProductAssociationCountsGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/association_counts").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAssociationCountsGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAssociationCountsOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductAssociationCountsOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/association_counts").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAssociationCountsOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductGrowthRateGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationProductGrowthRateGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/growth_rate").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductGrowthRateGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductGrowthRateOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductGrowthRateOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/growth_rate").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductGrowthRateOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductItemListGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var operationProductItemListGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/item_list").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductItemListGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductItemListOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductItemListOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/item_list").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductItemListOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductSalesRankGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["item", "start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationProductSalesRankGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/sales_rank").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "item",
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductSalesRankGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductSalesRankOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductSalesRankOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/sales_rank").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductSalesRankOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductScatterGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationProductScatterGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/scatter").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductScatterGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductScatterOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductScatterOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/scatter").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductScatterOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductTop5revorderGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["start_date", "end_date", "Authorization"],
      ["body"]
    );

    var operationProductTop5revorderGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/top5revorder").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "start_date",
        "end_date",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductTop5revorderGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductTop5revorderOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductTop5revorderOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product/top5revorder").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductTop5revorderOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductAdvanceOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceBuyAnalysisGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["types", "item", "start_date", "end_date", "id", "Authorization"],
      ["body"]
    );

    var operationProductAdvanceBuyAnalysisGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/buy_analysis").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "types",
        "item",
        "start_date",
        "end_date",
        "id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceBuyAnalysisGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceBuyAnalysisOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductAdvanceBuyAnalysisOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/buy_analysis").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceBuyAnalysisOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceBuyAnalysisExportPost = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var operationProductAdvanceBuyAnalysisExportPostRequest = {
      verb: "post".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/buy_analysis/export").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceBuyAnalysisExportPostRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceBuyAnalysisExportOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductAdvanceBuyAnalysisExportOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/buy_analysis/export").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceBuyAnalysisExportOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceDashboardGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["item", "start_date", "end_date", "id", "Authorization"],
      ["body"]
    );

    var operationProductAdvanceDashboardGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/dashboard").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "item",
        "start_date",
        "end_date",
        "id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceDashboardGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceDashboardOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductAdvanceDashboardOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/dashboard").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceDashboardOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceLabelSetGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["item", "start_date", "end_date", "id", "Authorization"],
      ["body"]
    );

    var operationProductAdvanceLabelSetGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/label_set").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "item",
        "start_date",
        "end_date",
        "id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceLabelSetGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceLabelSetOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductAdvanceLabelSetOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/label_set").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceLabelSetOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceProductListGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["item", "Authorization"],
      ["body"]
    );

    var operationProductAdvanceProductListGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/product_list").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "item",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceProductListGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceProductListOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductAdvanceProductListOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/product_list").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceProductListOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceSalesOrderGet = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["item", "start_date", "end_date", "id", "Authorization"],
      ["body"]
    );

    var operationProductAdvanceSalesOrderGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/sales_order").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, [
        "item",
        "start_date",
        "end_date",
        "id",
      ]),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceSalesOrderGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.operationProductAdvanceSalesOrderOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var operationProductAdvanceSalesOrderOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/operation/product_advance/sales_order").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      operationProductAdvanceSalesOrderOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.timeOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var timeOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/time").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      timeOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.timeBehaviorOnlineGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var timeBehaviorOnlineGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/time/behavior_online").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      timeBehaviorOnlineGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.timeBehaviorOnlineOptions = function (
    params,
    body,
    additionalParams
  ) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var timeBehaviorOnlineOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/time/behavior_online").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      timeBehaviorOnlineOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.timeMemberGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var timeMemberGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/time/member").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      timeMemberGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.timeMemberOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var timeMemberOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/time/member").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      timeMemberOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.timeOnlineGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var timeOnlineGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/time/online").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      timeOnlineGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.timeOnlineOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var timeOnlineOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/time/online").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      timeOnlineOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.timeSalesGet = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(
      params,
      ["Authorization"],
      ["body"]
    );

    var timeSalesGetRequest = {
      verb: "get".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/time/sales").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, [
        "Authorization",
      ]),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      timeSalesGetRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.timeSalesOptions = function (params, body, additionalParams) {
    if (additionalParams === undefined) {
      additionalParams = {};
    }

    apiGateway.core.utils.assertParametersDefined(params, [], ["body"]);

    var timeSalesOptionsRequest = {
      verb: "options".toUpperCase(),
      path:
        pathComponent +
        uritemplate("/time/sales").expand(
          apiGateway.core.utils.parseParametersToObject(params, [])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body: body,
    };

    return apiGatewayClient.makeRequest(
      timeSalesOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  return apigClient;
};
export var allapi = apigClientFactory.newClient();
