<template>
  <div class="back-drop" @click.self="closeModal">
    <Transition>
      <div class="variable-modal-container" v-if="openContainer">
        <header class="header-nav-bar">
          <img
            class="close-cross"
            src="../../assets/whiteXWithRadius.svg"
            alt="close"
            @click.self="closeModal"
          />
        </header>
        <div class="modal-body">
          <div class="insert-option row">
            <div class="title">插入個人化變數</div>
            <div class="drop-down-wrap">
              <DropDown
                :valueArr="variableOptions"
                :selectValue="selectedValue"
                :isTextNeedOverflow="false"
                @selectValueByDrop="(value) => (selectedValue = value)"
                class="drop-down"
              />
            </div>
            <p class="intro">{{ optionsIntro }}</p>
          </div>
          <div class="insert-variable row">
            <div v-if="selectedValue === '取消訂閱'">
              <VariableInputRow
                :labelName="'取消訂閱'"
                :defaultVal="defaultInput[3]"
                :disable="true"
              />
              <VariableInputRow
                :labelName="'連結網址'"
                :defaultVal="unSubscribeUrl"
                :btnName="'儲存'"
              />
            </div>
            <div v-if="selectedValue === '顧客姓名'">
              <VariableInputRow
                :labelName="'顧客姓名'"
                :defaultVal="defaultInput[0]"
                :disable="true"
              />
              <!-- <VariableInputRow
                :labelName="'顧客姓'"
                :defaultVal="defaultInput[1]"
                :disable="true"
              />
              <VariableInputRow
                :labelName="'顧客名'"
                :defaultVal="defaultInput[2]"
                :disable="true"
              /> -->
            </div>
            <div v-if="selectedValue === 'coupon投放'">
              <VariableInputRow
                :labelName="'優惠券'"
                :defaultVal="defaultInput[4]"
                :disable="true"
              />
            </div>
          </div>
          <div class="notification" v-if="selectedValue === '取消訂閱'">
            注意 : 連結網址可設定跳轉後的外站連結，您亦可使用系統預設的頁面。
            另外，在設計註冊歡迎信、訂單確認信時，系統預設皆會寄出，因此不用插入此變數。
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script setup>
import {
  ref,
  defineEmits,
  defineProps,
  computed,
  onMounted,
  Teleport,
} from "vue";
import DropDown from "../../components/Dropdown.vue";
import VariableInputRow from "./VariableInputRow.vue";
const props = defineProps({
  unSubscribeUrl: {
    type: String,
    default: "",
  },
  showModal: {
    type: Boolean,
    default: false,
  },
  //傳送渠道的種類
  channelType: {
    type: String,
    default: "Email", //Email、SMS
  },
});
const emits = defineEmits(["closeModal"]);
const variableOptions = ref([]);
const intro = {
  取消訂閱:
    "請複製下方變數並插入右方內容區塊URL的位置，當使用者點擊後即會退訂電子報，並自動跳轉下方網址。",
  顧客姓名:
    "複製下方變數並插入文字中，使信件客製化；可讓信件中加入訂閱者的姓名，增加親切感。(英文皆以全名呈現)",
  coupon投放: "複製下方變數並插入文字中，使信件客製化；",
};

const selectedValue = ref("取消訂閱");
const optionsIntro = computed(() => intro?.[selectedValue.value]);
const defaultInput = [
  "{{name}}",
  "{{firstname}}",
  "{{lastname}}",
  "{{unsubscribe}}",
  "{{coupon}}",
];
const openContainer = ref(false);

onMounted(() => {
  openContainer.value = true; // 打開彈窗主體 產生transition
  setVariableOption();
});
function closeModal() {
  openContainer.value = false;
  setTimeout(() => {
    emits("closeModal");
  }, 800);
}

function setVariableOption() {
  const hasCoupon = sessionStorage.getItem("coupon_id");
  switch (props.channelType) {
    case "SMS":
      variableOptions.value = ["顧客姓名"];
      break;

    case "Email":
      if (hasCoupon) {
        //從「coupon投放」頁面過來，有上傳coupon
        variableOptions.value = ["顧客姓名", "取消訂閱", "coupon投放"];
      } else {
        variableOptions.value = ["顧客姓名", "取消訂閱"];
      }
      break;
    default:
      console.log("需要定義個人化變數下拉選單的種類", channelType.value);
      variableOptions.value = ["顧客姓名", "取消訂閱"];
      break;
  }
  selectedValue.value = variableOptions.value[0];
}
</script>

<style scoped>
.back-drop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.variable-modal-container {
  position: relative;
  width: 35%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-nav-bar {
  width: 100%;
  height: 30px;
  background: linear-gradient(270deg, #71afb6, #b4dbdf);
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
  justify-content: flex-end;
}
.close-cross {
  width: 12px;
  height: 12px;
  margin-right: 15px;
}
.modal-body {
  background-color: white;
  border-radius: 0 0 10px 10px;
}
.row {
  display: flex;
  align-items: center;
}
.title {
  width: auto;
  color: #71afb6;
}
.drop-down-wrap {
  width: 220px;
}
.drop-down :deep(.flex-fill) {
  pointer-events: none;
}
.intro {
  margin-top: 25px;
}
.notification {
  font-size: 12px;
  color: #c4c4c4;
}

/* transition 淡入淡出動畫*/
.v-enter-active,
.v-leave-active {
  transition: opacity 0.8s ease, top 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  top: 30%;
  opacity: 0;
}

.v-enter-to,
.v-leave-from {
  top: 50%;
  opacity: 1;
}
</style>
