import axiosInstance from "./axiosIntance";
const BASE_URL = 'https://4k4o2eikk8.execute-api.ap-northeast-1.amazonaws.com/prod/rema'

export async function getCustomParamInfo() {
  try {
    const res = await axiosInstance.get(BASE_URL + '/custom_param')
    console.log("取得客製化參數ok",res)
    return res?.data

  } catch(err) {
    console.log("取得客製化參數有誤",err)
    return []
  }
}

export async function updateCustomParamInfo (url)  {
  try {
    const res = await axiosInstance.patch(BASE_URL + `/custom_param`,
      {
        value: url
      } 
      ,
      {
      params: {
       param_name: 'unsubscribe_redirect_url'
      }
    })
    console.log("更新客製化參數ok",res)
    return res?.data

  } catch(err) {
    console.log("更新客製化參數有誤",err)
    return []
  }
}
