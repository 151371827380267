import { useDateRangeStore } from "../store/useDateRangeStore"; // pinia

export default function DateSelectorInitialize(
  apiStartDate = "2022-10-31",
  apiEndDate = "2030-10-31"
) {
  const dateRangeStore = useDateRangeStore();
  // 如果session資料都為空的話
  if (
    dateRangeStore.selectedStartDate === "" &&
    dateRangeStore.selectedEndDate === ""
  ) {
    dateRangeStore.startDate = apiStartDate;
    dateRangeStore.endDate = apiEndDate;
    dateRangeStore.setMonth();
    dateRangeStore.setRangeTitle("最近30天");
  }

  // 回傳最後的時間選擇種類
  return dateRangeStore.rangeTitle;
}
