<template>
<div  class="card-wrapper">
  <div class="item-card-header">
    <input type="checkbox"  :id="cardInfoData.key" class="checkbox" v-model="imageChecked">
    <div class="img-editor-wrap">
      <img :src="EditDotsIcon" alt="img-editor" class="img-editor" @click="editImageToolTip">
      <div class="img-editor-tool" v-show="isShowEditImageList" ref="editToolTipDom">
        <div class="delete-btn" @click="deleteImage(props.cardInfoData?.key)">刪除</div>
      </div>
    </div>
    
  </div>
  <div class="item-card-body" :for="cardInfoData.key">
    <div class="card-img-wrap" :class="[isImageLoading?'load':'']">
      <img :src="imagePath" alt="card-img" class="card-img" :onload="imageLoad">
    </div>
    <div class="card-info">
      <div class="created-date">{{ cardCreatedDate}}</div>
      <input class="name"  :value="cardInfoData.name" @change="($event)=>handleCardInput($event)" @v-if="!isWaitingApi"/>
      <div class="capacity"
        :class="[isImageSizeTooLarge?'alert-text':'']">
        {{cardInfoData.size}} KB {{ isImageSizeTooLarge? '檔案過大':'' }}
      </div>
      <button 
        class="insert-btn"
        :class="[imageChecked || isImageSizeTooLarge?'disabled':'']"
        @click="insertImage(cardInfoData.key)"
      >
        插入
      </button>
    </div> 
  </div>
</div>
</template>
<script setup>
import {ref,defineProps,computed,watch,defineEmits} from 'vue'
import EditDotsIcon from '../../assets/editPointIcon.svg'
import { onClickOutside } from '@vueuse/core';
import { allapi2 } from "../../../public/static2/api/apigClient";

const props = defineProps({
  cardInfoData: {
    type: Object,
    default: ()=> {}
  },
  checked: {
    type: Boolean || undefined
  }
})
const emits = defineEmits(['checKBoxChecked','updateImageInfo','deleteImage','insertImage'])
const imageChecked = ref(false)
const editToolTipDom = ref(null)
const isWaitingApi = ref(false) // 等待修改檔名api回應時間
onClickOutside(editToolTipDom,()=> isShowEditImageList.value = false)
const isShowEditImageList = ref(false)

const cardCreatedDate = computed(()=>{
  const transformGMTtime = props.cardInfoData?.time + '+0800'
  const year = new Date(transformGMTtime).getFullYear()
  const month = new Date(transformGMTtime).getUTCMonth() + 1 // 1月 = 0
  const date = new Date(transformGMTtime).getDate()
  return year + '/' + month + '/' + date
})
const imagePath = computed(()=>{
  return 'https://d19z6wy4716zvk.cloudfront.net/' + props.cardInfoData?.key
})

// 壓縮完圖片是否大於2MB
const isImageSizeTooLarge = computed(()=>{
  return props.cardInfoData?.size > 2000
})
const isImageLoading = ref(true)

watch(imageChecked,(newVal)=> {
  // 上傳checkbox資訊和key值 針對原本資料做修改
  emits('checKBoxChecked',newVal,props.cardInfoData?.key)
})

// 監測checked換分頁時 仍然保持checkbox選項
watch(()=>props.checked,(newVal)=>{
  imageChecked.value = newVal
},{immediate: true})

watch(()=>props.cardInfoData.time,(newVal)=>{
  console.log('卡片時間有變',newVal)
})
// 使用者點選別處後 or 按enter 呼叫後端api更新圖片資訊--------
// 點選輸入框修改圖片名稱後----------
async function handleCardInput(event) {
  isWaitingApi.value = true
  try {
    await apiModifyAllImagesData(props.cardInfoData.key, event.target.value,props.cardInfoData.size)
    // emit 重新呼叫所有圖片api
    emits('updateImageInfo')
    event.target.blur()
    console.log("handleInput 圖片名稱修改成功")
  } catch(err) {
    console.log("handleInput 圖片名稱修改有錯")
  }
  isWaitingApi.value = false

}

// 點選圖片編輯icon-------- ()
function editImageToolTip() {
  isShowEditImageList.value = !isShowEditImageList.value
}
// 點擊刪除按鈕時
function deleteImage(imageKey) {
  emits('deleteImage',imageKey)
}

// 插入圖片至樣板
function insertImage(imageKey) {
  emits('insertImage',imageKey)
}

// 圖片CDN仔入完成後
function imageLoad() {
  isImageLoading.value = false
}

// 修改圖片資料檔名(patch)--------------
async function apiModifyAllImagesData(key,fileName,size) {
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
  };
  const body = {
    key: key,
    name: fileName,
    size: size,
  }
  await allapi2.imagesInfoPatch(params,body)
    .then((res) => {
      console.log('更新圖片資料ok',res)
  
    })
    .catch((err) => {
      console.log("更新圖片資料有誤",err);
    });
}
</script>
<style scoped>
.card-wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
}
.checkbox {
  cursor: pointer;
}
.img-editor {
  display: flex;
  width: 20px;
  height: 4px;
  cursor: pointer;
}
.item-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.item-card-body {
  display: flex;
  /* gap: 15.38px; */
  width: 100%;
  margin-top: 10px;
}
.card-img-wrap,.card-info {
  width: 48%;
}
.card-img-wrap {
  margin-right: 15.38px;
}

.card-img {
  border-radius: 10px;
  width: 100%;
  height: 120px;
}
.created-date,.capacity {
  font-size: 12px;
  font-weight: 400;
  color: #8A8A8A;
}
.alert-text {
  color: red;
}
.name {
  font-size: 18px;
  color: #575757;
  margin: 5px 0;
  /*over-flow eclipsis*/
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  height: 24px;
}
.insert-btn {
  all: unset;
  border-radius: 10px ;
  color: #FFFFFF;
  font-size:18px;
  padding: 7px 0;
  height: 26px;
  cursor: pointer;
  text-align: center;
  line-height: 20px;
  background: linear-gradient(270deg,#71afb6,#b4dbdf);
  margin-top: 13px;
  width: 100%;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.img-editor-wrap {
  position: relative;
}
.img-editor-tool{
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, -10%);
  width: 150px;
  background-color: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
}
.rename-btn,.delete-btn {
  padding: 11px 15px;
}
.rename-btn:hover {
  background-color: #EAF3F4;
  border-radius: 15px 15px 0 0;
}
.delete-btn:hover {
  background-color: #EAF3F4;
  border-radius: 15px;
}
.rename-btn::before,.delete-btn::before {
  display: inline-block;
  width: 14px;
  height: 18px;
  margin-right: 14px;
}

.rename-btn::before {
  content: url('../../assets/rename-black.svg');
}
.delete-btn::before {
  content: url('../../assets/delete-black.svg');
}

/* loading skeleton*/
@keyframes loading {
   to{
     background-position-x: -20%;
   }
}
.load { 
  background: linear-gradient(
    100deg, 
    rgba(256, 256, 256, 0) 0%,
    rgba(256, 256, 256, 0.5) 100%,
    rgba(256, 256, 256, 0) 30%)
    #ededed;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 0.5s loading ease-in-out infinite;
    border-radius: 10px;
} 

</style>