import moment from "moment";

const DateSelector = {
  /* 最近幾天相關 */
  getPassDaysStart: function getPassDaysStart(hasDataEnd, hours) {
    const startDateSec = moment(new Date(hasDataEnd)) - 1000 * 60 * 60 * hours;
    const startDate = moment(new Date(startDateSec))
      .add(1, "days")
      .format("YYYY-MM-DD");
    return startDate;
  },

  getRecentYearStart: function getRecentYearStart(hasDataEnd) {
    const RecentYear = moment(new Date(hasDataEnd))
      .subtract(1, "years")
      .add(1, "days")
      .format("YYYY-MM-DD");
    return RecentYear;
  },

  getLastMonthStart: function getLastMonthStart(hasDataEnd) {
    const startDate = moment(new Date(hasDataEnd))
      .subtract(1, "months")
      .startOf("months")
      .format("YYYY-MM-DD");
    const endDate = moment(new Date(hasDataEnd))
      .subtract(1, "months")
      .endOf("months")
      .format("YYYY-MM-DD");
    return { startDate, endDate };
  },

  getThisMonthStart: function getThisMonthStart(hasDataEnd) {
    const startDate = moment(new Date(hasDataEnd))
      .startOf("months")
      .format("YYYY-MM-DD");
    const endDate = moment(new Date(hasDataEnd))
      .endOf("months")
      .format("YYYY-MM-DD");
    return { startDate, endDate };
  },

  getHighlightCustomDate: function getHighlightCustomDate(startDate, endDate) {
    const endMonth = Number(endDate.slice(5, 7));
    const endYear = Number(endDate.slice(0, 4));
    const start = moment(new Date(startDate)).format("YYYY-MM-DD");
    const end = moment(new Date(endDate)).format("YYYY-MM-DD");
    return { endMonth, endYear, start, end };
  },
};

export default DateSelector;
