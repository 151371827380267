<template>
  <div class="leftNav" v-if="!login && isMainSideBar">
    <Navbar :navData="navOverview" />
    <hr />
    <Navbar :navData="navReMa" />
    <li style="" class="logo-wrapper">
      <div class="">
        <img src="../assets/leftnav-created.png" alt="logo" />
      </div>
    </li>
    <!-- <ul class="list-unstyled components">
      <li
        style="margin-bottom: 30px"
        v-if="this.lv.OperationalPerformance != 0"
      >
        <div
          :class="behavior ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.behavior = !this.behavior"
        >
          <img src="../assets/trendGray.png" v-show="!behavior" />
          <img src="../assets/trend.png" v-show="behavior" />
          <span class="ms-6rem-custom" data-gtm="left-nav"> 營運表現 </span>
          <img
            :src="rightArrow"
            alt="right-arrow"
            class="grey-arrow-icon"
            v-show="!behavior"
          />
          <img
            :src="blueDownArrow"
            alt="down-arrow"
            class="blue-arrow-icon"
            v-show="behavior"
          />
        </div>
        <div class="ms-3" :class="behavior ? 'show' : 'noshow'">
          <router-link
            to="/OperationalOverview"
            active-class="active"
            v-if="this.lv.OperationalOverview != 0"
            data-gtm="left-nav"
          >
            營運總覽
          </router-link>
          <router-link
            to="/IndicatorCompare"
            active-class="active"
            v-if="this.lv.IndicatorCompare != 0"
            data-gtm="left-nav"
          >
            指標同比
          </router-link>
          <router-link
            to="/MembershipSales"
            active-class="active"
            v-if="this.lv.MembershipSales != 0"
            data-gtm="left-nav"
          >
            會員銷售
          </router-link>
          <div v-if="this.lv.CommoditySales != 0">
            <div
              class="retailGoods"
              :class="retailGoods ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.retailGoods = !this.retailGoods"
              data-gtm="left-nav"
            >
              商品銷售
              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!retailGoods"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="retailGoods"
              />
            </div>
            <div class="ms-2" :style="retailGoods ? '' : 'display:none'">
              <router-link
                to="/CommoditySales"
                active-class="active"
                style="padding-left: 55px; margin: 0 10px 14px 0"
                v-if="this.lv.CommoditySales != 0"
                data-gtm="left-nav"
              >
                銷售表現
              </router-link>
              <router-link
                to="/CommodityAdvanceAnalysis"
                active-class="active"
                style="padding-left: 55px; margin: 0 10px 14px 0"
                v-if="this.lv.CommodityAdvanceAnalysis != 0"
                data-gtm="left-nav"
              >
                商品進階分析
              </router-link>
            </div>
          </div>
          <router-link
            to="/StoreChannelSales"
            active-class="active"
            v-if="this.lv.StoreChannelSales != 0"
            data-gtm="left-nav"
          >
            門市通路銷售
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.lv.WebsiteBehavior != 0">
        <div
          :class="Website ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.Website = !this.Website"
        >
          <img src="../assets/website-anal-grey.svg" v-show="!Website" />
          <img src="../assets/website-anal-blue.svg" v-show="Website" />
          <span class="ms-6rem-custom" data-gtm="left-nav"> 網站行為 </span>
          <img
            :src="rightArrow"
            alt="right-arrow"
            class="grey-arrow-icon"
            v-show="!Website"
          />
          <img
            :src="blueDownArrow"
            alt="down-arrow"
            class="blue-arrow-icon"
            v-show="Website"
          />
        </div>
        <div class="ms-3" :class="Website ? 'show' : 'noshow'">
          <router-link
            to="/WebsiteAnalysis"
            active-class="active"
            v-if="this.lv.WebsiteAnalysis != 0"
            data-gtm="left-nav"
          >
            網站行為分析
          </router-link>
          <router-link
            to="/WebsiteAdvance"
            active-class="active"
            v-if="this.lv.WebsiteAdvance != 0"
            data-gtm="left-nav"
          >
            網站進階分析
          </router-link>
          <router-link
            to="/clickForceTag"
            active-class="active"
            v-if="this.lv.clickForceTag != 0"
            data-gtm="left-nav"
          >
            域動站外標籤
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.lv.CustomerShopbehavior">
        <div
          :class="labelGroup ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.labelGroup = !this.labelGroup"
        >
          <img src="../assets/analyzeGray.png" v-show="!labelGroup" />
          <img src="../assets/analyze.png" v-show="labelGroup" />
          <span class="ms-6rem-custom" data-gtm="left-nav"> 顧客購物行為 </span>
          <img
            :src="rightArrow"
            alt="right-arrow"
            class="grey-arrow-icon"
            v-show="!labelGroup"
          />
          <img
            :src="blueDownArrow"
            alt="down-arrow"
            class="blue-arrow-icon"
            v-show="labelGroup"
          />
        </div>
        <div class="ms-3" :class="labelGroup ? 'show' : 'noshow'">
          <router-link
            to="/ValueGrouping"
            active-class="active"
            v-if="this.lv.ValueGrouping != 0"
            data-gtm="left-nav"
          >
            整體顧客行為
          </router-link>
          <router-link
            to="/CustomLabel"
            active-class="active"
            v-if="this.lv.CustomLabel != 0"
            data-gtm="left-nav"
          >
            線上購物行為
          </router-link>
          <router-link
            to="/OfflineBehavior"
            active-class="active"
            v-if="this.lv.OfflineBehavior != 0"
            data-gtm="left-nav"
          >
            線下購物行為
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.lv.New360Page != 0">
        <div
          :class="New360 ? 'highlight' : 'nohighlight'"
          active-class="active"
        >
          <router-link
            class="my-0"
            to="/New360Page"
            active-class="active"
            style="padding-left: 5px"
          >
            <img src="../assets/New360Gray.svg" v-show="!New360" />
            <img src="../assets/New360White.svg" v-show="New360" />
            <span style="padding-left: 0.65rem" data-gtm="left-nav">
              360度顧客輪廓
            </span>
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.lv.CustomerAnalysis != 0">
        <div
          :class="CustomerAnalyze ? 'highlight' : 'nohighlight'"
          active-class="active"
        >
          <router-link
            class="my-0"
            to="/CustomerAnalysis"
            active-class="active"
            style="padding-left: 5px"
          >
            <img
              src="../assets/Customer-analysis-gray.svg"
              v-show="!CustomerAnalyze"
            />
            <img
              src="../assets/Customer-analysis.svg"
              v-show="CustomerAnalyze"
            />
            <span style="padding-left: 0.65rem" data-gtm="left-nav"
              >&nbsp;顧客分析模組&nbsp;</span
            >
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.lv.CustomerAnalysis != 0">
        <div
          :class="Building ? 'highlight' : 'nohighlight'"
          active-class="active"
        >
          <router-link
            class="my-0"
            to="/BuildingCase"
            active-class="active"
            style="padding-left: 5px"
          >
            <img src="../assets/Building-case-gray.svg" v-show="!Building" />
            <img src="../assets/Building-case.svg" v-show="Building" />
            <span style="padding-left: 0.65rem" data-gtm="left-nav"
              >&nbsp;建案模組&nbsp;</span
            >
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.lv.CustomerLabel != 0">
        <div
          :class="tagGroup ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.tagGroup = !this.tagGroup"
        >
          <img src="../assets/customTagGroupGray.svg" v-show="!tagGroup" />
          <img src="../assets/customTagGroupBlue.svg" v-show="tagGroup" />
          <span class="ms-6rem-custom" data-gtm="left-nav"
            >&nbsp;顧客貼標分群&nbsp;</span
          >
          <img :src="rightArrow" alt="right-arrow" v-show="!tagGroup" />
          <img
            :src="blueDownArrow"
            alt="down-arrow"
            class="blue-arrow-icon"
            v-show="tagGroup"
          />
        </div>
        <div class="ms-3" :class="tagGroup ? 'show' : 'noshow'">
          <router-link
            to="/RFMpage"
            active-class="active"
            data-gtm="left-nav"
            v-if="this.lv.RFMpage != 0"
          >
            價值分群
          </router-link>
          <router-link
            to="/RFMCustomPage"
            active-class="active"
            data-gtm="left-nav"
            v-if="this.lv.RFMCustomPage != 0"
          >
            個性化分群
          </router-link>
          <router-link
            to="/LabelManage"
            active-class="active"
            data-gtm="left-nav"
            v-if="this.lv.LabelManage != 0"
          >
            分群管理與報表
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.lv.AIDataAnalyze != 0">
        <div
          :class="aiGroup ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="aiGroup = !aiGroup"
          data-gtm="left-nav"
        >
          <img :src="AIDataAnalyzeGray" v-show="!aiGroup" />
          <img :src="AIDataAnalyze" v-show="aiGroup" />
          <span class="ms-6rem-custom" data-gtm="left-nav"
            >&nbsp;AI數據分析&nbsp;</span
          >
          <img
            :src="rightArrow"
            alt="right-arrow"
            class="grey-arrow-icon"
            v-show="!aiGroup"
          />
          <img
            :src="blueDownArrow"
            alt="down-arrow"
            class="blue-arrow-icon"
            v-show="aiGroup"
          />
        </div>
        <div class="ms-3" :class="aiGroup ? 'show' : 'noshow'">
          <router-link
            to="/RankRecommend"
            active-class="active"
            data-gtm="left-nav"
            v-if="lv.AIListRecommend != 0"
          >
            AI名單推薦
          </router-link>
          <router-link
            to="/AIRobotIan"
            active-class="active"
            data-gtm="left-nav"
            v-if="lv.SaleAIRobot != 0"
          >
            行銷AI機器人
          </router-link>
        </div>
      </li>
      <hr />
      <li class="mt-4" style="margin-bottom: 30px" v-if="this.lv.Rema != 0">
        <div
          :class="auto ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.auto = !this.auto"
        >
          <img src="../assets/rema.svg" />
          <span class="ms-025rem-custom" data-gtm="left-nav"
            >&nbsp;ReMA行銷自動化&nbsp;</span
          >
          <img
            :src="rightArrow"
            alt="right-arrow"
            class="grey-arrow-icon"
            v-show="!auto"
          />
          <img
            :src="blueDownArrow"
            alt="down-arrow"
            class="blue-arrow-icon"
            v-show="auto"
          />
        </div>

        <div :class="auto ? 'show' : 'noshow'">
          <ul class="ps-3 mb-3 mt-3">
            <li
              class="ms-6rem-custom ps-4"
              :class="remaEmail ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaEmail = !this.remaEmail"
              data-gtm="left-nav"
              v-if="this.lv.RemaEmail != 0"
            >
              Email
              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!remaEmail"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="remaEmail"
              />
            </li>
            <li :style="remaEmail ? '' : 'display:none'">
              <router-link
                to="/ProductTextTemplate"
                :class="highlightEmail ? 'active' : ''"
                active-class="active"
                data-gtm="left-nav"
                v-if="this.lv.ProductTextTemplate != 0"
              >
                Email模板
              </router-link>
              <router-link
                to="/MarketingSchedule"
                active-class="active"
                data-gtm="left-nav"
                v-if="this.lv.MarketingSchedule != 0"
              >
                排程
              </router-link>
            </li>
          </ul>
          <ul class="ps-3 mb-3 mt-3">
            <li
              class="ms-6rem-custom ps-4"
              :class="remaSms ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaSms = !this.remaSms"
              data-gtm="left-nav"
              v-if="this.lv.RemaSMS != 0"
            >
              SMS

              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!remaSms"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="remaSms"
              />
            </li>
            <li class="ms-1" :style="remaSms ? '' : 'display:none'">
              <router-link
                to="/ProductSmsTemplate"
                :class="highlightSms ? 'active' : ''"
                active-class="active"
                data-gtm="left-nav"
                v-if="this.lv.ProductSmsTemplate != 0"
              >
                簡訊模板
              </router-link>
              <router-link
                to="/SmsSchedule"
                active-class="active"
                data-gtm="left-nav"
                v-if="this.lv.SmsSchedule != 0"
              >
                排程
              </router-link>
            </li>
          </ul>
          <ul class="ps-3 mb-3 mt-3" v-if="this.lv.RemaFB != 0">
            <li
              class="ms-6rem-custom ps-4"
              :class="remaFacebook ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaFacebook = !this.remaFacebook"
              data-gtm="left-nav"
            >
              Meta Ads

              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!remaFacebook"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="remaFacebook"
              />
            </li>
            <li class="ms-2" :style="remaFacebook ? '' : 'display:none'">
              <router-link
                to="/FacebookAdsPackage"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                data-gtm="left-nav"
                v-if="this.lv.FacebookAdsPackage != 0"
              >
                受眾包預覽
              </router-link>
              <router-link
                to="/FbShortUrl"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                data-gtm="left-nav"
                v-if="this.lv.FbShortUrl != 0"
              >
                點擊追蹤功能
              </router-link>
            </li>
          </ul>
          <ul class="ps-3 mb-3 mt-3" v-if="this.lv.RemaLine != 0">
            <li
              class="ms-6rem-custom ps-4"
              :class="remaLine ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaLine = !this.remaLine"
              data-gtm="left-nav"
            >
              Line

              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!remaLine"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="remaLine"
              />
            </li>
            <li class="ms-2" :style="remaLine ? '' : 'display:none'">
              <router-link
                to="/LineAdsPackage"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                data-gtm="left-nav"
                v-if="this.lv.LineAdsPackage != 0"
              >
                受眾包預覽
              </router-link>
              <router-link
                to="/LineShortUrl"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                data-gtm="left-nav"
                v-if="this.lv.LineShortUrl != 0"
              >
                點擊追蹤功能
              </router-link>
            </li>
          </ul>
          <ul class="ps-3 mb-3 mt-3" v-if="this.lv.RemaGoogle != 0">
            <li
              class="ms-6rem-custom ps-4"
              :class="remaGoogle ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaGoogle = !this.remaGoogle"
              data-gtm="left-nav"
            >
              Google Ads

              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!remaGoogle"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="remaGoogle"
              />
            </li>
            <li class="ms-2" :style="remaGoogle ? '' : 'display:none'">
              <router-link
                to="/GoogleAdsPackage"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                data-gtm="left-nav"
                v-if="this.lv.GoogleAdsPackage != 0"
              >
                受眾包預覽
              </router-link>
              <router-link
                to="/GoogleShortUrl"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                data-gtm="left-nav"
                v-if="this.lv.GoogleShortUrl != 0"
              >
                點擊追蹤功能
              </router-link>
            </li>
          </ul>
          <ul class="ps-3 mb-3 mt-3" v-if="this.lv.AutomationScript != 0">
            <div
              class="ms-6rem-custom ps-4"
              :class="remaScript ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaScript = !this.remaScript"
              data-gtm="left-nav"
            >
              自動化劇本

              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!remaScript"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="remaScript"
              />
            </div>
            <div class="ms-1" :style="remaScript ? '' : 'display:none'">
              <router-link
                to="/ScriptTable"
                active-class="active"
                data-gtm="left-nav"
                v-if="this.lv.ScriptTable != 0"
              >
                劇本一覽表
              </router-link>
            </div>
          </ul>
        </div>
      </li>

      <li style="margin-bottom: 30px" v-if="this.lv.MarketingPerformance != 0">
        <div
          :class="advEffect ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.advEffect = !this.advEffect"
        >
          <img src="../assets/effectGray.svg" v-show="!advEffect" />
          <img src="../assets/effectBlue.svg" v-show="advEffect" />
          <span class="ms-6rem-custom" data-gtm="left-nav"> 行銷成效 </span>
          <img
            :src="rightArrow"
            alt="right-arrow"
            class="grey-arrow-icon"
            v-show="!advEffect"
          />
          <img
            :src="blueDownArrow"
            alt="down-arrow"
            class="blue-arrow-icon"
            v-show="advEffect"
          />
        </div>
        <div class="ms-3" :class="advEffect ? 'show' : 'noshow'">
          <router-link
            to="/FullCalendar"
            active-class="active"
            data-gtm="left-nav"
            v-if="this.lv.FullCalendar != 0"
          >
            活動行事曆
          </router-link>
          <router-link
            to="/AllAdvEffect"
            active-class="active"
            data-gtm="left-nav"
            v-if="this.lv.AllAdvEffect != 0"
          >
            整體行銷成效
          </router-link>
          <div class="ps-3 mb-3" v-if="this.lv.EmailPerformance != 0">
            <div
              class="ms-6rem-custom ps-4"
              :class="email ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.email = !this.email"
              data-gtm="left-nav"
            >
              Email
              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!email"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="email"
              />
            </div>
            <div :style="email ? '' : 'display:none'">
              <router-link
                to="/EmailMarketingTable"
                :class="highlightPerformanceEmail ? 'active' : ''"
                active-class="active"
                style="
                  padding-left: 40px;
                  margin: 0 10px 0 0;
                  position: relative;
                "
                data-gtm="left-nav"
                v-if="this.lv.EmailMarketingTable != 0"
              >
                投放記錄一覽表
              </router-link>
              <router-link
                to="/LatestResult"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                data-gtm="left-nav"
                v-if="this.lv.LatestResult != 0"
              >
                整體投放趨勢
              </router-link>
            </div>
          </div>
          <ul class="ps-3 mb-3" v-if="this.lv.SMSperformance != 0">
            <li
              class="ms-6rem-custom ps-4"
              :class="sms ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.sms = !this.sms"
            >
              SMS
              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!sms"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="sms"
              />
            </li>
            <li :style="sms ? '' : 'display:none'">
              <router-link
                :class="highlightPerformanceSMS ? 'active' : ''"
                to="/SmsMarketingTable"
                class="overflow-text"
                active-class="active "
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.lv.SmsMarketingTable != 0"
              >
                投放記錄一覽表
              </router-link>

              <router-link
                to="/SmsLatestResult"
                class="overflow-text"
                active-class="active "
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.lv.SmsLatestResult != 0"
              >
                整體投放趨勢
              </router-link>
            </li>
          </ul>
          <ul class="ps-3 mb-3" v-if="this.lv.CrossAdsEffect != 0">
            <li
              class="ms-6rem-custom ps-4"
              :class="crossads ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.crossads = !this.crossads"
            >
              跨媒體廣告成效
              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!crossads"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="crossads"
              />
            </li>
            <li :style="crossads ? '' : 'display:none'">
              <router-link
                :class="highlightPerformanceSMS ? 'active' : ''"
                to="/CrossAdsChart"
                class="overflow-text"
                active-class="active "
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.lv.CrossAdsChart != 0"
              >
                跨媒成效分析
              </router-link>

              <router-link
                to="/CrossAdsTable"
                class="overflow-text"
                active-class="active "
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.lv.CrossAdsTable != 0"
              >
                活動交叉比較
              </router-link>
            </li>
          </ul>
          <ul class="ps-3 mb-3" v-if="this.lv.FBperformance != 0">
            <li
              class="ms-6rem-custom ps-4"
              :class="fb ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.fb = !this.fb"
            >
              Meta ads

              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!fb"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="fb"
              />
            </li>
            <li :style="fb ? '' : 'display:none'">
              <router-link
                to="/FbadsTable"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.lv.FbadsTable != 0"
              >
                投放紀錄一覽表
              </router-link>
              <router-link
                to="/FbTrendAnal"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.lv.FbTrendAnal != 0"
              >
                趨勢比較分析
              </router-link>
              <router-link
                to="/FbThemeAnal"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.lv.FbThemeAnal != 0"
              >
                活動主題比較
              </router-link>
            </li>
          </ul>

          <ul class="ps-3 mb-3" v-if="this.lv.Googleperformance != 0">
            <li
              class="ms-6rem-custom ps-4"
              :class="google ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.google = !this.google"
            >
              Google Ads

              <img
                :src="rightArrow"
                alt="right-arrow"
                class="grey-arrow-icon"
                v-show="!google"
              />
              <img
                :src="blueDownArrow"
                alt="down-arrow"
                class="blue-arrow-icon"
                v-show="google"
              />
            </li>
            <li :style="google ? '' : 'display:none'">
              <router-link
                to="/GoogleadsTable"
                active-class="active"
                style="
                  padding-left: 40px;
                  margin: 0 10px 0 0;
                  position: relative;
                "
                v-if="this.lv.GoogleadsTable != 0"
              >
                投放記錄一覽表
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <li v-if="this.lv.PlatformManage != 0">
        <div
          :class="memberManage ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.memberManage = !this.memberManage"
        >
          <img
            :src="iconPlatMember"
            alt="iconPlatMember"
            v-show="memberManage"
          />
          <img
            :src="iconPlatMemberGray"
            alt="iconPlatMemberGray"
            v-show="!memberManage"
          />
          <span class="ms-6rem-custom" data-gtm="left-nav"> 平台管理 </span>
          <img
            :src="rightArrow"
            alt="right-arrow"
            class="grey-arrow-icon"
            v-show="!memberManage"
          />
          <img
            :src="blueDownArrow"
            alt="down-arrow"
            class="blue-arrow-icon"
            v-show="memberManage"
          />
        </div>
        <div class="ms-3" :class="memberManage ? 'show' : 'noshow'">
          <router-link
            to="/reportExport"
            active-class="active"
            v-if="this.lv.reportExport != 0"
          >
            報表資料管理
          </router-link>
          <router-link
            to="/MemberUploadDel"
            :class="memberLimit ? 'active' : ''"
            active-class="active"
            v-if="this.lv.MemberUploadDel != 0"
          >
            行銷名單管理
          </router-link>
          <router-link
            to="/MemberData"
            active-class="active"
            v-if="this.lv.MemberData != 0"
          >
            行銷投放紀錄
          </router-link>
        </div>
      </li>
      <li style="margin-top: 8rem">
        <div class="power-logo">
          <img
            class="logo-width"
            src="../assets/leftnav-created.png"
            alt="logo"
          />
        </div>
      </li>
    </ul> -->
  </div>
</template>

<script>
import developMode from "../config/developMode";
import { useAccountInfo } from "../store/useAccountInfo.js";
import Navbar from "../components/LeftNav/Navbar.vue";

//圖片
import rightArrow from "../assets/greyArrow.svg";
import blueDownArrow from "../assets/blueArrow.svg";
import AIDataAnalyze from "../assets/nav-ai-data-analyze.svg";
import AIDataAnalyzeGray from "../assets/nav-ai-data-analyze-grey.svg";
import iconPlatMember from "../assets/platMemberBlue.svg";
import iconPlatMemberGray from "../assets/platMemberGray.svg";
import iconOperationalDisplayGray from "../assets/trendGray.png";
import iconOperationalDisplay from "../assets/trend.png";
import iconWebsiteBehaviorGray from "../assets/website-anal-grey.svg";
import iconWebsiteBehavior from "../assets/website-anal-blue.svg";
import iconCustomerBuyGray from "../assets/analyzeGray.png";
import iconCustomerBuy from "../assets/analyze.png";
import iconNew360Gary from "../assets/New360Gray.svg";
import iconNew360 from "../assets/New360White.svg";
import iconCustomerAnalysisGray from "../assets/Customer-analysis-gray.svg";
import iconCustomerAnalysis from "../assets/Customer-analysis.svg";
import iconBuildingCaseGray from "../assets/Building-case-gray.svg";
import iconBuildingCase from "../assets/Building-case.svg";
import iconCustomerLabelGray from "../assets/customTagGroupGray.svg";
import iconCustomerLabel from "../assets/customTagGroupBlue.svg";
import iconRema from "../assets/rema.svg";
import iconSalePerformanceGray from "../assets/effectGray.svg";
import iconSalePerformance from "../assets/effectBlue.svg";

export default {
  name: "LeftNav",
  components: {
    Navbar,
  },
  data() {
    return {
      accountInfo: useAccountInfo(),
      lv: {}, //控制那些分頁要顯示。0:導覽列不顯示該分頁且不顯示分頁內容；1、2、3：導覽列顯示該分頁且顯示分頁內容
      developMode,
      login: false,

      rightArrow,
      blueDownArrow,
      AIDataAnalyze,
      AIDataAnalyzeGray,
      iconPlatMember,
      iconPlatMemberGray,

      navOverview: [
        {
          name: "營運表現",
          img: iconOperationalDisplayGray,
          imgFocus: iconOperationalDisplay,
          path: "",
          lv: "OperationalPerformance", //判斷是否顯示這個按鈕，與this.lv對照的參數
          clicked: "behavior", //如果可以展開時才需要此參數。判斷是否被點開
          children: [
            {
              name: "營運總覽",
              path: "/OperationalOverview",
              lv: "OperationalOverview", //判斷是否顯示這個按鈕，與this.lv對照的參數
              clicked: "",
              children: [],
            },
            {
              name: "指標同比",
              path: "/IndicatorCompare",
              lv: "IndicatorCompare",
              clicked: "",
              children: [],
            },
            {
              name: "會員銷售",
              path: "/MembershipSales",
              lv: "MembershipSales",
              clicked: "",
              children: [],
            },
            {
              name: "商品銷售",
              path: "",
              lv: "CommoditySales",
              clicked: "retailGoods",
              children: [
                {
                  name: "銷售表現",
                  path: "/CommoditySales",
                  lv: "CommoditySales",
                  clicked: "",
                  children: [],
                },
                {
                  name: "商品進階分析",
                  path: "/CommodityAdvanceAnalysis",
                  lv: "CommodityAdvanceAnalysis",
                  clicked: "",
                  children: [],
                },
              ],
            },
            {
              name: "門市通路銷售",
              path: "/StoreChannelSales",
              lv: "StoreChannelSales",
              clicked: "",
              children: [],
            },
          ],
        },
        {
          name: "網站行為",
          img: iconWebsiteBehaviorGray,
          imgFocus: iconWebsiteBehavior,
          path: "",
          lv: "WebsiteBehavior",
          clicked: "Website",
          children: [
            {
              name: "網站行為分析",
              path: "/WebsiteAnalysis",
              lv: "",
              clicked: "",
              children: [],
            },
            {
              name: "網站進階分析",
              path: "/WebsiteAdvance",
              lv: "",
              clicked: "",
              children: [],
            },
          ],
        },
        {
          name: "顧客購物行為",
          img: iconCustomerBuyGray,
          imgFocus: iconCustomerBuy,
          path: "",
          lv: "CustomerShopbehavior",
          clicked: "labelGroup",
          children: [
            {
              name: "整體顧客行為",
              path: "/ValueGrouping",
              lv: "ValueGrouping",
              clicked: "",
              children: [],
            },
            {
              name: "線上購物行為",
              path: "/CustomLabel",
              lv: "CustomLabel",
              clicked: "",
              children: [],
            },
            {
              name: "線下購物行為",
              path: "/OfflineBehavior",
              lv: "OfflineBehavior",
              clicked: "",
              children: [],
            },
            // {
            //   name: "",
            //   path: "",
            //   lv: "",
            //   clicked: "",
            //   children: [],
            // },
          ],
        },
        {
          name: "360度顧客輪廓",
          img: iconNew360Gary,
          imgFocus: iconNew360,
          path: "/New360Page",
          lv: "New360Page",
          clicked: "New360",
          children: [],
        },
        {
          name: "顧客分析模組",
          img: iconCustomerAnalysisGray,
          imgFocus: iconCustomerAnalysis,
          path: "/CustomerAnalysis",
          lv: "CustomerAnalysis",
          clicked: "CustomerAnalyze",
          children: [],
        },
        {
          name: "建案模組",
          img: iconBuildingCaseGray,
          imgFocus: iconBuildingCase,
          path: "/BuildingCase",
          lv: "CustomerAnalysis",
          clicked: "Building",
          children: [],
        },
        {
          name: "顧客貼標分群",
          img: iconCustomerLabelGray,
          imgFocus: iconCustomerLabel,
          path: "",
          lv: "CustomerLabel",
          clicked: "tagGroup",
          children: [
            {
              name: "價值分群",
              path: "/RFMpage",
              lv: "RFMpage",
              clicked: "",
              children: [],
            },
            {
              name: "個性化分群",
              path: "/RFMCustomPage",
              lv: "RFMCustomPage",
              clicked: "",
              children: [],
            },
            {
              name: "分群管理與報表",
              path: "/LabelManage",
              lv: "LabelManage",
              clicked: "",
              children: [],
            },
          ],
        },
        {
          name: "AI數據分析",
          img: AIDataAnalyzeGray,
          imgFocus: AIDataAnalyze,
          path: "",
          lv: "AIDataAnalyze",
          clicked: "aiGroup",
          children: [
            {
              name: "AI名單推薦",
              path: "/RankRecommend",
              lv: "AIListRecommend",
              clicked: "",
              children: [],
            },
            {
              name: "行銷AI機器人",
              path: "/AIRobotIan",
              lv: "SaleAIRobot",
              clicked: "",
              children: [],
            },
          ],
        },
      ],

      navReMa: [
        {
          name: "ReMA行銷自動化",
          img: iconRema,
          imgFocus: iconRema,
          path: "",
          lv: "Rema",
          clicked: "auto",
          children: [
            {
              name: "Email",
              path: "",
              lv: "RemaEmail",
              clicked: "remaEmail",
              children: [
                {
                  name: "Email模板",
                  path: "/ProductTextTemplate",
                  lv: "ProductTextTemplate",
                  clicked: "",
                  children: [],
                },
                {
                  name: "Coupon投放",
                  path: "/EmailCoupon",
                  lv: "EmailCoupon",
                  clicked: "",
                  children: [],
                },
                {
                  name: "排程",
                  path: "/MarketingSchedule",
                  lv: "MarketingSchedule",
                  clicked: "",
                  children: [],
                },
              ],
            },
            // 新的測試NavBarUI
            // {
            //   name: "Email(測試)",
            //   path: "",
            //   lv: "RemaEmail_New",
            //   clicked: "remaEmail-test",
            //   children: [
            //     {
            //       name: "Email模板(測試)",
            //       path: "/ProductTextTemplate_New",
            //       lv: "ProductTextTemplate_New",
            //       clicked: "",
            //       children: [],
            //     },
            //     {
            //       name: "排程(測試)",
            //       path: "/MarketingSchedule_New",
            //       lv: "MarketingSchedule_New",
            //       clicked: "",
            //       children: [],
            //     },
            //   ],
            // },

            {
              name: "SMS",
              path: "",
              lv: "RemaSMS",
              clicked: "remaSms",
              children: [
                {
                  name: "簡訊模板",
                  path: "/ProductSmsTemplate",
                  lv: "ProductSmsTemplate",
                  clicked: "",
                  children: [],
                },
                {
                  name: "排程",
                  path: "/SmsSchedule",
                  lv: "SmsSchedule",
                  clicked: "",
                  children: [],
                },
              ],
            },
            {
              name: "Meta Ads",
              path: "",
              lv: "RemaFB",
              clicked: "remaFacebook",
              children: [
                {
                  name: "受眾包預覽",
                  path: "/FacebookAdsPackage",
                  lv: "FacebookAdsPackage",
                  clicked: "",
                  children: [],
                },
                {
                  name: "點擊追蹤功能",
                  path: "/FbShortUrl",
                  lv: "FbShortUrl",
                  clicked: "",
                  children: [],
                },
              ],
            },
            {
              name: "Line",
              path: "",
              lv: "RemaLine",
              clicked: "remaLine",
              children: [
                {
                  name: "受眾包預覽",
                  path: "/LineAdsPackage",
                  lv: "LineAdsPackage",
                  clicked: "",
                  children: [],
                },
                {
                  name: "漸強受眾包",
                  path: "/CresLabLineAdsPackage",
                  lv: "CresLabLineAdsPackage",
                  clicked: "",
                  children: [],
                },
                {
                  name: "點擊追蹤功能",
                  path: "/LineShortUrl",
                  lv: "LineShortUrl",
                  clicked: "",
                  children: [],
                },
              ],
            },
            {
              name: "Google Ads",
              path: "",
              lv: "RemaGoogle",
              clicked: "remaGoogle",
              children: [
                {
                  name: "受眾包預覽",
                  path: "/GoogleAdsPackage",
                  lv: "GoogleAdsPackage",
                  clicked: "",
                  children: [],
                },
                {
                  name: "點擊追蹤功能",
                  path: "/GoogleShortUrl",
                  lv: "GoogleShortUrl",
                  clicked: "",
                  children: [],
                },
              ],
            },
            {
              name: "自動化劇本",
              path: "",
              lv: "AutomationScript",
              clicked: "remaScript",
              children: [
                {
                  name: "劇本一覽表",
                  path: "/ScriptTable",
                  lv: "ScriptTable",
                  clicked: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          name: "行銷成效",
          img: iconSalePerformanceGray,
          imgFocus: iconSalePerformance,
          path: "",
          lv: "MarketingPerformance",
          clicked: "advEffect",
          children: [
            {
              name: "活動行事曆",
              path: "/FullCalendar",
              lv: "FullCalendar",
              clicked: "",
              children: [],
            },
            {
              name: "整體行銷成效",
              path: "/AllAdvEffect",
              lv: "AllAdvEffect",
              clicked: "",
              children: [],
            },
            {
              name: "Email",
              path: "",
              lv: "EmailPerformance",
              clicked: "email",
              children: [
                {
                  name: "投放紀錄一覽表",
                  path: "/EmailMarketingTable",
                  lv: "EmailMarketingTable",
                  clicked: "",
                  children: [],
                },
                {
                  name: "整體投放趨勢",
                  path: "/LatestResult",
                  lv: "LatestResult",
                  clicked: "",
                  children: [],
                },
              ],
            },
            {
              name: "SMS",
              path: "",
              lv: "SMSperformance",
              clicked: "sms",
              children: [
                {
                  name: "投放紀錄一覽表",
                  path: "/SmsMarketingTable",
                  lv: "SmsMarketingTable",
                  clicked: "",
                  children: [],
                },
                {
                  name: "整體投放趨勢",
                  path: "/SmsLatestResult",
                  lv: "SmsLatestResult",
                  clicked: "",
                  children: [],
                },
              ],
            },
            {
              name: "Meta ads",
              path: "",
              lv: "FBperformance",
              clicked: "fb",
              children: [
                {
                  name: "投放紀錄一覽表",
                  path: "/FbadsTable",
                  lv: "FbadsTable",
                  clicked: "",
                  children: [],
                },
                {
                  name: "趨勢比較分析",
                  path: "/FbTrendAnal",
                  lv: "FbTrendAnal",
                  clicked: "",
                  children: [],
                },
                {
                  name: "活動主題比較",
                  path: "/FbThemeAnal",
                  lv: "FbThemeAnal",
                  clicked: "",
                  children: [],
                },
              ],
            },
            {
              name: "Google Ads",
              path: "",
              lv: "Googleperformance",
              clicked: "google",
              children: [
                {
                  name: "投放紀錄一覽表",
                  path: "/GoogleadsTable",
                  lv: "GoogleadsTable",
                  clicked: "",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          name: "平台管理",
          img: iconPlatMemberGray,
          imgFocus: iconPlatMember,
          path: "",
          lv: "PlatformManage",
          clicked: "memberManage",
          children: [
            {
              name: "報表資料管理",
              path: "/reportExport",
              lv: "reportExport",
              clicked: "",
              children: [],
            },
            {
              name: "行銷名單管理",
              path: "/MemberUploadDel",
              lv: "MemberUploadDel",
              clicked: "",
              children: [],
            },
            {
              name: "行銷投放紀錄",
              path: "/MemberData",
              lv: "MemberData",
              clicked: "",
              children: [],
            },
          ],
        },
      ],

      behavior: false,
      Website: false,
      New360: false,
      CustomerAnalyze: false,
      Building: false,
      labelGroup: false,
      customerProfile: false,
      tagGroup: false,
      memberManage: false,
      auto: false,
      advEffect: false,
      retailGoods: false,
      aiGroup: false,

      email: false,
      fb: false,
      google: false,
      line: false,

      remaEmail: false,
      remaSms: false,
      remaGoogle: false,
      remaFacebook: false,
      remaLine: false,
      remaScript: false,

      highlight: false,
      highlightPerformanceSMS: false,
      highlightPerformanceEmail: false,
      highlightEmail: false,
      highlightSms: false,
      sms: false,
      crossads: false,

      memberLimit: false,

      isMainSideBar: true /* 不顯示LeftNav.vue，改顯示LeftNavAccount */,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        console.log("route", route.name);
        this.getLv();

        this.login =
          this.$route.path === "/Login" ||
          this.$route.path === "/ForgotPassword" ||
          this.$route.path === "/ResetPassword";

        this.behavior =
          this.$route.path === "/OperationalOverview" ||
          this.$route.path === "/MembershipSales" ||
          this.$route.path === "/CommoditySales" ||
          this.$route.path === "/CommodityAdvanceAnalysis" ||
          this.$route.path === "/StoreChannelSales" ||
          this.$route.path === "/IndicatorCompare";

        this.retailGoods =
          this.$route.path === "/CommoditySales" ||
          this.$route.path === "/CommodityAdvanceAnalysis";

        this.Website =
          this.$route.path === "/WebsiteAnalysis" ||
          this.$route.path === "/WebsiteAdvance" ||
          this.$route.path === "/clickForceTag";

        this.New360 = this.$route.path === "/New360Page";
        this.CustomerAnalyze = this.$route.path === "/CustomerAnalysis";
        this.Building = this.$route.path === "/BuildingCase";

        this.labelGroup =
          this.$route.path === "/ValueGrouping" ||
          this.$route.path === "/CustomLabel" ||
          this.$route.path === "/OfflineBehavior";

        this.customerProfile =
          this.$route.path === "/BasicInfo" ||
          this.$route.path === "/PurchaseRecord" ||
          this.$route.path === "/OnlineBehavior";

        this.tagGroup =
          this.$route.path === "/RFMpage" ||
          this.$route.path === "/RFMCustomPage" ||
          this.$route.path === "/LabelManage";

        this.aiGroup =
          this.$route.path === "/RankRecommend" ||
          this.$route.path === "/AIRobotIan";

        this.memberManage =
          this.$route.path === "/MemberData" ||
          this.$route.path === "/MemberUploadDel" ||
          this.$route.path === "/reportExport" ||
          this.$route.path === "/MemberLimit";

        this.memberLimit = this.$route.path === "/MemberLimit";

        this.auto =
          this.$route.path === "/ProductTextTemplate" ||
          this.$route.path === "/MarketingSchedule" ||
          this.$route.path === "/MarketingScript" ||
          this.$route.path === "/TriggerScript" ||
          this.$route.path === "/ScriptTable" ||
          this.$route.path === "/SmsSchedule" ||
          this.$route.path === "/ProductSmsTemplate" ||
          this.$route.path === "/LineAdsPackage" ||
          this.$route.path === "/FacebookAdsPackage" ||
          this.$route.path === "/LineShortUrl" ||
          this.$route.path === "/FbShortUrl" ||
          this.$route.path === "/SmsSetting" ||
          this.$route.path === "/EmailSetting" ||
          this.$route.path === "/AfterTriggerScript" ||
          this.$route.path === "/GoogleAdsPackage" ||
          this.$route.path === "/GoogleShortUrl";

        this.advEffect =
          this.$route.path.includes("AllAdvEffect") ||
          this.$route.path.includes("MarketingTable") ||
          this.$route.path.includes("ResultPerformance") ||
          this.$route.path.includes("LatestResult") ||
          this.$route.path.includes("EventPerformance") ||
          this.$route.path.includes("MarketingTrend") ||
          this.$route.path.includes("FbadsTable") ||
          this.$route.path.includes("FbTrendAnal") ||
          this.$route.path.includes("FbThemeAnal") ||
          this.$route.path.includes("FullCalendar") ||
          this.$route.path.includes("GoogleadsTable") ||
          this.$route.path.includes("CrossAdsChart") ||
          this.$route.path.includes("CrossAdsTable");

        this.email =
          this.$route.path === "/EmailMarketingTable" ||
          this.$route.path === "/ResultPerformance" ||
          this.$route.path === "/EventPerformance" ||
          this.$route.path === "/LatestResult";

        this.sms =
          this.$route.path === "/SmsMarketingTable" ||
          this.$route.path === "/SmsLatestResult" ||
          this.$route.path === "/SmsResultPerformance" ||
          this.$route.path == "/SmsEventPerformance";

        this.crossads =
          this.$route.path === "/CrossAdsChart" ||
          this.$route.path === "/CrossAdsTable";

        this.highlightPerformanceSMS =
          this.$route.path == "/SmsResultPerformance" ||
          this.$route.path == "/SmsEventPerformance";

        this.highlightPerformanceEmail =
          this.$route.path === "/ResultPerformance" ||
          this.$route.path === "/EventPerformance";

        this.highlightEmail = this.$route.path === "/EmailSetting";
        this.highlightSms = this.$route.path === "/SmsSetting";

        this.fb =
          this.$route.path === "/FbMarketingTable" ||
          this.$route.path === "/FbMarketingTrend" ||
          this.$route.path === "/FbadsTable" ||
          this.$route.path === "/FbTrendAnal" ||
          this.$route.path === "/FbThemeAnal";

        this.google =
          this.$route.path === "/GoogleadsTable" ||
          this.$route.path === "/GoogleMarketingTrend";

        this.remaEmail =
          this.$route.path === "/ProductTextTemplate" ||
          this.$route.path === "/MarketingSchedule" ||
          this.$route.path === "/EmailSetting";

        this.remaSms =
          this.$route.path === "/SmsSchedule" ||
          this.$route.path === "/ProductSmsTemplate" ||
          this.$route.path === "/SmsSetting";

        this.remaFacebook =
          this.$route.path === "/FacebookAdsPackage" ||
          this.$route.path === "/FbShortUrl";

        this.remaLine =
          this.$route.path === "/LineAdsPackage" ||
          this.$route.path === "/LineShortUrl";

        this.remaScript = this.$route.path === "/ScriptTable";

        this.remaGoogle =
          this.$route.path === "/GoogleAdsPackage" ||
          this.$route.path === "/GoogleShortUrl";

        /* 不顯示LeftNav.vue，改顯示LeftNavAccount */
        this.isMainSideBar =
          this.$route.path !== "/AccountSetting" &&
          this.$route.path !== "/accountSetting" &&
          this.$route.path !== "/TermPage" &&
          this.$route.path !== "/termPage" &&
          this.$route.path !== "/PrivacyPage" &&
          this.$route.path !== "/privacyPage" &&
          this.$route.path !== "/AuthorizationManage" &&
          this.$route.path !== "/authorizationManage" &&
          this.$route.path !== "/UploadFile";
      },
      immediate: true,
    },
  },
  created() {
    console.log("this.login", this.login);
    this.getLv();
  },
  methods: {
    getLv() {
      this.lv = this.accountInfo.$state.lv;
      // console.log("lv分頁權限", this.lv); //從pinia取得
      /* 0：導覽列不顯示其分頁，並無法看到該分頁內容 */
      /* 1：檢視者、2：編輯者、3：執行者、4：管理員*/
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ms-6rem-custom {
  margin-left: 0.6rem;
}

.ms-025rem-custom {
  margin-left: 0.15rem;
}

li {
  cursor: pointer;
}

img {
  position: relative;
  top: -2px;
}

.leftNav {
  position: relative;
  background-color: #eaf3f4;
  min-width: 215px;
  min-height: calc(89vh);
  padding-top: 30px;
}

.highlight {
  vertical-align: bottom;
  font-size: 18px;
  color: #63a7c8;
}

.nohighlight {
  vertical-align: bottom;
  font-size: 18px;
  color: #8a8a8a;
}

.inside-highlight {
  color: #63a7c8;
  border-radius: 8px;
  font-size: 18px;
  padding-left: 35px;
  height: 40px;
  line-height: 40px;
}

.inside-nohighlight {
  color: #8a8a8a;
  font-size: 18px;
}

.show {
  display: block;
}

.noshow {
  display: none;
}

a {
  display: block;
  color: #8a8a8a;
  text-decoration: none;
  border-radius: 8px;
  margin: 10px;
  height: 40px;
  line-height: 40px;
  padding-left: 35px;
}
/*觸及樣式*/
a:not(.active):hover {
  background-color: white;
}

.active {
  display: block;
  background-color: #63a7c8;
  color: white;
}

.power-logo {
  position: absolute;
  bottom: 10px;
  margin-left: 1.5rem;
}
.logo-wrapper {
  margin-top: 8rem;
  > div {
    position: absolute;
    bottom: 10px;
    margin-left: 1.5rem;
    img {
      width: 100%;
    }
  }
}

.logo-power {
  position: inherit;
  margin-top: 20rem;
  margin-left: 1.5rem;
}

.logo-width {
  width: 100%;
}

.overflow-text {
  white-space: nowrap;
}

.retailGoods {
  padding-left: 45px !important;
  margin-bottom: 10px;
  line-height: 40px;
}
.grey-arrow-icon {
  width: 8px;
  height: 12px;
  margin-left: 2px;
}
.blue-arrow-icon {
  width: 12px;
  height: 8px;
  margin-left: 2px;
}
hr {
  width: 100%;
  margin-top: 0px;
  opacity: 1;
  height: 0;
  border: 0;
  border-bottom: 1px solid #63a7c8;
}
.nav-main {
  color: #8a8a8a;
  cursor: pointer;
  .nav-second {
    cursor: pointer;
  }
  .router-link-exact-active {
    color: white;
    background-color: #63a7c8;
  }
}
</style>
