<template>
  <ul class="header-nav d-flex justify-content-between align-items-center">
    <li style="margin-left: 2.2%">
      <router-link
        class="d-flex align-items-center"
        :to="homepage"
        style="text-decoration: none"
        data-gtm="header-logo"
      >
        <img
          v-if="!isClickForce"
          class="clickforce-logo ms-2"
          :src="originLogo"
          alt="originLogo"
        />
        <img
          v-else
          class="clickforce-logo"
          :src="clickForceLogo"
          alt="clickForceLogo"
        />
      </router-link>
    </li>
    <li
      style="margin-right: 65px"
      class="justify-content-between d-flex align-items-center"
    >
      <router-link
        class="d-flex align-items-center"
        to="/FullCalendar"
        style="text-decoration: none"
        data-gtm="header-calendar"
      >
        <img :src="calendarIcon" alt="calendarIcon" class="me-2 head-img" />
      </router-link>
      <div class="dropdown">
        <button
          class="btn btn-link"
          type="button"
          id="accountDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-gtm="header-account"
        >
          <img
            class="head-img"
            :src="accountIcon"
            alt="accountIcon"
            data-gtm="header-account"
          />
        </button>

        <ul
          id="profile-dropdown"
          class="dropdown-menu"
          style="min-width: 250px"
          aria-labelledby="accountDropdown"
        >
          <li class="user-div">
            <p class="username m-0">{{ signupUser }}</p>
            <p class="user-email mb-1">{{ signupEmail }}</p>
          </li>
          <div v-for="(item, index) in dropdownFirst" :key="index">
            <a
              class="dropdown-item"
              :href="item?.href"
              :target="item?.target"
              :data-gtm="item?.gtm"
            >
              <img :src="item?.src" :alt="item?.alt" :style="item?.imgStyle" />
              {{ item?.name }}
            </a>
          </div>
          <hr />
          <div v-for="(item, index) in dropdownSecond" :key="index">
            <a
              class="dropdown-item"
              :href="item?.href"
              :target="item?.target"
              :data-gtm="item?.gtm"
            >
              <img :src="item?.src" :alt="item?.alt" :style="item?.imgStyle" />
              {{ item?.name }}
            </a>
          </div>
          <hr />
          <a
            class="dropdown-item last-item"
            @click="logout"
            data-gtm="header-account-dropdown"
          >
            <img :src="logoutIcon" alt="logoutIcon" />
            登出
          </a>
        </ul>
      </div>
      <img
        class="head-img ms-2"
        :src="ringIcon"
        alt="ringIcon"
        data-gtm="header-notify"
      />
    </li>
  </ul>
</template>
<script setup>
import developMode from "../config/developMode";
import { storeToRefs } from "pinia";
import { useAccountInfo } from "../store/useAccountInfo.js";
import { ref, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useDateRangeStore } from "../store/useDateRangeStore";

//圖片
import accountIcon from "../../src/assets/account.svg";
import calendarIcon from "../../src/assets/head-calendar.svg";
import clickForceLogo from "../../src/assets/clickforce-new-mix1.png";
import originLogo from "../../src/assets/logo-BD.svg";
import ringIcon from "../../src/assets/ring.svg";
import settingIcon from "../../src/assets/setting-icon.svg";
import manualIcon from "../../src/assets/instruction-icon.svg";
import phoneIcon from "../../src/assets/phone-icon.svg";
import hammerIcon from "../../src/assets/service-menu.png";
import privacyIcon from "../../src/assets/privacy-icon.svg";
import logoutIcon from "../../src/assets/logout-icon.svg";
import importIconGray from "../../src/assets/importIconGray.svg";

const route = useRoute();
const router = useRouter();

const accountInfo = useAccountInfo();
const isClickForce = ref(false); //是否為域動客戶
const { lv } = storeToRefs(accountInfo);
const company = ref(sessionStorage.getItem("company"));
const login = ref(false);
const signupUser = ref("");
const signupEmail = ref("");
const doc_url = ref("");
const homepage = ref("/OperationalOverview"); //首頁的路徑
const dateRangeStore = useDateRangeStore();
const dropdownFirst = ref([
  {
    name: "帳號設定",
    href: "/AccountSetting",
    src: settingIcon,
    alt: "settingIcon",
    target: "",
    imgStyle: "",
    gtm: "header-account-dropdown", //google gtm埋碼需要
  },
  {
    name: "使用手冊",
    href: doc_url,
    src: manualIcon,
    alt: "manualIcon",
    target: "_blank",
    imgStyle: "",
    gtm: "header-account-dropdown",
  },
  {
    name: "資料上傳",
    href: "/UploadFile",
    src: importIconGray,
    alt: "importIconGray",
    target: "",
    imgStyle: "",
    gtm: "header-account-dropdown",
  },
]);
const dropdownSecond = ref([
  {
    name: "聯絡我們",
    href: "https://retailingdata.com.tw/index.html#/",
    src: phoneIcon,
    alt: "phoneIcon",
    target: "_blank",
    imgStyle: "",
    gtm: "header-account-dropdown",
  },
  {
    name: "服務條款",
    href: "/TermPage",
    src: hammerIcon,
    alt: "hammerIcon",
    target: "",
    imgStyle: "",
    gtm: "header-account-dropdown",
  },
  {
    name: "隱私權政策",
    href: "/PrivacyPage",
    src: privacyIcon,
    alt: "privacyIcon",
    target: "",
    imgStyle: "",
    gtm: "header-account-dropdown",
  },
]);

const logout = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("id_token");
  // pinia 資料reset時間選擇區間資料
  dateRangeStore.$reset();

  if (sessionStorage.getItem("was_clickforce") === "clickforce") {
    router.push("/Login#clickforce");
  } else {
    router.push("/Login");
  }
};

watch(lv, (newLv) => {
  isClickForce.value = newLv.clickforce;
});
watch(company, () => {
  setHomepage();
});

watch(
  route,
  () => {
    login.value = route.name === "/Login";
    if (login.value) {
      console.log("還在LOGIN頁");
    } else {
      signupUser.value = sessionStorage.getItem("name");
      signupEmail.value = sessionStorage.getItem("email");
    }
  },
  { immediate: true }
);

const setHomepage = () => {
  isClickForce.value = lv.value.clickforce === 4;
  setTimeout(() => {
    if (isClickForce.value) {
      /* 域動客戶 */
      homepage.value = "/OperationalOverview";
    } else if (company.value === "hilife") {
      /* 萊爾富客戶 */
      homepage.value = "/RankRecommend";
    } else if (company.value === "highwealth") {
      /* 興富發客戶 */
      homepage.value = "/WebsiteAnalysis";
    } else if (lv.value.OperationalPerformance === 0) {
      /* 沒有營運表現等分頁的權限 */
      homepage.value = "/RFMCustomPage";
    } else {
      homepage.value = "/OperationalOverview";
    }
  }, 0);
};

onMounted(() => {
  setHomepage();
  doc_url.value = sessionStorage.getItem("doc_url"); //變更使用者手冊的網址
});
</script>

<style scoped lang="scss">
.head-img {
  height: 35px;
}
.head-img:hover {
  opacity: 0.7;
}

.header-nav {
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 7px 0px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  border-radius: 20px;
  a {
    color: #575757;
  }
  a:hover {
    background-color: #eaf3f4;
    color: #575757;
  }
  img {
    margin-right: 10px;
    width: 15px;
  }
}

hr {
  width: 100%;
  opacity: 1;
  height: 0;
  border: 0;
  border-bottom: 1px solid #c4c4c4;
  margin: 0.25rem 0;
}

// .cdp-logo {
//   width: 9rem;
// }

.username {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.user-email {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #c4c4c4;
}

.user-div {
  padding-top: 0.5rem;
  padding-left: 1.2rem;
  padding-bottom: 0.2rem;
  box-shadow: rgba(174, 174, 174, 0.35) 0px 3px 4px -1px;
  margin-bottom: 5px;
}
</style>
