import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import OperationalOverview from "../views/OperationalOverview.vue";
import developMode from "../config/developMode";
import EditTemplateTest from "../views/EditTemplateTest.vue";

const routes = [
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/OperationalOverview",
    name: "OperationalOverview",
    component: OperationalOverview,
  },
  {
    path: "/MembershipSales",
    name: "MembershipSales",
    component: () => import("../views/MembershipSalesDev.vue"),
  },
  {
    path: "/CommoditySales",
    name: "CommoditySales",
    component: () => import("../views/CommoditySales.vue"),
  },
  /*商品進階分析*/
  {
    path: "/CommodityAdvanceAnalysis",
    name: "CommodityAdvanceAnalysis",
    component: () => import("../views/CommodityAdvanceAnalysis.vue"),
  },

  {
    path: "/StoreChannelSales",
    name: "StoreChannelSales",
    component: () => import("../views/StoreChannelSales.vue"),
  },
  {
    path: "/ValueGrouping",
    name: "ValueGrouping",
    component: () => import("../views/ValueGrouping.vue"),
  },
  {
    path: "/CustomLabel",
    name: "CustomLabel",
    component: () => import("../views/CustomLabel.vue"),
  },
  {
    path: "/OfflineBehavior",
    name: "OfflineBehavior",
    component: () => import("../views/OfflineBehavior.vue"),
  },
  {
    path: "/RFMpage",
    name: "RFMpage",
    component: () => import("../views/RFMpageDev.vue"),
  },
  {
    path: "/RFMCustomPage",
    name: "RFMCustomPage",
    component: () => import("../views/RFMCustomPageNew.vue"),
  },
  {
    path: "/ProductTextTemplate",
    name: "ProductTextTemplate",
    component: () => import("../views/ProductTextTemplate.vue"),
  },
  {
    path: "/ProductTextTemplate_New",
    name: "ProductTextTemplate_New",
    component: () => import("../views/ProductTextTemplate_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/MarketingSchedule",
    name: "MarketingSchedule",
    component: () => import("../views/MarketingSchedule.vue"),
  },
  {
    path: "/MarketingSchedule_New",
    name: "MarketingSchedule_New",
    component: () => import("../views/MarketingSchedule_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/TriggerScript",
    name: "TriggerScript",
    component: () => import("../views/TriggerScript.vue"),
  },
  {
    path: "/AfterTriggerScript",
    name: "AfterTriggerScript",
    component: () => import("../views/AfterTriggerScript.vue"),
  },
  {
    path: "/MarketingScript",
    name: "MarketingScript",
    component: () => import("../views/MarketingScript.vue"),
  },
  {
    path: "/AllAdvEffect",
    name: "AllAdvEffect",
    component: () => import("../views/AllAdvEffect.vue"),
  },
  {
    path: "/AllAdvEffect_New",
    name: "AllAdvEffect_New",
    component: () => import("../views/AllAdvEffect_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/EmailMarketingTable",
    name: "EmailMarketingTable",
    component: () => import("../views/EmailMarketingTable.vue"),
  },
  {
    path: "/EmailMarketingTable_New",
    name: "EmailMarketingTable_New",
    component: () => import("../views/EmailMarketingTable_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/FbMarketingTable",
    name: "FbMarketingTable",
    component: () => import("../views/FbMarketingTable.vue"),
  },
  {
    path: "/GoogleMarketingTable",
    name: "GoogleMarketingTable",
    component: () => import("../views/GoogleMarketingTable.vue"),
  },
  {
    path: "/GoogleMarketingTrend",
    name: "GoogleMarketingTrend",
    component: () => import("../views/GoogleMarketingTrend.vue"),
  },
  {
    path: "/ResultPerformance",
    name: "ResultPerformance",
    component: () => import("../views/ResultPerformance.vue"),
  },
  {
    path: "/ResultPerformance_New",
    name: "ResultPerformance_New",
    component: () => import("../views/ResultPerformance_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/LatestResult",
    name: "LatestResult",
    component: () => import("../views/LatestResult.vue"),
  },
  {
    path: "/LatestResult_New",
    name: "LatestResult_New",
    component: () => import("../views/LatestResult_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/TermPage",
    name: "TermPage",
    component: () => import("../views/TermPage.vue"),
  },
  {
    path: "/PrivacyPage",
    name: "PrivacyPage",
    component: () => import("../views/PrivacyPage.vue"),
  },
  {
    path: "/AccountSetting",
    name: "AccountSetting",
    component: () => import("../views/AccountSetting.vue"),
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword"),
  },
  {
    path: "/ProductSmsTemplate",
    name: "ProductSmsTemplate",
    component: () => import("../views/ProductSmsTemplate.vue"),
  },
  {
    path: "/ProductSmsTemplate_New",
    name: "ProductSmsTemplate_New",
    component: () => import("../views/ProductSmsTemplate_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/SmsSchedule",
    name: "SmsSchedule",
    component: () => import("../views/SmsSchedule.vue"),
  },
  {
    path: "/SmsSchedule_New",
    name: "SmsSchedule_New",
    component: () => import("../views/SmsSchedule_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/SmsMarketingTable",
    name: "SmsMarketingTable",
    component: () => import("../views/SmsMarketingTable.vue"),
  },
  {
    path: "/SmsMarketingTable_New",
    name: "SmsMarketingTable_New",
    component: () => import("../views/SmsMarketingTable_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/SmsResultPerformance",
    name: "SmsResultPerformance",
    component: () => import("../views/SmsResultPerformance.vue"),
  },
  {
    path: "/SmsResultPerformance_New",
    name: "SmsResultPerformance_New",
    component: () => import("../views/SmsResultPerformance_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/SmsLatestResult",
    name: "SmsLatestResult",
    component: () => import("../views/SmsLatestResult.vue"),
  },
  {
    path: "/SmsLatestResult_New",
    name: "SmsLatestResult_New",
    component: () => import("../views/SmsLatestResult_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/LineAdsPackage",
    name: "LineAdsPackage",
    component: () => import("../views/LineAdsPackage.vue"),
  },
  {
    path: "/CresLabLineAdsPackage",
    name: "CresLabLineAdsPackage",
    component: () => import("../views/LineAdsPackage.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/LineShortUrl",
    name: "LineShortUrl",
    component: () => import("../views/LineShortUrl.vue"),
  },
  {
    path: "/FacebookAdsPackage",
    name: "FacebookAdsPackage",
    component: () => import("../views/FacebookAdsPackage.vue"),
  },
  {
    path: "/FbShortUrl",
    name: "FbShortUrl",
    component: () => import("../views/FbShortUrl.vue"),
  },
  {
    path: "/FbadsTable",
    name: "FbadsTable",
    component: () => import("../views/FbadsTable.vue"),
  },
  {
    path: "/FbadsTableByAdv",
    name: "FbadsTableByAdv",
    component: () => import("../views/FbadsTableByAdv.vue"),
  },

  {
    path: "/FbTrendAnal",
    name: "FbTrendAnal",
    component: () => import("../views/FbTrendAnal.vue"),
  },
  {
    path: "/FbThemeAnal",
    name: "FbThemeAnal",
    component: () => import("../views/FbThemeAnal.vue"),
  },
  {
    path: "/EmailSetting",
    name: "EmailSetting",
    component: () => import("../views/EmailSetting.vue"),
  },
  {
    path: "/EmailSetting_New",
    name: "EmailSetting_New",
    component: () => import("../views/EmailSetting_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },

  {
    path: "/SmsSetting",
    name: "SmsSetting",
    component: () => import("../views/SmsSetting.vue"),
  },
  {
    path: "/SmsSetting",
    name: "SmsSetting_New",
    component: () => import("../views/SmsSetting_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/ScriptTable",
    name: "ScriptTable",
    component: () => import("../views/ScriptTable.vue"),
  },
  {
    path: "/MemberUploadDel",
    name: "MemberUploadDel",
    component: () => import("../views/MemberUploadDel.vue"),
  },
  {
    path: "/TriggerScriptPerformance",
    name: "TriggerScriptPerformance",
    component: () => import("../views/TriggerScriptPerformance.vue"),
  },
  {
    path: "/FullCalendar",
    name: "FullCalendar",
    component: () => import("../views/FullCalendar.vue"),
  },
  {
    path: "/FullCalendar_New",
    name: "FullCalendar_New",
    component: () => import("../views/FullCalendar.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/WebsiteAnalysis",
    name: "WebsiteAnalysis",
    component: () => import("../views/WebsiteAnalysisDev.vue"),
  },
  {
    path: "/WebsiteAdvance",
    name: "WebsiteAdvance",
    component: () => import("../views/WebsiteAdvance.vue"),
  },
  {
    path: "/TagReport/:id",
    name: "TagReport",
    component: () => import("../views/TagReportDevNew.vue"),
  },
  {
    path: "/MemberLimit",
    name: "MemberLimit",
    component: () => import("../views/MemberLimit.vue"),
  },
  {
    path: "/MemberData",
    name: "MemberData",
    component: () => import("../views/MemberData.vue"),
  },
  {
    path: "/New360Page",
    name: "New360Page",
    component: () => import("../views/New360Page.vue"),
  },
  {
    path: "/reportExport",
    name: "reportExport",
    component: () => import("../views/reportExport.vue"),
  },
  {
    path: "/ClickForceTag",
    name: "ClickForceTag",
    component: () => import("../views/ClickForceTag.vue"),
  },
  {
    path: "/LabelManage",
    name: "LabelManage",
    component: () => import("../views/LabelManageDev.vue"),
  },
  {
    path: "/CustomerAnalysis",
    name: "CustomerAnalysis",
    component: () => import("../views/CustomerAnalysis.vue"),
  },
  {
    path: "/BuildingCase",
    name: "BuildingCase",
    component: () => import("../views/BuildingCase.vue"),
  },
  {
    path: "/IndicatorCompare",
    name: "IndicatorCompare",
    component: () => import("../views/IndicatorCompare.vue"),
  },
  {
    path: "/AuthorizationManage",
    name: "AuthorizationManage",
    component: () => import("../views/AuthorizationManage.vue"),
  },
  {
    path: "/UploadFile",
    name: "UploadFile",
    component: () => import("../views/UploadFile.vue"),
  },
  /* 內部開發功能 */
  {
    path: "/GoogleadsTable",
    name: "GoogleadsTable",
    component: () => import("../views/GoogleadsTable.vue"),
  },
  {
    path: "/CrossAdsChart",
    name: "CrossAdsChart",
    component: () => import("../views/CrossAdsChart.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/CrossAdsTable",
    name: "CrossAdsTable",
    component: () => import("../views/CrossAdsTable.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/GoogleAdsPackage",
    name: "GoogleAdsPackage",
    component: () => import("../views/GoogleAdsPackage.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/GoogleShortUrl",
    name: "GoogleShortUrl",
    component: () => import("../views/GoogleShortUrl.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/AIRobotIan",
    name: "AIRobotIan",
    component: () => import("../views/AIRobotIan.vue"),
  },
  {
    path: "/AICustomRecommend",
    name: "AICustomRecommend",
    component: () => import("../views/AICustomRecommend.vue"),
  },
  {
    path: "/RankRecommend",
    name: "RankRecommend",
    component: () => import("../views/RankRecommend.vue"),
  },
  {
    path: "/EditTemplate/:id",
    name: "EditTemplateTest",
    component: () => import("../views/EditTemplateTest.vue"),
  },
  {
    path: "/EditTemplate_New/:id",
    name: "EditTemplate_New",
    component: () => import("../views/EditTemplate_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/EmailCoupon",
    name: "EmailCoupon",
    component: () => import("../views/EmailCoupon.vue"),
  },
  {
    path: "/EmailCoupon_New",
    name: "EmailCoupon_New",
    component: () => import("../views/EmailCoupon_New.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },

  {
    path: "/CreateScript",
    name: "CreateScript",
    component: () => import("../views/CreateScript.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/ScriptSetting",
    name: "ScriptSetting",
    component: () => import("../views/CreateScript.vue"),
    beforeEnter: (to, from, next) => {
      if (developMode) {
        next();
      } else {
        next(false);
      }
    },
  },

  // {
  //   path: "/New360PageNew",
  //   name: "New360PageNew",
  //   component: () => import("../views/New360PageTest.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (developMode) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   },
  // },

  {
    path: "/",
    redirect: { name: "OperationalOverview" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.onError((error) => {
  console.error(error);
  /* 為了解決不定期跳轉頁面時發生loading error，因此監測到此狀況時，重新整理頁面 */
  Vue.prototype.$log.error("Failure Reason: ", error.message, error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    Vue.prototype.$log.error("Occur chunk load error, will reload window");
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    Vue.prototype.$log.error("Occur loading chunk failed, will reload window");
    window.location.reload();
  }
});

export default router;
