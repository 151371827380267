<template>
  <div class="email-editor-container" id="email-editor-container">
    <div v-if="isTemplateUpLoadingImages" class="loading-image-spinner"></div>
    <!--樣板儲存成功失敗彈窗-->
    <Transition>
      <div class="toast-success" v-if="IsTemplateUpdatedSuccessText || IsInsertImgErrText">
        <div class="toast-success-title">訊息</div>
        <p class="toast-success-text">
          {{
            IsTemplateUpdatedSuccessText === "success"
              ? "樣板儲存成功✅"
              : "樣板儲存失敗❌"
          }}
          {{ IsInsertImgErrText?'圖片插入失敗❌':'' }}
        </p>
      </div>
    </Transition>

    <header class="header">
      <div class="editor" v-if="props.unlayerModalProps?.name">拖曳編輯器</div>
      <div
        class="drag-editor"
        v-if="!props.unlayerModalProps?.name"
        @click="() => router.go(-1)"
      >
        拖曳編輯器
      </div>
      <div class="template-name">模板名稱: {{ templateName }}</div>
      <button
        class="button btn-light-color send-details"
        data-gtm="button-tools"
      >
        <a
          href="https://retailingdata-com.gitbook.io/clickforcexfirst-cdp-cao-zuo-shou-ce/hang-xiao-zi-dong-hua-yu-cheng-xiao/rema-hang-xiao-zi-dong-hua/email-dian-zi-you-jian"
          target="_blank"
        >
          投放事項
        </a>
      </button>
      <button
        class="button btn-gradient-color insert-condition"
        @click="isShowSetVariableModal = true"
        data-gtm="button-tools"
      >
        插入個人化變數
      </button>
    </header>
    <EmailEditor
      :locale="'zh-TW'"
      :tools="tools"
      :options="options"
      :project-id="projectId"
      class="email-editor"
      v-on:load="editorLoaded"
      ref="emailEditor"
    />
    <Teleport to="#app">
      <UpLoadImageModal
        v-if="isShowUpLoadImageModal"
        @closeModal="() => (isShowUpLoadImageModal = false)"
        @insertImageToTemplate="(imageKey) => insertImageToTemplate(imageKey)"
        class="upload-img-container"
      />
    </Teleport>
    <AlertModal
      :content="alertMsg"
      @closeModal="showAlertModal = false"
      v-if="showAlertModal"
    />
    <SetVariableModal
      v-if="isShowSetVariableModal"
      :unSubscribeUrl="unSubscribeUrl"
      :showModal="isShowSetVariableModal"
      @closeModal="() => (isShowSetVariableModal = false)"
      class="variable-container"
    />
    <footer
      class="footer"
      :class="[props.unlayerModalProps?.name ? 'btn-center' : '']"
    >
      <button
        class="button btn-light-color btn-back"
        v-if="!props.unlayerModalProps?.name"
        @click="() => router.push('/ProductTextTemplate')"
        data-gtm="button-tools"
      >
        返回
      </button>
      <button
        class="button btn-storage btn-gradient-color"
        v-if="
          !props.unlayerModalProps?.name ||
          props.unlayerModalProps?.name.includes('template')
        "
        @click="storeEditedTemplate"
        data-gtm="button-tools"
      >
        儲存
      </button>
      <button
        class="button btn-storage btn-gradient-color"
        v-else
        @click="storeEditedABTestTemplate"
        data-gtm="button-tools"
      >
        儲存
      </button>
      <button
        class="button btn-gradient-color btn-next"
        v-if="!props.unlayerModalProps?.name"
        @click="prepareStoreTemplate"
        data-gtm="button-tools"
      >
        儲存及下一步
      </button>
    </footer>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick, defineProps, watch } from "vue";
import { allapi2 } from "../../public/static2/api/apigClient";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { EmailEditor } from "vue-email-editor";
import UpLoadImageModal from "../components/EditTemplateTest/UpLoadImageModal.vue";
import SetVariableModal from "../components/EditTemplateTest/SetVariableModal.vue";
import AlertModal from "../components/EditTemplateTest/AlertModal.vue";

const router = useRouter();
const route = useRoute();
const templateName = ref("");
const emailEditor = ref(null);
// props
const props = defineProps({
  unlayerModalProps: {
    type: Object,
    default: () => {},
  },
});
// emits
const emits = defineEmits(["closeModal", "refresh-todos"]);
// emailEditor 小工具設定參數------------
const projectId = ref(119196); // unlayer 帳戶進階功能專用id 修改後進階功能會關閉
const tools = {
  social: {
    properties: {
      icons: {
        value: {
          iconType: "squared",
          icons: [
            { name: "Facebook", url: "https://facebook.com/" },
            { name: "Twitter", url: "https://twitter.com/" },
          ],
        },
      },
    },
  },
};
const options = {
  tabs: {
    blocks: {
      enabled: true, //區塊群
    },
    body: {
      enabled: true, //信件
    },
  },
  features: {
    preview: "desktop",
  },
};
const updateImageFunction = ref(null); // 圖片上傳至樣板使用的func
const isTemplateUpLoadingImages = ref(false);
const IsTemplateUpdatedSuccessText = ref("");
const IsInsertImgErrText = ref(false) // 圖片插入失敗彈窗
const isShowUpLoadImageModal = ref(false);
const isShowSetVariableModal = ref(false);
const unSubscribeUrl = ref("");
const alertMsg = ref("");
const showAlertModal = ref(false);
let emailTemplateRes = "";

// 所有樣板資料
let allTemplateData = "";

async function editorLoaded() {
  if (props.unlayerModalProps?.name === "emailsettingBbox_b") {
    console.log("預設模板編輯取B箱");
    await apiGetABtestEditedTemplate(props.unlayerModalProps?.key);
  } else if (props.unlayerModalProps?.name === "emailsettingCbox_b") {
    console.log("預設模板編輯取C箱");
    await apiGetABtestCachedTemplate(props.unlayerModalProps?.cBoxKey);
  } else if (props.unlayerModalProps?.name !== "MarketingNew") {
    await apiGetOneEmailTemplate();
  } else {
    emailTemplateRes = "";
  }

  // 進入頁面時loading樣板---------

  const templateJSON = emailTemplateRes?.data?.json;
  console.log("樣板回傳資料", templateJSON);
  emailEditor.value.editor.loadDesign(templateJSON);

  // 上傳圖片按鈕註冊-----------
  emailEditor.value.editor.registerCallback(
    "selectImage",
    function (params, done) {
      // 打開圖片上傳彈窗----------------------
      updateImageFunction.value = done;
      isShowUpLoadImageModal.value = true;  
    }
  );

  // 樣板更新(圖片上傳)
  emailEditor.value.editor.addEventListener("design:updated", function (data) {
    isTemplateUpLoadingImages.value = false;
    console.log("模板資料updated data", data);
  });
}

onMounted(async () => {
  const name = sessionStorage.getItem("template_name");
  templateName.value = name;
  // api function-----------
  // 取消訂閱網址----------------------
  await apiGetUnSubscribeUrl();
});

onBeforeRouteLeave((to, from) => {
  if (!to.path.includes("/EmailSetting")) {
    //如果不是跳轉到「郵件投放設定」，則要清除coupon_id
    //避免誤以為其他排程有建立coupon
    sessionStorage.removeItem("coupon_id");
  }
});

// 插入圖片至樣板------------------------------
function insertImageToTemplate(imageKey) {
  isTemplateUpLoadingImages.value = true;
  isShowUpLoadImageModal.value = false;
  try {
    updateImageFunction.value({
    url:
      "https://d19z6wy4716zvk.cloudfront.net/" + imageKey,
  });
  } catch(err) {
    isTemplateUpLoadingImages.value = false;
    IsInsertImgErrText.value = true;
    console.log("unlayer 上傳檔案錯誤",err)
  }
  
}

// 「儲存以及下一步」之前，檢查模板文字
async function prepareStoreTemplate() {
  const isValid = await checkCouponText();
  if (isValid) storeTemplateAndNext();
}
async function checkCouponText() {
  return new Promise((resolve, reject) => {
    emailEditor.value.editor.exportHtml((data) => {
      const hasCouponText = data.html.includes("{{coupon}}"); //模板是否有{{coupon}}的文字
      const couponId = sessionStorage.getItem("coupon_id"); //是否成功上傳coupon

      if (couponId && hasCouponText === false) {
        alertMsg.value = "模板必須加入{{coupon}}";
        showAlertModal.value = true;
        resolve(false);
      } else if (!couponId && hasCouponText) {
        alertMsg.value = "模板必須移除{{coupon}}";
        showAlertModal.value = true;
        resolve(false);
      } else {
        resolve(true); //驗證通過
      }
    });
  });
}
// 上傳樣板並轉到email編輯準備投放頁---------------
async function storeTemplateAndNext() {
  const templateId = route.params?.id ? route.params?.id : "none"; // 樣板id key

  if (templateId?.includes("newTemplate")) {
    // 新增樣板
    await apiPostOneEmailTemplate(templateName.value);
    await apiGetAllTemplateInfo();
    const templateInfoInDataBase = allTemplateData.find((item) => {
      return item.name === templateName.value;
    });
    const newTemplateKey = templateInfoInDataBase?.key;
    router.push(`/EmailSetting?key=${newTemplateKey}`);
  } else {
    // 編輯樣板
    await apiPatchOneEmailTemplate();
    router.push(`/EmailSetting?key=${templateId}`);
  }
}

// 樣板儲存(舊樣板為PATCH/新樣板為POST儲存樣板)
async function storeEditedTemplate() {
  const templateId = route.params?.id ? route.params?.id : "none";
  console.log("templateId", templateId);
  // 新增樣板
  if (
    templateId.includes("newTemplate") ||
    props.unlayerModalProps?.name?.includes("New")
  ) {
    await apiPostOneEmailTemplate(templateName.value);
    await apiGetAllTemplateInfo();
    const templateInfoInDataBase = allTemplateData.find((item) => {
      return item.name === templateName.value;
    });
    const newTemplateKey = templateInfoInDataBase?.key;
    console.log("route.fullPath", route.fullPath);
    if (
      route.fullPath.includes("MarketingScript") ||
      route.fullPath.includes("AfterTriggerScript")
    ) {
      emits("refresh-todos");
      setTimeout(() => emits("closeModal"), 1200);
    } else {
      router.push(`/EditTemplate/${newTemplateKey}`);
    }
  } else {
    //編輯樣板
    await apiPatchOneEmailTemplate();
  }
}

// 儲存修改過的AB test B樣板(Patch存入C模板中)
async function storeEditedABTestTemplate() {
  let body = {
    key: "",
    json: "",
    html: "",
    node: "",
  };

  await emailEditor.value.editor.exportHtml(async (data) => {
    body = {
      key: props.unlayerModalProps?.cBoxKey,
      json: data.design,
      html: data.html,
      node: new Date().getTime() + "abc",
    };
    console.log("編輯完B箱要存入C箱前的apiBody", body);
    await apiPostToABTestToCachedTemplate(body);
  });
}

// 取得單一模板------------------------------
async function apiGetOneEmailTemplate() {
  const token = sessionStorage.getItem("access_token");
  const session_template_key = sessionStorage.getItem("template_key"); // 自動化排程使用 因為router無id
  const params = {
    Authorization: "Bearer " + token,
    key: route.params?.id ? route.params?.id : session_template_key,
  };
  await allapi2
    .templatev2TempKeyGet(params)
    .then((res) => {
      emailTemplateRes = res;
      console.log("獲取單一模板訊息ok", res);
    })
    .catch((err) => {
      console.log("獲取單一模板訊息連線有誤", err);
    });
}
// 新增新樣板編輯後------------------------
async function apiPostOneEmailTemplate(name) {
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
  };
  let body = {
    name: name,
    json: "",
    html: "",
  };
  await new Promise((resolve) => {
    emailEditor.value.editor.exportHtml(async (data) => {
      body = {
        name: name,
        json: data.design,
        html: data.html,
      };
      console.log("儲存新樣板body", body);
      await allapi2
        .templatev2TempInfoPost(params, body)
        .then(async (res) => {
          console.log("儲存新樣板ok", res);
          nextTick(() => {
            IsTemplateUpdatedSuccessText.value = "success";
            setTimeout(() => (IsTemplateUpdatedSuccessText.value = ""), 1500);
          });
          resolve();
        })
        .catch((err) => {
          nextTick(() => {
            IsTemplateUpdatedSuccessText.value = "error";
            setTimeout(() => (IsTemplateUpdatedSuccessText.value = ""), 1500);
          });
          resolve();
        });
    });
  });
}
// 單一樣板編輯---------------------------
async function apiPatchOneEmailTemplate() {
  isTemplateUpLoadingImages.value = true;
  IsTemplateUpdatedSuccessText.value = "";
  const token = sessionStorage.getItem("access_token");
  const session_tempalte_key = sessionStorage.getItem("template_key");
  const params = {
    Authorization: "Bearer " + token,
  };

  // unlayer 內部提供的模板輸出方法-------------
  emailEditor.value.editor.exportHtml(async (data) => {
    const body = {
      key: route.params?.id ? route.params?.id : session_tempalte_key, // 樣板id key
      name: templateName.value, // 樣板名
      json: data.design, // html json
      html: data.html,
    };
    await allapi2
      .templatev2TempInfoPatch(params, body)
      .then((res) => {
        emailTemplateRes = res;
        console.log("儲存單一模板html--ok", res);
        isTemplateUpLoadingImages.value = false;
        nextTick(() => {
          IsTemplateUpdatedSuccessText.value = "success";
          setTimeout(() => (IsTemplateUpdatedSuccessText.value = ""), 1500);
        });
      })
      .catch((err) => {
        console.log("儲存單一模板html連線有誤", err);
        isTemplateUpLoadingImages.value = false;
        nextTick(() => {
          IsTemplateUpdatedSuccessText.value = "error";
          setTimeout(() => (IsTemplateUpdatedSuccessText.value = ""), 1500);
        });
      });
  });
}

// 取消訂閱網址 給個人化變數使用
async function apiGetUnSubscribeUrl() {
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
  };
  allapi2
    .templatev2UnsubscribeUrlGet(params)
    .then((res) => {
      console.log("獲取取消訂閱的網址ok", res);
      unSubscribeUrl.value = res.data.content;
    })
    .catch((err) => {
      console.log("獲取取消訂閱的網址連線有誤", err);
    });
}
// 取得所有樣板資訊api
async function apiGetAllTemplateInfo() {
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
  };
  await allapi2
    .templatev2TempInfoGet(params)
    .then((res) => {
      allTemplateData = res.data;
      console.log("取得所有樣板資料ok", res);
    })
    .catch((err) => {
      console.log("取得所有樣板連線有誤", err);
    });
}
// 取得A/B test B箱編輯模板資訊
async function apiGetABtestEditedTemplate(templateKey) {
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
    key: templateKey,
  };
  await allapi2
    .templatev2TransitKeyGet(params)
    .then((res) => {
      emailTemplateRes = res;
      console.log("取得A/B-test B箱編輯用模板資訊ok", res);
    })
    .catch((err) => {
      console.log("取得A/B-test B箱編輯用模板連線有誤", err);
    });
}
// 取得A/B test C箱編輯模板資訊-----------------------------
async function apiGetABtestCachedTemplate(templateKey) {
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
    key: templateKey,
  };
  console.log("編輯模板取C箱 GET", "params", params);
  await allapi2
    .templatev2CacheKeyGet(params)
    .then((res) => {
      emailTemplateRes = res;
      console.log("取得A/B-test C箱編輯用模板資訊ok", res);
    })
    .catch((err) => {
      console.log("取得A/B-test C箱編輯用模板連線有誤", err);
    });
}

// 儲存AB Test B樣版編輯過後資料------------------
// async function apiPatchToABTestEditedTemplate(apiBody) {
//   const token = sessionStorage.getItem("access_token");
//   const params = {
//     Authorization: "Bearer " + token,
//   };
//   const body = apiBody
//   console.log("修改B模板的api body",apiBody)
//   await allapi2
//     .templatev2TransitInfoPatch(params, body)
//     .then((res) => {
//       console.log("修改A/B test B模板(存入B)成功", res);
//     }).catch((err) => {
//         console.log("修改A/B test B模板(存入B)連線有誤",err);
//       });

// }
// C箱Post新增編及過後的A/B Test B樣板
async function apiPostToABTestToCachedTemplate(apiBody) {
  console.log("新增c箱的api body", apiBody);
  isTemplateUpLoadingImages.value = true;
  const token = sessionStorage.getItem("access_token");
  const params = {
    Authorization: "Bearer " + token,
  };
  const body = apiBody;

  await allapi2
    .templatev2CacheInfoPost(params, body)
    .then((res) => {
      isTemplateUpLoadingImages.value = false;
      IsTemplateUpdatedSuccessText.value = "success";
      console.log("新增A/B test B模板到C模板(存入C)成功", res);
      setTimeout(() => emits("closeModal"), 1000);
    })
    .catch((err) => {
      isTemplateUpLoadingImages.value = false;
      IsTemplateUpdatedSuccessText.value = "error";
      console.log("新增A/B test B模板到C模板(存入C)連線有誤", err);
      setTimeout(() => emits("closeModal"), 1000);
    });
}
// C箱再次儲存新內容時，需要使用Patch---------------------
// async function apiPatchToABTestToCachedTemplate(apiBody) {
//   console.log("新增c箱的api body",apiBody)
//   const token = sessionStorage.getItem("access_token");
//   const params = {
//     Authorization: "Bearer " + token,
//   };
//   const body = apiBody

//   await allapi2
//     .scriptTempCachePatch(params, body)
//     .then((res) => {
//       console.log("修改A/B test B模板並新增C模板(存入C)成功", res);
//     }).catch((err) => {
//         console.log("修改A/B test B模板並新增C模板(存入C)連線有誤",err);
//       });

// }

watch(
  () => props.unlayerModalProps,
  (obj) => {
    console.log("要編輯的email模板資料", obj);
  },
  { immediate: true }
);
</script>
<style scoped>
.header {
  height: 81px;
  display: flex;
  justify-content: space-between;
  padding: 20px 47px 21px 105px;
  font-size: 22px;
  font-weight: 400;

  @media screen and (max-width: 1560px) {
    font-size: 20px;
    padding-right: 10px;
  }
  @media screen and (max-width: 1440px) {
    font-size: 18px;
    padding-left: 20px;
  }
}

.drag-editor::before {
  content: url("../assets/arrow-black-back.svg");
  width: 12px;
  height: 17px;
  margin-right: 15px;
}
.template-name {
  color: #71afb6;
  margin-left: 1.73%;
}
.button {
  all: unset;
  border-radius: 10px;
  font-size: 18px;
  padding: 7px 30px;
  cursor: pointer;
  line-height: 20px;
}
.button a {
  all: unset;
}
.button::before {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 4px;
  position: relative;
  top: 2px;
}
.btn-light-color {
  background-color: #eaf3f4;
  color: #71afb6;
  border-color: transparent;
}
.btn-gradient-color {
  background: linear-gradient(270deg, #71afb6, #b4dbdf);
  color: #ffffff;
  border-color: transparent;
}
.send-details {
  margin-left: auto;
  margin-right: 1.73%;
}
.send-details::before {
  content: url("../assets/information.svg");
}
.insert-condition::before {
  content: url("../assets/insert-template-variable.svg");
}
.email-editor-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.email-editor {
  max-width: 100%;
  height: calc(100% - 161px);
}
.email-editor :deep(iframe) {
  max-width: 100%;
  min-width: unset !important;
}
.footer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 20px 47px 20px 48px;
}

.btn-center .btn-storage {
  margin: auto;
}
.btn-back::before {
  content: url("../assets/green-left-arrow.svg");
  top: 0;
}
.btn-storage {
  margin-left: auto;
  margin-right: 1.73%;
  height: 25px;
}
.btn-storage::before {
  content: url("../assets/saveIconWhite.svg");
}
.btn-next::after {
  display: inline-block;
  content: url("../assets/white-up-arrow-with-radius.svg");
  transform: rotate(90deg) scale(0.7);
  width: 12px;
  height: 7.4px;
  margin-left: 4px;
  position: relative;
  top: 8px;
  left: 6px;
}
.upload-img-container {
  position: fixed;
  top: 0;
  z-index: 100;
}
.variable-container {
  position: absolute;
  top: 0;
}

/*loading Spinner UI*/
.loading-image-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eaf3f4;
  opacity: 0.6;
  z-index: 5;
  border-radius: 10px;
}
.loading-image-spinner::after {
  content: "";
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid #e5e5e5;
  border-top: 5px solid rgb(95, 88, 88);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: turn-around 1s linear infinite;
}
@keyframes turn-around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toast-success-title {
  background: linear-gradient(270deg, #71afb6, #b4dbdf);
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  padding: 20px;
  font-size: 22px;
}
.toast-success-text {
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  padding: 20px;
  height: 80px;
  font-size: 20px;
}

.toast-success {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
}
/* transition 淡入淡出動畫*/
.v-enter-active,
.v-leave-active {
  transition: opacity 0.8s ease, bottom 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  bottom: 70%;
  opacity: 0;
}

.v-enter-to,
.v-leave-from {
  bottom: 50%;
  opacity: 1;
}
</style>
