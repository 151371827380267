<template>
  <div class="loading-container">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.loading-container {
  background-color: hsla(0, 0%, 100%, 0.7);
  height: 100%;
  padding: 40vh;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
</style>
