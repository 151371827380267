import { Pinia, createPinia, storeToRefs } from "pinia";
const pinia = createPinia();
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createPersistedState } from "pinia-plugin-persistedstate";
pinia.use(
  createPersistedState({
    storage: sessionStorage, //Pinia檔persist為true時，默認資料儲存的位置
  })
);

import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App);

app.use(
  pinia
); /* 重要！ 因為main.js需要將資料存到Pinia，所以需要將Pinia的初始化移到createApp之前。確保Pinia在Vue初始化之前就準備好 */

import router from "./router";
import store from "./store";

import "@vuepic/vue-datepicker/dist/main.css";
import "normalize.css/normalize.css";

import PrimeVue from "primevue/config";

import { SetupCalendar, Calendar, DatePicker } from "v-calendar";
import axios from "axios";
import VueAxios from "vue-axios";
import Datepicker from "@vuepic/vue-datepicker";
import { dragscrollNext } from "vue-dragscroll";
import VueClipboard from "vue3-clipboard";
import Tooltip from "primevue/tooltip";
import { MonthPicker } from "vue-month-picker";
import { MonthPickerInput } from "vue-month-picker";
import ToastService from "primevue/toastservice";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";

import { useLoadingStore } from "./store/useLoadingStore";

import { allapi2 } from "../public/static2/api/apigClient.js";
import developMode from "../src/config/developMode.js";
import { usePerformanceInfo } from "./store/usePerformanceInfo.js";
import { useAccountInfo } from "./store/useAccountInfo.js";
import ToastPlugin from 'vue-toast-notification'; // toast套件
import 'vue-toast-notification/dist/theme-bootstrap.css'; // toast套件
const accountInfo = useAccountInfo();

require("highcharts/highcharts-more.js")(Highcharts);
// 路徑切換前需要做 token的檢驗
router.beforeEach(async (to, from, next) => {
  if (
    to.path === "/Productarchive" ||
    to.path === "/ProductTextTemplate" ||
    to.path === "/MarketingSchedule" ||
    to.path === "/ProductSmsTemplate" ||
    to.path === "/SmsSchedule" ||
    to.path === "/LineAdsPackage" ||
    to.path === "/FacebookAdsPackage" ||
    to.path === "/EmailSetting"
  ) {
    console.log("no remove label data");
  } else {
    sessionStorage.removeItem("getlabelId");
    sessionStorage.removeItem("getlabelTitle");
    sessionStorage.removeItem("getlabelNumber");
  }

  const access = sessionStorage.getItem("access_token");
  const id = sessionStorage.getItem("id_token");
  const page = to.path.split("/")[1]; // for dynamic route to check
  const body = {
    id_token: id,
    access_token: access,
    page,
  };
  await allapi2
    .userVerifytokenPost(null, body)
    .then((res) => {
      console.log("檢驗token", res);
      sessionStorage.setItem("token", "ImLogin");
      accountInfo.saveLv("lv", res.data.auth);
      accountInfo.saveLv("company", res.data.company); //辨別此帳號是哪一個客戶公司
      console.log("是否為域動客戶?", res.data.auth.clickforce === 4);
      if (res.data.auth.clickforce === 4) {
        sessionStorage.setItem(
          "doc_url",
          "https://retailingdata-com.gitbook.io/clickforcexfirst-cdp-cao-zuo-shou-ce/"
        );
      } else {
        sessionStorage.setItem(
          "doc_url",
          "https://data-ret-ai-ling.gitbook.io/first-cdp-cao-zuo-shou-ce-ju-dian-zi-xun/"
        );
      }

      /* 判斷此分頁是否有verify? start */
      /* 如果要前往的分頁名稱，在res.data.auth找不到該key名稱，表示verify有誤 */
      /* 注意：有些分頁名稱會有兩個"/"，所以用"/"分割取index為1的內容。 ex:標籤報表資訊 */
      const pathName = to.path.split("/")[1];
      // console.log("路徑名稱", pathName);
      if (Object.keys(res.data.auth).indexOf(pathName) === -1) {
        alert("Verify有誤");
      }
      /* 判斷此分頁是否有verify? end */
    })
    .catch((err) => {
      sessionStorage.setItem("token", "ImNotLogin");
      console.log("檢驗token有誤,過期");
      console.log(err);
    });

  const isLogin = sessionStorage.getItem("token") === "ImLogin";
  const { lv } = storeToRefs(accountInfo); //從Pinia取得此帳號的權限和公司名稱
  const company = sessionStorage.getItem("company");
  const OperationalPerformance = lv.value.OperationalPerformance;
  if (isLogin) {
    if (to.path === "/Login" || to.path === "/") {
      //假如使用者直接輸入"/Login"或是"/"的時候，要導回營運總覽或指定頁面
      if (OperationalPerformance === 0) {
        //沒有營運表現的權限
        next("/RFMCustomPage");
      } else if (company === "hilife") {
        //萊爾富
        next("/RankRecommend");
      } else if (company === "highwealth") {
        //興富發
        next("/WebsiteAnalysis");
      } else {
        next("/OperationalOverview");
      }
    } else {
      next();
    }
  } else {
    console.log(to.path);
    if (
      to.path === "/ForgotPassword" ||
      to.path === "/Login" ||
      to.path === "/ResetPassword"
    ) {
      console.log(from);
      next();
    } else if (
      to.path != "/ForgotPassword" ||
      to.path != "/Login" ||
      to.path != "/ResetPassword"
    ) {
      console.log(from);
      next("/Login");
    } else {
      next();
    }
  }

  // 換頁時將pinia的loading狀態改為false，避免沒用到pinia的頁面持續loading
  const loadingStore = useLoadingStore();
  if (loadingStore.loading) {
    // console.log('loadingStore is loading, ending it!')
    loadingStore.endLoading();
  }
});

app
  .use(store)
  .use(router)
  .use(PrimeVue, { zIndex: { tooltip: 5000 } })
  .use(ToastService)
  .use(SetupCalendar, {})
  .use(VueAxios, axios)
  .use(MonthPicker)
  .use(MonthPickerInput)
  .use(ToastPlugin)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(HighchartsVue, {
    highcharts: Highcharts,
  })
  .component("Calendar", Calendar)
  .component("DatePicker", DatePicker)
  .component("Datepicker", Datepicker)
  .directive("dragscroll", dragscrollNext)
  .directive("tooltip", Tooltip)
  .mount("#app");
