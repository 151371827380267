<template>
  <div
    id="nav"
    class="d-flex flex-column"
    style="height: 100vh"
    v-if="!isRouteIsEditTemplate"
  >
    <Header class="col-12" v-if="isLogin === 'ImLogin'" />
    <div class="col-12 d-flex flex-fill">
      <LeftNav id="leftNav" class="col-2" v-if="isLogin === 'ImLogin'" />
      <div class="position-relative" style="flex-grow: 1">
        <Loading v-if="loading" />
        <NotAvailable v-if="!available" />
        <router-view v-else></router-view>
      </div>
    </div>
  </div>
  <div
    v-if="isRouteIsEditTemplate"
    class="template-page d-flex flex-column"
    style="height: 100vh"
  >
    <router-view></router-view>
  </div>
</template>

<script>
export default { name: "App" };
</script>

<script setup>
import "../src/plugins/css/bootstrapModal.css";
import "../src/plugins/css/colorFontsize.css";
import "../src/plugins/css/common.css";
import "../src/plugins/css/primevueTooltip.css";
import "../src/plugins/css/fullcalendar.css";
import "../src/plugins/css/personalLabel.css";
import "../src/plugins/css/ag-grid.css";
import "../src/plugins/css/usual.css";
import "../src/plugins/scss/main.scss";
import LeftNav from "../src/components/LeftNav.vue";
import Header from "../src/components/Header.vue";
import Loading from "./components/Loading.vue";
import NotAvailable from "./components/NotAvailable.vue";

import { ref, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { storeToRefs } from "pinia";
import { useLoadingStore } from "./store/useLoadingStore";
import developMode from "../src/config/developMode.js";
import { useAccountInfo } from "./store/useAccountInfo.js";

const pathLv = ref({});
const accountInfo = useAccountInfo();
const route = useRoute();
const router = useRouter();
const isRouteIsEditTemplate = computed(() =>
  route.path.includes("/EditTemplate")
);

const loadingStore = useLoadingStore();
const { loading } = storeToRefs(loadingStore);

const isLogin = ref("");
const available = ref(true);
console.log("developMode",developMode)
const getLv = () => {
  //如果該分頁的權限是0就不顯示其分頁內容(內部開發模式)
  const { lv } = storeToRefs(accountInfo);
  pathLv.value = lv.value;
  console.log("lv分頁權限", pathLv.value);

  /* 如果該分頁的數值為0，就不顯示分頁內容；其他數字都可顯示 */
  /* 登入頁不用判斷，因為都要能顯示 */

  if (route.name != "Login") {
    available.value = pathLv.value[route.name] === 0 ? false : true;
    if (pathLv.value[route.name] === undefined) {
      console.warn("此分頁的授權key要調整");
    }
  } else if (route.name === "Login") {
    available.value = true; /* 確保從無法顯示內容的分頁登出時，能看到登入頁 */
  }
};

/* 目的：當跳轉頁面時，偵測到chunk error發生時，會重新整理網頁，避免無法跳轉 */
/* 問題發生的原因：當網站有新的版本，而電腦快取的還是舊版本，會因為找不到指定檔案而error */
/* 參考文章：https://stackoverflow.com/questions/59385650/vuejs-browser-caching-and-loading-chunk-failed-issue */
window.onerror = function (mMsg, mSource, mLineNo, mColNo, error) {
  // mMsg = Char. Error Msg: 例如："Uncaugh SyntaxError: Unexpected token '<'"
  if (mMsg.toLowerCase().indexOf("unexpected token '<'") > -1) {
    if (navigator.onLine) {
      console.warn("偵測到chunk error發生");
      window.location.reload();
    }
  }
};

onMounted(() => {
  isLogin.value = sessionStorage.getItem("token");
  console.log("this.isLogin", isLogin);
  getLv();
});

watch(route, () => {
  isLogin.value = sessionStorage.getItem("token");
  const auth = sessionStorage.getItem("username");

  console.log("this.isLogin", isLogin);
  console.log("route", route.path);
  getLv();

  /* sessionStorage移除只在自動化劇本成效、Email單筆投放表現、SMS單筆投放表現的頁面用到的資料 */
  switch (route.path) {
    case "TriggerScriptPerformance":
    case "ResultPerformance":
    case "SmsResultPerformance":
      break;
    default:
      sessionStorage.removeItem("scriptEffectScriptName");
      sessionStorage.removeItem("scriptEffectVersion");
      break;
  }

  /* 控制不同客戶的分頁授權。無授權者，即使進到該分頁也無法看到內容 */
  // auth for highwealth
  if (auth === "firstcdp_highwealth") {
    switch (route.path) {
      case "/OperationalOverview":
        available.value = false;
        return;
      default:
        available.value = true;
    }
  }

  // auth for love woman
  if (auth === "firstcdp_lovetowoman") {
    switch (route.path) {
      default:
        available.value = true;
    }
  }

  // auth for philips
  if (auth === "firstcdp_philips") {
    switch (route.path) {
      case "/MemberUploadDel":
      case "/MemberData":
        available.value = false;
        return;
      default:
        available.value = true;
    }
  }
});
</script>
