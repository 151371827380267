/* 儲存帳戶重要資訊，不放在sessionStorage讓用戶可更改 */
import { defineStore } from "pinia";

export const useAccountInfo = defineStore({
  id: "accountInfo",
  state: () => {
    return {
      lv: {}, //0:不可讀寫、1:檢視者、2:編輯者、3:執行者、4:管理者
      company: "", //判斷帳號屬於哪個客戶公司 ex:highwealth = 興富發
    };
  },
  actions: {
    saveLv(type, data) {
      switch (type) {
        case "lv":
          this.lv = data;
          // console.log("儲存的lv", this.lv);
          break;
        case "company":
          this.company = data;
          console.log("所屬公司的種類", this.company);
          break;
        default:
          break;
      }
    },
  },
  // persist: true, //true時能將資料存到sessionStorage。為了解決重新整理時state會被初始化
});
