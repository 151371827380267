/* 儲存投放成效的用到的各種資訊 */
import { defineStore } from "pinia";

export const usePerformanceInfo = defineStore({
  id: "performanceInfo",
  state: () => ({
    labelId: [], //發送客群的id
    scriptId: "", //SMS的活動id
    ruleId: "", //Email的活動id
    viceRuleId: "", //一張樹狀圖其中一個分支的id。
    /* v_開頭：單筆投放紀錄 > 自動回應成效 > email/sms報告要使用；ve_開頭：自動化劇本成效 > email報告；vs_開頭：自動化劇本成效 > sms報告 */
    templateId: "", //用來查看html資訊的模板id。
    scriptName: "", //投放成效的活動名稱
    version: "", //版本。目前僅單筆投放成效的頁面有使用
    hasAbTestTemplate: "無", //是否顯示AB Testing模組。"有"、"無" (目前只有email投放渠道可以出現ab Testing模組)
    hasAbTesting: "", //匯出csv名單的時候，要決定call api給後端的types。目前的Ab testing資訊 ex:"A"、"B"、"AB"、"(URL網址)" (未來可以更改成更貼切的名稱，目前的名稱是前人制定的，沒有很符合其功用)
    hasAutoResponse: 0, //是否有自動回應。 0：沒有; 2、3代表有 (未來可以和後端 資料庫工程師確認2、3代表什麼意思? 能否改成true, false?)
    action: "", //投放成效的渠道。根據渠道種類來變更投放成效欄位名稱、style設定
    /* ex:email、sms、trigger_email、trigger_sms */
    checkListType: "performance", // 查看名單的種類。為了區分給後端的id差別。(自動回應成效樹狀圖的查看名單id是V開頭、單筆投放成效的查看名單id是T開頭)
    /* ex:"performance"(單筆投放成效)、"treeData"(單筆投放成效-自動回應成效報告、自動化劇本成效-劇本成效報告) */

    /* 新的單筆參數成效*/
 
    scheduleId: '', 
    hasABTest: '',
    activity_name: ''
  }),
  actions: {},
  persist: true,
});
