<template>
  <!--關鍵字搜尋框-->
  <div class="dashboard-search-div" style="position: relative" ref="target">
    <img class="searchImg" :src="searchIcon" alt="searchImg" />
    <input
      placeholder="搜尋名稱"
      class="search-input"
      v-model="searchInput"
    />
    <img
      class="xmark"
      :src="xMark"
      alt="clear keywords"
      @click="searchInput = ''"
    />
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  defineExpose,
  ref,
  watch,
} from "vue";

// 圖片資源-------------------------------------
import searchIcon from "../../../src/assets/search-black.png";
import xMark from "../../../src/assets/greenX.svg";
import { onClickOutside } from "@vueuse/core"; // 商品名搜尋input點擊使用
// 元件外部導入props
const props = defineProps({
  allItemList: {
    type: Array,
    require,
  },
  // 下拉選單指定api屬性欄位
  itemRowApiPropsName: {
    type: String,
    require,
  },
});
// 傳遞至外層組件emit function 點擊下拉選項時需要呼叫外層api的動作 和聚焦搜尋框的功能
const emits = defineEmits(["updateSearchInput"]);
// 放大鏡商品名關鍵字搜尋框
const searchInput = ref("");
const searchList = ref([]);
const autoShow = ref(false);
const target = ref(null);
// defineExpose 暴露給父層控制
defineExpose({
  searchInput,
  searchList,
});

// 搜尋框關鍵字輸入功能
// function searchInputOnfocus() {
//   searchList.value = props.allItemList;
//   autoShow.value = true;
//   emits("searchInputOnfocus", {});
// }
onClickOutside(target, () => {
  autoShow.value = false;
});
// 根據搜尋框輸入關鍵字去filter商品選項
watch(searchInput, (keyWord) => {
  console.log("keyWord:" + keyWord);
  if (!keyWord || !keyWord.trim()) {
    emits('updateSearchInput','') // 上傳空值
  } else {
    emits('updateSearchInput',keyWord.trim()) // 上傳實際搜索值
  }
});

</script>
<style scoped>
.dashboard-search-div {
  position: relative;
  width: 250px;
  height: 40px;
}
.searchImg {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 22px;
  left: 22px;
  transform: translate(-50%, -50%);
}
.xmark {
  position: absolute;
  width: 12px;
  height: 12px;
  top: calc(50% + 2px);
  right: 0;
  transform: translate(-50%, -50%);
  margin-right: 7px;
  cursor: pointer;
}
.search-dropdown {
  background-color: white;
  position: absolute;
  right: 0;
  z-index: 10000;
  width: 100%;
  max-height: 373px;
  /* overflow: auto; */
  box-shadow: 0 7px 29px 0 hsla(240, 5%, 41%, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem 0.25rem 15px 15px;
}
.search-dropdown p {
  margin-bottom: 0;
}
.search-input {
  padding-right: 35px;
}
</style>